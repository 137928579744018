/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useEnvironment } from '../../context/EnvironmentContext';
import InputBox from '../Base/Elements/Input/InputBox';
import logo from '../../../images/retrohook.png';
import '../../css/Environments.css';

// Type definitions
interface EnvironmentFormProps {
  setShowCreateForm: () => void;
}

interface Environment {
  name: string;
  awsAccountID: string;
  awsRegion: string;
  awsUserClientId: string;
  awsUserSecret: string;
  storage: { version: string };
  network: { version: string };
  compute: { version: string };
  stackery: { version: string };
}

// Constants
const DEFAULT_NEW_ENVIRONMENT: Environment = {
  name: '',
  awsAccountID: '',
  awsRegion: '',
  awsUserClientId: '',
  awsUserSecret: '',
  storage: { version: 'latest' },
  network: { version: 'latest' },
  compute: { version: 'latest' },
  stackery: { version: 'latest' },
};

const PLACEHOLDERS = {
  awsRegion: 'Enter AWS Region',
  awsUserClientId: 'Enter AWS User Client ID',
  awsAccountID: 'Enter AWS Account ID',
  awsUserSecret: 'Enter AWS User Client Secret',
  name: 'Enter Environment Name',
};

export default function EnvironmentForm({ setShowCreateForm }: EnvironmentFormProps) {
  const { createEnvironment, availableRegions, userCF, loading } = useEnvironment();

  const [environment, setEnvironment] = useState<Environment>(DEFAULT_NEW_ENVIRONMENT);
  const [loadingType, setLoadingType] = useState('');

  // Container and item animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3,
      },
    },
    hover: {
      scale: 1.02,
      transition: { duration: 0.2 },
    },
  };

  // Input change handler
  const handleInputChange = (event: any, input: keyof Environment) => {
    setEnvironment((prev) => ({
      ...prev,
      [input]: event.target.value,
    }));
  };

  // Save environment handler
  const saveEnvironment = async () => {
    setLoadingType('save');
    await createEnvironment(environment);
    setEnvironment(DEFAULT_NEW_ENVIRONMENT);
    setLoadingType('');
    setShowCreateForm(false);
  };

  // Download CloudFormation template
  const downloadUserCF = () => {
    const element = document.createElement('a');
    const file = new Blob([JSON.stringify(userCF)], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = 'retrohook-user.json';
    document.body.appendChild(element);
    element.click();
  };

  // Render loading state
  const renderLoadingState = () => (
    <motion.div className="env-form-loading-container" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.3 }}>
      <motion.div className="env-form-loading-content" initial={{ scale: 0.9 }} animate={{ scale: 1 }} transition={{ type: 'spring', stiffness: 300 }}>
        <motion.img
          className="env-form-loading-logo"
          src={logo}
          alt="Logo"
          initial={{ rotate: -10 }}
          animate={{ rotate: 0 }}
          transition={{ type: 'spring', stiffness: 300 }}
        />
        <motion.h2
          className="env-form-loading-text"
          style={{ fontFamily: '"Gugi", sans-serif' }}
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.2 }}
        >
          Retrohook
        </motion.h2>
      </motion.div>
    </motion.div>
  );

  // Render form content
  const renderFormContent = () => (
    <motion.div initial="hidden" animate="visible" variants={containerVariants}>
      <motion.p className="env-form-download-container" variants={itemVariants}>
        <motion.a onClick={downloadUserCF} className="env-form-download-link" whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
          Download
        </motion.a>{' '}
        the AWS CloudFormation Role template to generate cloud connectivity details.
      </motion.p>

      <motion.div className="env-form-content">
        {/* Name Input */}
        <motion.div className="env-form-input-wrapper" variants={itemVariants}>
          <InputBox name="Name" onChange={(event: any) => handleInputChange(event, 'name')} value={environment.name} placeholder={PLACEHOLDERS.name} />
        </motion.div>

        {/* AWS Account ID Input */}
        <motion.div className="pb-2 pt-4" variants={itemVariants}>
          <InputBox
            name="AWS Account ID"
            onChange={(event: any) => handleInputChange(event, 'awsAccountID')}
            value={environment.awsAccountID}
            placeholder={PLACEHOLDERS.awsAccountID}
          />
        </motion.div>

        {/* AWS Client ID Input */}
        <motion.div className="pb-2 pt-2" variants={itemVariants}>
          <InputBox
            name="AWS Client ID"
            onChange={(event: any) => handleInputChange(event, 'awsUserClientId')}
            value={environment.awsUserClientId}
            placeholder={PLACEHOLDERS.awsUserClientId}
          />
        </motion.div>

        {/* AWS Client Secret Input */}
        <motion.div className="pb-2 pt-2" variants={itemVariants}>
          <InputBox
            name="AWS Client Secret"
            onChange={(event: any) => handleInputChange(event, 'awsUserSecret')}
            value={environment.awsUserSecret}
            placeholder={PLACEHOLDERS.awsUserSecret}
          />
        </motion.div>

        {/* AWS Region Select */}
        <motion.div className="pb-2" variants={itemVariants}>
          <select className="env-form-region-select" value={environment.awsRegion} onChange={(event) => handleInputChange(event, 'awsRegion')}>
            <option value="" disabled>
              Select AWS Region
            </option>
            {availableRegions?.map((region: any, index: number) => (
              <option key={index} value={region.RegionName}>
                {region.RegionName}
              </option>
            ))}
          </select>
        </motion.div>

        {/* Submit Button */}
        <motion.button
          type="button"
          className="env-form-submit-button"
          onClick={saveEnvironment}
          variants={itemVariants}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Create Environment
        </motion.button>
      </motion.div>
    </motion.div>
  );

  return (
    <div className="env-form-container">
      <div className="env-form-wrapper">
        <AnimatePresence mode="wait">{loadingType === 'save' && loading ? renderLoadingState() : renderFormContent()}</AnimatePresence>
      </div>
    </div>
  );
}
