/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import SaveInAnotherAccountAlert from '../../Base/Elements/Alerts/SaveInAnotherAccountAlert';
import UserPermissionsAlert from '../../Base/Elements/Alerts/UserPermissionsAlert';
import URLInputBox from './Elements/URLInputBox';
import Dropdown from './Elements/TunnelDropDown';
import FlowHeading from './Elements/FlowHeading';
import { LLayerProps } from '../../../types';
import '../../../css/Workflows.css';

export default function LLayer({
  error,
  tunnels = [],
  workflow,
  selectedWorkspace,
  sampleMap,
  fhirSampleMap,
  clearError,
  handleNextUrl,
  handleNextTunnel,
  handleNextIP,
  handleNextPort,
  handleNextS3BucketName,
  handleNextS3Key,
  handleNextClientId,
  handleNextClientSecret,
  handleNextOrgId,
  handleNextProjectId,
}: LLayerProps) {
  const authHeader = {
    'retrohook-api-key': selectedWorkspace.hash.value,
  };
  const actionShort = workflow?.next?.type?.substring(0, 4);
  const isHttpsTrigger = workflow.trigger.type.split(' ')[0] === 'HTTPS';

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3,
      },
    },
    hover: {
      scale: 1.02,
      transition: { duration: 0.2 },
    },
  };

  const renderPipeDreamInput = (id: string, label: string, value: string | undefined, onChange: () => void, type = 'text') => (
    <motion.div className="pipedream-container" variants={itemVariants}>
      <label htmlFor={id} className="pipedream-label">
        {label}
      </label>
      <div className="pipedream-input-wrapper">
        <p className="pipedream-input-container">
          <input type={type} name={id} id={id} value={value} onChange={onChange} className="pipedream-input" />
        </p>
      </div>
    </motion.div>
  );

  return (
    <motion.div className="llayer-container" initial="hidden" animate="visible" variants={containerVariants}>
      <AnimatePresence>
        {error === 'SaveInAnotherAccountException' && (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <SaveInAnotherAccountAlert closeAlert={clearError} />
          </motion.div>
        )}
        {error === 'UserPermissionsException' && (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <UserPermissionsAlert closeAlert={clearError} />
          </motion.div>
        )}
      </AnimatePresence>

      <div className="llayer-content">
        <div className="llayer-section">
          <motion.div className="llayer-main" variants={containerVariants}>
            <div>
              <motion.div className="destination-container" variants={itemVariants}>
                <FlowHeading icon={ExclamationCircleIcon} title="Destination" />

                {actionShort === 'HTTP' && (
                  <motion.div className="mt-6 bg-transparent" variants={itemVariants}>
                    <URLInputBox leftInput="->" url="URL" onChange={handleNextUrl} value={workflow.next.url} />
                  </motion.div>
                )}

                {actionShort === 'MLLP' && (
                  <motion.div className="destination-mllp" variants={itemVariants}>
                    <div className="destination-tunnel">
                      <Dropdown tunnels={tunnels} selected={workflow.next?.tunnel} selectHandler={handleNextTunnel} />
                    </div>
                    <div className="destination-ip">
                      <URLInputBox leftInput="->" url="Destination Override" onChange={handleNextIP} value={workflow.next.ip} />
                    </div>
                    <div className="destination-port">
                      <URLInputBox leftInput=":" url="Port Override" onChange={handleNextPort} value={workflow.next.port} />
                    </div>
                  </motion.div>
                )}

                {actionShort.substring(0, 2) === 'S3' && (
                  <motion.div className="destination-s3" variants={itemVariants}>
                    <div className="destination-s3-inputs">
                      <div className="inline">
                        <URLInputBox url="S3 Bucket Name" onChange={handleNextS3BucketName} value={workflow.next.bucket} />
                      </div>
                      <div className="inline">
                        <URLInputBox url="SubFolder (Optional)" onChange={handleNextS3Key} value={workflow.next.key} />
                      </div>
                    </div>
                  </motion.div>
                )}
              </motion.div>

              {actionShort === 'Pipe' && (
                <motion.div variants={itemVariants}>
                  <h3 className="text-lg text-gray-200">PipeDream Settings</h3>
                  {renderPipeDreamInput('clientId', 'Client ID', workflow.next.clientId, handleNextClientId)}
                  {renderPipeDreamInput('clientSecret', 'Client Secret', workflow.next.clientSecret, handleNextClientSecret, 'password')}
                  {renderPipeDreamInput('orgId', 'Org ID', workflow.next.orgId, handleNextOrgId)}
                  {renderPipeDreamInput('projectId', 'Project ID', workflow.next.projectId, handleNextProjectId)}
                </motion.div>
              )}
            </div>

            {isHttpsTrigger && !workflow.SK && (
              <motion.div className="http-warning" variants={itemVariants}>
                <p className="http-warning-text">
                  <ExclamationCircleIcon className="http-warning-icon" />
                  The workflow must be saved in order to generate the HTTPS entry point.
                </p>
              </motion.div>
            )}

            {isHttpsTrigger && (
              <motion.div className="auth-header-container" variants={itemVariants}>
                <label htmlFor="payload-info" className="auth-header-label">
                  Authorization Header
                </label>
                <div className="auth-header-content">
                  <pre className="auth-header-text">{JSON.stringify(authHeader, null, 2).substring(2, JSON.stringify(authHeader, null, 2).length - 2)}</pre>
                </div>
              </motion.div>
            )}
          </motion.div>

          <motion.div className="preview-container" variants={itemVariants}>
            <FlowHeading icon={ExclamationCircleIcon} title="Message Preview" />
            {(actionShort === 'HTTP' || actionShort === 'MLLP') && (
              <motion.div className="preview-container-small" variants={itemVariants}>
                <label htmlFor="payload-info" className="preview-label">
                  Body
                </label>
                <div className="preview-content">
                  <pre className="preview-text">{JSON.stringify(sampleMap, null, 2)}</pre>
                </div>
              </motion.div>
            )}
          </motion.div>
          {workflow?.next?.type.split(' ')[1] === 'FHIR' && (
            <motion.div className="fhir-container" variants={itemVariants}>
              <label htmlFor="payload-info" className="fhir-label">
                Sample Bundle Resource
              </label>
              <div className="fhir-content">
                <pre className="fhir-text">{JSON.stringify(fhirSampleMap, null, 2)}</pre>
              </div>
            </motion.div>
          )}
        </div>
      </div>
    </motion.div>
  );
}
