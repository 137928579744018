/* eslint-disable import/no-unresolved */
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ComputerDesktopIcon, SunIcon, MoonIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../../../context/ThemeContext';
import '../../../css/Base.css';

// Type definition for theme
type ThemeType = 'light' | 'dark' | 'system';

const ThemeToggler: React.FC = function () {
  const { theme, toggleTheme } = useTheme();

  // Handle theme toggle with type safety
  const handleToggle = () => {
    const themeSequence: ThemeType[] = ['light', 'dark', 'system'];
    const currentIndex = themeSequence.indexOf(theme as ThemeType);
    const nextIndex = (currentIndex + 1) % themeSequence.length;
    toggleTheme(themeSequence[nextIndex]);
  };

  // Render appropriate icon based on current theme
  const renderThemeIcon = () => {
    const themeIcons = {
      light: <SunIcon className="theme-icon-light" />,
      dark: <MoonIcon className="theme-icon-dark" />,
      system: <ComputerDesktopIcon className="theme-icon-system" />,
    };

    const iconVariants = {
      initial: {
        opacity: 0,
        rotate: -180,
      },
      animate: {
        opacity: 1,
        rotate: 0,
        transition: {
          type: 'spring',
          stiffness: 300,
          damping: 20,
        },
      },
      exit: {
        opacity: 0,
        rotate: 180,
        transition: {
          duration: 0.2,
        },
      },
    };

    return (
      <AnimatePresence mode="wait">
        <motion.div key={theme} initial="initial" animate="animate" exit="exit" variants={iconVariants}>
          {themeIcons[theme as ThemeType] || themeIcons.system}
        </motion.div>
      </AnimatePresence>
    );
  };

  return (
    <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
      <button type="button" onClick={handleToggle} className="theme-toggler-button" aria-label="Toggle Theme">
        {renderThemeIcon()}
      </button>
    </motion.div>
  );
};

export default ThemeToggler;
