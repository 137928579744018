/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React from 'react';
import { motion } from 'framer-motion';
import { ExclamationCircleIcon, CloudArrowUpIcon } from '@heroicons/react/24/outline';
import InputBox from '../Base/Elements/Input/InputBox';
import '../../css/Workspace.css';

// eslint-disable-next-line import/prefer-default-export
export const SettingsSection: React.FC = function ({ workspace, handleOrganizationValueChange, handleMessageIDChange, saveWorkflow }: any) {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3,
      },
    },
  };

  return (
    <motion.div className="settings-section-container" initial="hidden" animate="visible" variants={containerVariants}>
      <motion.div className="settings-section-input-wrapper" variants={itemVariants}>
        <div className="settings-section-input-text">
          <InputBox name="Workspace Name" onChange={handleOrganizationValueChange} placeholder={workspace?.name} defaultValue={workspace?.name} />
        </div>
      </motion.div>

      <motion.div className="settings-section-grid" variants={itemVariants}>
        <div className="settings-section-security-segment">
          <InputBox name="Security Segment" onChange={handleMessageIDChange} placeholder={workspace?.hash?.location} defaultValue={workspace?.hash?.location} />
        </div>
        <div className="settings-section-security-token">
          <InputBox name="Security Token" readOnly disabled defaultValue={workspace?.hash?.value} />
        </div>
      </motion.div>

      <motion.p className="settings-section-alert" variants={itemVariants}>
        <ExclamationCircleIcon className="settings-section-alert-icon" />
        <span className="settings-section-alert-text">
          You must include the Workflow Message ID Value below in the stated Workflow Message ID Location of your HL7 Messages for security.
        </span>
      </motion.p>

      <motion.button
        type="button"
        onClick={() => saveWorkflow(workspace)}
        className="settings-section-save-button"
        variants={itemVariants}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        <CloudArrowUpIcon className="settings-section-save-icon" />
        Save
      </motion.button>
    </motion.div>
  );
};
