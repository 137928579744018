/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';
import { ArrowUpOnSquareIcon } from '@heroicons/react/24/outline';
import { IconPickerItem } from 'react-heroicons-picker';
import { ArrowLongRightIcon } from '@heroicons/react/24/solid';

import SaveInAnotherAccountAlert from '../../Base/Elements/Alerts/SaveInAnotherAccountAlert';
import UserPermissionsAlert from '../../Base/Elements/Alerts/UserPermissionsAlert';
import WorkflowNameInputBox from './Elements/WorkflowNameInputBox';
import FlowHeading from './Elements/FlowHeading';
import Dropdown from './Elements/WorkflowDropDown';
import { AdaptersProps, defaultEventTypes, defaultActionTypes, AdapterType } from '../../../types';
import AnimatedBeam from './Elements/AnimatedBeam';

export default function Adapters({
  error,
  selectedEnvironment,
  workflow,
  placeholder,
  clearError,
  handleFlowNameChange,
  toggleActive,
  updateEventTrigger,
  updateActionTrigger,
  handleSampleMessageChange,
}: AdaptersProps) {
  const [eventTypes, setEventTypes] = useState<AdapterType[]>(defaultEventTypes);
  const [actionTypes, setActionTypes] = useState<AdapterType[]>(defaultActionTypes);
  const [activeFlow, setActiveFlow] = useState<boolean>(false);
  const eventDropdownRef = React.useRef(null);
  const actionDropdownRef = React.useRef(null);

  useEffect(() => {
    const updateDropdowns = () => {
      const isDevEnvironment = selectedEnvironment.name.substring(3, 6) === 'Dev';

      const updatedEventTypes = defaultEventTypes.map((eventType) => ({
        ...eventType,
        disabled: isDevEnvironment && eventType.type.startsWith('HTTP') ? true : eventType.disabled,
      }));

      const updatedActionTypes = defaultActionTypes.map((actionType) => ({
        ...actionType,
        disabled: isDevEnvironment && (actionType.type === 'MLLP HL7v2' || actionType.type.startsWith('S3')) ? true : actionType.disabled,
      }));

      setEventTypes(updatedEventTypes);
      setActionTypes(updatedActionTypes);
    };

    updateDropdowns();
    return () => {
      setEventTypes(defaultEventTypes);
      setActionTypes(defaultActionTypes);
    };
  }, [selectedEnvironment]);

  const handleActiveToggle = () => {
    toggleActive();
    setActiveFlow(!activeFlow);
  };

  return (
    <div className="adapters-layout">
      {error === 'SaveInAnotherAccountException' && <SaveInAnotherAccountAlert closeAlert={clearError} />}
      {error === 'UserPermissionsException' && <UserPermissionsAlert closeAlert={clearError} />}

      <div className="adapters-main">
        <div className="adapters-card">
          <div className="adapters-content">
            <FlowHeading icon={ArrowUpOnSquareIcon} title="Name" />

            <div className="adapters-name-wrapper">
              <div className="adapters-name-container">
                <div className="adapters-icon-container">
                  <div className="adapters-icon-button">
                    <IconPickerItem icon={workflow?.icon || 'BoltIcon'} library="outline" />
                  </div>
                </div>
                <div className="adapters-name-input-wrapper">
                  <WorkflowNameInputBox name="Name" onChange={handleFlowNameChange} value={workflow?.flowName} placeholder={placeholder.flowName} />
                </div>
              </div>

              <button
                type="button"
                onClick={handleActiveToggle}
                className={`adapters-status-button ${activeFlow ? 'adapters-status-active' : 'adapters-status-inactive'}`}
              >
                {activeFlow ? 'Active' : 'Inactive'}
              </button>
            </div>

            <div className="adapters-description">
              <FlowHeading icon={ArrowUpOnSquareIcon} title="Adapters" />
              <p className="adapters-description-text">
                Select the desired event type to initiate your workflow and the corresponding action you would like to take place as a result of the event.
              </p>
            </div>

            <div className="adapters-selection">
              <div className="adapters-selection-content">
                <div className="adapters-dropdown-container">
                  <h2 className="adapters-dropdown-title" ref={eventDropdownRef}>
                    Event
                  </h2>
                  <Dropdown selectHandler={updateEventTrigger} selected={workflow?.trigger} Types={eventTypes} />
                </div>
                <ArrowLongRightIcon className="adapters-arrow" />
                <div className="adapters-dropdown-container">
                  <h2 className="adapters-dropdown-title" ref={actionDropdownRef}>
                    Action
                  </h2>
                  <Dropdown selectHandler={updateActionTrigger} selected={workflow?.next} Types={actionTypes} />
                </div>
              </div>
            </div>

            <div className="adapters-sample">
              <div className="adapters-sample-header">
                <FlowHeading icon={ArrowUpOnSquareIcon} title="Sample Message" />
              </div>
              <div className="adapters-sample-container">
                <div className="adapters-sample-content">
                  {(workflow.trigger.type.split(' ')[1] === 'HL7v2' || workflow.trigger.type === 'MLLP') && (
                    <textarea
                      className="adapters-sample-textarea"
                      id="sample-message-hl7"
                      name="sample-message"
                      rows={10}
                      defaultValue={workflow?.sampleMessage}
                      onChange={handleSampleMessageChange}
                    />
                  )}
                  {workflow.trigger.type.split(' ')[1] === 'C-CDA' && (
                    <textarea
                      className="adapters-sample-textarea"
                      id="sample-message-ccda"
                      name="sample-message"
                      rows={10}
                      defaultValue={workflow?.sampleMessage}
                      onChange={handleSampleMessageChange}
                    />
                  )}
                  {workflow.trigger.type.split(' ')[1] === 'JSON' && (
                    <textarea
                      className="adapters-sample-textarea"
                      id="sample-message-json"
                      name="sample-message"
                      rows={10}
                      defaultValue={workflow?.sampleMessage}
                      onChange={handleSampleMessageChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
