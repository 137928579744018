/* eslint-disable func-names */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React from 'react';

// Type definition for Chatwoot settings
interface ChatwootSettings {
  hideMessageBubble: boolean;
  position: 'left' | 'right';
  locale: string;
  type: 'standard' | 'expanded_bubble';
}

// Type definition for Chatwoot SDK
interface ChatwootSDK {
  run: (config: { websiteToken: string; baseUrl: string }) => void;
}

// Extend window interface to include chatwoot-specific properties
declare global {
  interface Window {
    chatwootSettings?: ChatwootSettings;
    chatwootSDK?: ChatwootSDK;
  }
}

class ChatwootWidget extends React.Component {
  componentDidMount(): void {
    // Add Chatwoot Settings
    window.chatwootSettings = {
      hideMessageBubble: false,
      position: 'right', // This can be left or right
      locale: 'en', // Language to be set
      type: 'standard', // [standard, expanded_bubble]
    };

    // Paste the script from inbox settings except the <script> tag
    (function (d: Document, t: string) {
      const BASE_URL = 'https://app.chatwoot.com';
      const g = d.createElement(t) as HTMLScriptElement;
      const s = d.getElementsByTagName(t)[0];

      g.src = `${BASE_URL}/packs/js/sdk.js`;
      g.defer = true;
      g.async = true;

      s.parentNode?.insertBefore(g, s);

      g.onload = () => {
        window.chatwootSDK?.run({
          websiteToken: 'Fr3rB9YpDJYBGs5yGoGdgEFi',
          baseUrl: BASE_URL,
        });
      };
    })(document, 'script');
  }

  render(): React.ReactNode {
    return null;
  }
}

export default ChatwootWidget;
