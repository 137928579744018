import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import CopyToClipboard from 'react-copy-to-clipboard';
import { TrashIcon, InformationCircleIcon, Cog6ToothIcon, ArrowUpOnSquareIcon, CheckIcon } from '@heroicons/react/24/outline';

import SaveInAnotherAccountAlert from '../../Base/Elements/Alerts/SaveInAnotherAccountAlert';
import UserPermissionsAlert from '../../Base/Elements/Alerts/UserPermissionsAlert';
import MessageIdentifierInfoAlert from '../../Base/Elements/Alerts/MessageIdentifierInfoAlert';
import Toggle from './Elements/GridToggle';
import SFTPConfigModal from './Elements/SFTPConfigModal';
import FlowHeading from './Elements/FlowHeading';
import { ELayerProps, EventTriggerMap } from '../../../types';
import '../../../css/Workflows.css';

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.1,
    },
  },
};

const sectionVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      stiffness: 300,
      damping: 30,
    },
  },
};

const tableRowVariants = {
  hidden: { opacity: 0, x: -20 },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: 'spring',
      stiffness: 300,
      damping: 30,
    },
  },
};

export default function ELayer({
  error,
  selectedEnvironment,
  workflow,
  eventTriggerGrid: initialEventTriggerGrid,
  sampleFilter,
  triggerSample,
  selectedWorkspace,
  copied,
  clearError,
  setCopiedHandler,
  updateTriggerMethod,
  enableSampleFilter,
  updateTrigger,
  removeTriggerMapRow,
  ...sftpProps
}: ELayerProps) {
  const [eventTriggerGrid, setEventTriggerGrid] = useState(initialEventTriggerGrid);
  const [showMessageIdInfo, setShowMessageIdInfo] = useState(false);
  const [showSFTPModal, setShowSFTPModal] = useState(false);

  const isDefaultAccount = selectedEnvironment.accountId === '587677186137';
  const isTestAccount = selectedEnvironment.accountId === '411790770360';

  const mllpURL = isDefaultAccount ? 'mllp.retrohook.com:2575' : `${selectedEnvironment?.network?.nlb?.DNSName.toLowerCase()}:2575`;

  const httpURL = isDefaultAccount ? '' : `${selectedEnvironment?.network?.webhook?.ApiEndpoint}/messages/${workflow.SK.split('#')[1]}`;

  const clipBoardURL = workflow.trigger.type.substring(0, 4) === 'HTTP' ? httpURL : mllpURL;

  const triggerType = workflow.trigger.type.substring(0, 4);
  const actionType = workflow.next.type.substring(0, 4);

  useEffect(() => {
    setEventTriggerGrid(initialEventTriggerGrid);
  }, [initialEventTriggerGrid]);

  return (
    <motion.div className="elayer-container" variants={containerVariants} initial="hidden" animate="visible">
      <AnimatePresence>
        {error === 'SaveInAnotherAccountException' && (
          <motion.div initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -20 }}>
            <SaveInAnotherAccountAlert closeAlert={clearError} />
          </motion.div>
        )}
        {error === 'UserPermissionsException' && (
          <motion.div initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -20 }}>
            <UserPermissionsAlert closeAlert={clearError} />
          </motion.div>
        )}
        {showMessageIdInfo && (
          <motion.div initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -20 }}>
            <MessageIdentifierInfoAlert closeAlert={() => setShowMessageIdInfo(false)} />
          </motion.div>
        )}
      </AnimatePresence>

      {showSFTPModal && <SFTPConfigModal isOpen={showSFTPModal} workflow={workflow} closeModal={() => setShowSFTPModal(false)} {...sftpProps} />}

      <div className="elayer-content">
        {triggerType === 'MLLP' && (
          <motion.div className="elayer-message-section" variants={sectionVariants}>
            <motion.div className="elayer-message-container" whileHover={{ scale: 1.01 }} transition={{ type: 'spring', stiffness: 400 }}>
              <FlowHeading icon={ArrowUpOnSquareIcon} title="Message Address" />
              <div className="elayer-message-content">
                <p className="elayer-message-text">
                  <motion.span className="elayer-message-info" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.2 }}>
                    {mllpURL}
                    <span className="elayer-message-warning">MLLP Only </span>
                    {isDefaultAccount && <span className="elayer-message-warning">(IPSec required)</span>}
                    {isTestAccount && <span className="elayer-message-warning">(No PII Allowed)</span>}
                    {!isDefaultAccount && !isTestAccount && <span className="elayer-message-note">(IPSec required)</span>}
                  </motion.span>
                </p>
                <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                  <CopyToClipboard text={mllpURL} onCopy={setCopiedHandler}>
                    <button type="button" className="elayer-copy-button">
                      Copy To Clipboard
                      {copied && (
                        <motion.div initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ type: 'spring', stiffness: 400 }}>
                          <CheckIcon className="elayer-copy-icon" />
                        </motion.div>
                      )}
                    </button>
                  </CopyToClipboard>
                </motion.div>
              </div>
            </motion.div>
          </motion.div>
        )}

        {actionType === 'MLLP' && (
          <motion.div className="elayer-webhook-container" variants={sectionVariants}>
            <label htmlFor="env-info" className="elayer-webhook-label">
              Webhook URL (HTTP POST)
            </label>
            <motion.div className="elayer-message-content" whileHover={{ scale: 1.01 }} transition={{ type: 'spring', stiffness: 400 }}>
              <p className="elayer-message-text">{!isDefaultAccount && <span className="elayer-message-info">{httpURL}</span>}</p>
              <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                <CopyToClipboard text={clipBoardURL} onCopy={setCopiedHandler}>
                  <button type="button" className="elayer-copy-button">
                    Copy To Clipboard
                    {copied && (
                      <motion.div initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ type: 'spring', stiffness: 400 }}>
                        <CheckIcon className="elayer-copy-icon" />
                      </motion.div>
                    )}
                  </button>
                </CopyToClipboard>
              </motion.div>
            </motion.div>
          </motion.div>
        )}

        <motion.div className="elayer-filter-container" variants={sectionVariants}>
          <div className="elayer-filter-content">
            {(triggerType === 'MLLP' || triggerType === 'SFTP') && (
              <div className="relative overflow-x-auto">
                <table className="filter-table">
                  <thead className="filter-header">
                    <tr>
                      <td colSpan={4}>
                        <div className="filter-header-row">
                          <div className="filter-header-content">
                            <div className="filter-title-section">
                              <motion.h2 className="filter-title" initial={{ opacity: 0, x: -20 }} animate={{ opacity: 1, x: 0 }} transition={{ delay: 0.2 }}>
                                <FlowHeading icon={ArrowUpOnSquareIcon} title="Message Filter" />
                              </motion.h2>
                            </div>
                            <div className="filter-controls">
                              {workflow.trigger.type.split(' ')[1] === 'HL7v2' && (
                                <div className="view-toggle-container">
                                  <Toggle enabled={sampleFilter} setEnabled={enableSampleFilter} />
                                  <span>View All</span>
                                </div>
                              )}
                              {triggerType === 'SFTP' && (
                                <motion.div
                                  onClick={() => setShowSFTPModal(true)}
                                  className="sftp-config-button"
                                  whileHover={{ scale: 1.05 }}
                                  whileTap={{ scale: 0.95 }}
                                >
                                  <Cog6ToothIcon className="sftp-config-icon" />
                                  <span className="sftp-config-text">SFTP</span>
                                </motion.div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="filter-divider" />
                      </td>
                    </tr>
                    <tr>
                      <th className="table-header-cell-first">Field</th>
                      <th className="table-header-cell">Specification</th>
                      <th className="table-header-cell">SAMPLE</th>
                      <th className="table-header-cell">
                        FILTER
                        <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.9 }} className="ml-2 inline-flex">
                          <InformationCircleIcon className="table-header-icon" onClick={() => setShowMessageIdInfo(true)} />
                        </motion.div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isTestAccount && (
                      <motion.tr
                        variants={tableRowVariants}
                        className={triggerSample === selectedWorkspace.hash.value ? 'table-row-success' : 'table-row-error'}
                      >
                        <td className="table-cell-first">{selectedWorkspace.hash.location}</td>
                        <td className="table-cell-spec">Unique Message Identifier</td>
                        <td className="table-cell-sample">{triggerSample}</td>
                        <td className="table-cell-sample">{selectedWorkspace.hash.value}</td>
                      </motion.tr>
                    )}
                    {eventTriggerGrid.map((map: EventTriggerMap, idx: number) => (
                      <motion.tr
                        key={`${map[1].field}-${map[2].sample}${idx}`}
                        variants={tableRowVariants}
                        className={idx % 2 === 0 ? 'table-row-even' : 'table-row-odd'}
                      >
                        <td className="table-cell-first">{map[1].field}</td>
                        <td className="table-cell-spec">{map[0].definition}</td>
                        <td className="table-cell-sample">{map[2].sample}</td>
                        <td className="table-cell-filter">
                          <div className="filter-input-container">
                            <input type="text" name="filter" id={map[1].field} defaultValue={map[3].filter} onChange={updateTrigger} className="filter-input" />
                            {!sampleFilter && (
                              <motion.div
                                className="filter-delete"
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                onClick={() => removeTriggerMapRow(map)}
                              >
                                <TrashIcon className="filter-delete-icon" />
                              </motion.div>
                            )}
                          </div>
                        </td>
                      </motion.tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
}
