/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { UserCircleIcon } from '@heroicons/react/24/outline';
import UserPermissionsModal from '../Accounts/UserPermissionsModal';
import { User } from '../../types';
import '../../css/Workspace.css';

// eslint-disable-next-line import/prefer-default-export
export const PermissionsSection = function ({
  members,
  togglePermissions,
  selectedUser,
  openPermissions,
  removeUserFromWorkspace,
  setLoadingType,
  updatePermissions,
  savePermissions,
}: any) {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3,
      },
    },
    hover: {
      scale: 1.02,
      transition: { duration: 0.2 },
    },
  };

  return (
    <div className="permissions-section-container">
      <div className="permissions-section-inner">
        <UserPermissionsModal open={togglePermissions} close={savePermissions} updatePermissions={updatePermissions} selectedUser={selectedUser} />
        <motion.div className="permissions-section-grid" variants={containerVariants} initial="hidden" animate="visible">
          {(!members || members.length < 1) && (
            <motion.div className="permissions-empty-state" variants={itemVariants}>
              <div className="permissions-empty-state-content">
                <UserCircleIcon className="permissions-empty-state-icon" />
                No Team Members Yet, Start Inviting Your Team Now
              </div>
            </motion.div>
          )}
          <AnimatePresence>
            {members?.map((user: User, index: number) => (
              <motion.div
                key={user.email + index}
                variants={itemVariants}
                initial="hidden"
                animate="visible"
                exit="hidden"
                whileHover="hover"
                onClick={() => openPermissions(user)}
                className="permissions-member-card"
              >
                <div className="permissions-member-content">
                  <motion.img className="permissions-member-image" src={user?.picture} alt={user?.email} whileHover={{ scale: 1.1 }} />
                  {user?.email?.substring(0, 36) || ''}
                </div>
                <motion.button
                  type="button"
                  className="permissions-member-remove-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeUserFromWorkspace(user);
                    setLoadingType('remove');
                  }}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  X
                </motion.button>
              </motion.div>
            ))}
          </AnimatePresence>
        </motion.div>
      </div>
    </div>
  );
};
