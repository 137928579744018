/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Fragment, useEffect, useState, useMemo } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline';

interface TimeOption {
  id: number;
  label: string;
  value: number;
}

interface TimeDropdownProps {
  selected: TimeOption | null;
  selectHandler: (option: TimeOption) => void;
}

const generateTimeOptions = (): TimeOption[] => {
  const options: TimeOption[] = [];

  // Minutes (1-60)
  for (let i = 1; i <= 60; i + 1) {
    options.push({
      id: i,
      label: `${i} minute${i > 1 ? 's' : ''}`,
      value: i,
    });
  }

  // Hours (2-24)
  for (let i = 2; i <= 24; i + 1) {
    options.push({
      id: i + 60,
      label: `${i} hour${i > 1 ? 's' : ''}`,
      value: i * 60,
    });
  }

  return options;
};

const TimeDropdown: React.FC<TimeDropdownProps> = function ({ selected, selectHandler }) {
  const timeOptions = useMemo(() => generateTimeOptions(), []);
  const [selectedOption, setSelectedOption] = useState<TimeOption | null>(selected);

  useEffect(() => {
    if (!selectedOption) {
      setSelectedOption(timeOptions[0]);
    }
  }, [selectedOption, timeOptions]);

  const handleChange = (option: TimeOption) => {
    setSelectedOption(option);
    selectHandler(option);
  };

  return (
    <div className="time-dropdown-container">
      <label htmlFor="cadence" className="time-dropdown-label">
        Cadence
      </label>
      <Listbox value={selectedOption} onChange={handleChange}>
        {({ open }) => (
          <div className="time-dropdown-wrapper">
            <Listbox.Button className="time-dropdown-button">
              <span className="block truncate">{selectedOption?.label || timeOptions[0].label}</span>
              <span className="time-dropdown-icon">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Listbox.Options className="time-dropdown-options">
                {timeOptions.map((option) => (
                  <Listbox.Option
                    key={option.id}
                    value={option}
                    className={({ active }) => `time-dropdown-option ${active ? 'time-dropdown-option-active' : 'time-dropdown-option-inactive'}`}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={`time-dropdown-option-text ${selected ? 'font-semibold' : 'font-normal'}`}>{option.label}</span>
                        {selected && (
                          <span className={`time-dropdown-check-icon ${active ? 'time-dropdown-check-icon-active' : 'time-dropdown-check-icon-inactive'}`}>
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        )}
      </Listbox>
    </div>
  );
};

export default TimeDropdown;
