/* eslint-disable no-shadow */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/function-component-definition */
import { Fragment, useState, useEffect } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline';
import '../../../../css/Workflows.css';

interface WorkflowType {
  id: string;
  label: string;
  type?: string;
  disabled?: boolean;
}

interface WorkflowDropdownProps {
  Types: WorkflowType[];
  selected: WorkflowType | null;
  selectHandler: (workflow: WorkflowType) => void;
}

export default function WorkflowDropdown({ Types, selected, selectHandler }: WorkflowDropdownProps) {
  const getInitialSelection = () => {
    if (!selected || Object.keys(selected).length === 0) {
      return Types[1] || Types[0];
    }
    return selected;
  };

  const [selectedType, setSelectedType] = useState<WorkflowType>(getInitialSelection());
  const [workflowTypes, setWorkflowTypes] = useState<WorkflowType[]>(Types);

  useEffect(() => {
    setSelectedType(getInitialSelection());
    setWorkflowTypes(Types);
  }, [Types, selected]);

  const handleSelection = (workflow: WorkflowType) => {
    setSelectedType(workflow);
    selectHandler(workflow);
  };

  return (
    <Listbox value={selectedType} onChange={handleSelection}>
      {({ open }) => (
        <div className="workflow-dropdown-wrapper">
          <Listbox.Button className="workflow-dropdown-button">
            <span className="workflow-dropdown-text">{selectedType.label || selectedType.type}</span>
            <span className="workflow-dropdown-icon-wrapper">
              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </Listbox.Button>

          <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options className="workflow-dropdown-options">
              {workflowTypes.map((workflow) => (
                <Listbox.Option
                  key={workflow.id}
                  value={workflow}
                  disabled={workflow.disabled}
                  className={({ active }) => `workflow-dropdown-option ${active ? 'workflow-dropdown-option-active' : 'workflow-dropdown-option-inactive'}`}
                >
                  {({ selected, active }) => (
                    <div>
                      <span className={selected ? 'font-semibold' : 'font-normal'}>{workflow.label}</span>
                      {selected && (
                        <span className={`workflow-dropdown-check ${active ? 'workflow-dropdown-check-active' : 'workflow-dropdown-check-inactive'}`}>
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}
