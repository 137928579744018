import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

// TypingAnimation Component
interface TypingAnimationProps {
  children: string;
  className?: string;
  duration?: number;
  delay?: number;
}

export function TypingAnimation({ children, className, duration = 60, delay = 0 }: TypingAnimationProps) {
  const [displayedText, setDisplayedText] = useState<string>('');
  const [started, setStarted] = useState(false);

  useEffect(() => {
    const startTimeout = setTimeout(() => {
      setStarted(true);
    }, delay);
    return () => clearTimeout(startTimeout);
  }, [delay]);

  useEffect(() => {
    if (!started) return;

    let i = 0;
    const typingEffect = setInterval(() => {
      if (i < children.length) {
        setDisplayedText(children.substring(0, i + 1));
        i++;
      } else {
        clearInterval(typingEffect);
      }
    }, duration);

    return () => {
      clearInterval(typingEffect);
    };
  }, [children, duration, started]);

  return <span className={classNames('text-sm font-normal tracking-tight', className)}>{displayedText}</span>;
}

// Terminal Component
interface TerminalProps {
  children: React.ReactNode;
  className?: string;
  title?: React.ReactNode;
  actions?: React.ReactNode;
  status?: {
    storage?: 'red' | 'yellow' | 'green' | 'off';
    network?: 'red' | 'yellow' | 'green' | 'off';
    compute?: 'red' | 'yellow' | 'green' | 'off';
  };
}

export function Terminal({ children, className, title, actions, status = { storage: 'off', network: 'off', compute: 'off' } }: TerminalProps) {
  const getStatusColor = (status: 'red' | 'yellow' | 'green' | 'off') => {
    switch (status) {
      case 'red':
        return 'bg-red-500';
      case 'yellow':
        return 'bg-yellow-500';
      case 'green':
        return 'bg-green-500';
      default:
        return 'bg-gray-500/30';
    }
  };

  return (
    <div className="z-0 h-full max-h-[400px] w-full max-w-lg rounded-xl border-2 border-gray-500 bg-background">
      <div className="flex items-center justify-between border-b-2 border-gray-500 p-4">
        <div className="flex items-center gap-x-4">
          <div className="flex gap-x-2">
            <div className={classNames('h-2 w-2 rounded-full', getStatusColor(status.storage))} />
            <div className={classNames('h-2 w-2 rounded-full', getStatusColor(status.network))} />
            <div className={classNames('h-2 w-2 rounded-full', getStatusColor(status.compute))} />
          </div>
        </div>
        <div className="flex items-center gap-x-2">
          {title}
          {actions}
        </div>
      </div>
      <pre className="p-4">
        <code className="block overflow-auto whitespace-pre-wrap">{children}</code>
      </pre>
    </div>
  );
}

// Default export and named exports
export default Terminal;
export type { TerminalProps, TypingAnimationProps };
