import React from 'react';
import '../../../../css/Workflows.css';

interface URLInputBoxProps {
  // eslint-disable-next-line react/require-default-props
  leftInput?: React.ReactNode;
  url: string;
  value: string;
  onChange: () => void;
}

export default function URLInputBox({ leftInput, url, onChange, value }: URLInputBoxProps) {
  return (
    <div className="url-input-container">
      {leftInput && <span className="url-input-prefix">{leftInput}</span>}
      <label htmlFor="url-input" className="url-input-label">
        {url}
      </label>
      <input id="url-input" type="text" name="url" value={value} onChange={onChange} className="url-input-with-prefix" aria-label={url} />
    </div>
  );
}
