import React, { Fragment, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid';
import '../../../../css/Base.css';

// Type definitions
interface Workspace {
  SK: string;
  name: string;
}

interface WorkspaceDropdownProps {
  selectedWorkspace?: Workspace;
  accountPermissions?: Workspace[];
  workspaces?: Workspace[];
  setSelectedWorkspaceHandler: (workspace: Workspace) => void;
}

// Utility function for combining classes
const classNames = (...classes: any): string => {
  return classes.filter(Boolean).join(' ');
};

export default function WorkspaceDropdown({ selectedWorkspace, accountPermissions, workspaces, setSelectedWorkspaceHandler }: WorkspaceDropdownProps) {
  // State for selected workspace and available workspaces
  const [selected, setSelected] = useState<Workspace | undefined>(selectedWorkspace);
  const [availableWorkspaces, setAvailableWorkspaces] = useState<Workspace[] | undefined>(accountPermissions || workspaces);

  // Update state when props change
  useEffect(() => {
    setSelected(selectedWorkspace);
    setAvailableWorkspaces(accountPermissions || workspaces);
  }, [accountPermissions, selectedWorkspace, workspaces]);

  // Handle workspace selection
  const handleWorkspaceSelect = (workspace: Workspace) => {
    setSelected(workspace);
    setSelectedWorkspaceHandler(workspace);
  };

  // Animation variants
  const dropdownVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, x: -10 },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.2,
      },
    },
    hover: {
      scale: 1.02,
      transition: { duration: 0.2 },
    },
  };

  return (
    <Listbox value={selected} onChange={handleWorkspaceSelect}>
      {({ open }) => (
        <motion.div className="workspace-dropdown-container" initial="hidden" animate="visible" variants={dropdownVariants}>
          <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
            <Listbox.Button className="workspace-dropdown-button">
              <span className="workspace-dropdown-button-text">{selected?.name || 'Select Workspace'}</span>
              <span className="workspace-dropdown-button-icon-wrapper">
                <ChevronUpDownIcon className="workspace-dropdown-button-icon" aria-hidden="true" />
              </span>
            </Listbox.Button>
          </motion.div>

          <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options className="workspace-dropdown-options">
              {availableWorkspaces?.map((workspace) => (
                <motion.div key={workspace.SK} variants={itemVariants} whileHover="hover">
                  <Listbox.Option
                    value={workspace}
                    className={({ active }) =>
                      classNames(active ? 'workspace-dropdown-option-active' : 'workspace-dropdown-option-inactive', 'workspace-dropdown-option')
                    }
                  >
                    {({ selected: isSelected, active }) => (
                      <>
                        <span
                          className={classNames(
                            isSelected ? 'workspace-dropdown-option-text-selected' : 'workspace-dropdown-option-text-unselected',
                            'workspace-dropdown-option-text',
                          )}
                        >
                          {workspace.name}
                        </span>

                        {isSelected ? (
                          <motion.span
                            initial={{ opacity: 0, scale: 0.5 }}
                            animate={{ opacity: 1, scale: 1 }}
                            className={classNames(
                              active ? 'workspace-dropdown-option-check-icon-active' : 'workspace-dropdown-option-check-icon-inactive',
                              'workspace-dropdown-option-check-wrapper',
                            )}
                          >
                            <CheckIcon className="workspace-dropdown-option-check-icon" aria-hidden="true" />
                          </motion.span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                </motion.div>
              ))}
            </Listbox.Options>
          </Transition>
        </motion.div>
      )}
    </Listbox>
  );
}
