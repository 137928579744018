/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CogIcon } from '@heroicons/react/24/outline';
import InputBox from '../../../Base/Elements/Input/InputBox';
import TimeDropdown from './TimeDropdown';
import '../../../../css/Workflows.css';

interface SFTPConfigModalProps {
  isOpen: boolean;
  workflow: {
    trigger: {
      host?: string;
      port?: string;
      path?: string;
      username?: string;
      cadence?: string;
      key?: string;
      cert?: string;
    };
  };
  closeModal: () => void;
  onChangeUsername: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangePort: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangePath: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeHost: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeCadence: (value: string) => void;
  onChangeCert: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onChangeKey: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export default function SFTPConfigModal({
  isOpen,
  workflow,
  closeModal,
  onChangeHost,
  onChangePath,
  onChangePort,
  onChangeUsername,
  onChangeCadence,
  onChangeCert,
  onChangeKey,
}: SFTPConfigModalProps) {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="sftp-modal-container" onClose={closeModal}>
        <div className="sftp-modal-wrapper">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="sftp-modal-overlay" />
          </Transition.Child>

          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="sftp-modal-content">
              <div className="sftp-modal-body">
                <div className="sftp-modal-header">
                  <div className="sftp-modal-icon-wrapper">
                    <CogIcon className="sftp-modal-icon" aria-hidden="true" />
                  </div>
                  <div className="sftp-modal-title-wrapper">
                    <Dialog.Title as="h3" className="sftp-modal-title">
                      SFTP Configuration
                    </Dialog.Title>
                  </div>
                </div>

                <div className="sftp-form-container">
                  <InputBox name="Host" onChange={onChangeHost} defaultValue={workflow?.trigger?.host} placeholder="sftp://example.com" />
                  <InputBox name="Port" onChange={onChangePort} defaultValue={workflow?.trigger?.port} placeholder="22" />
                  <InputBox name="Path" onChange={onChangePath} defaultValue={workflow?.trigger?.path} placeholder="/path/to/files" />
                  <TimeDropdown selected={workflow.trigger.cadence} selectHandler={onChangeCadence} />
                  <InputBox name="Username" onChange={onChangeUsername} defaultValue={workflow?.trigger?.username} placeholder="username" />

                  <div className="sftp-textarea-wrapper">
                    <label htmlFor="key-trigger" className="sftp-textarea-label">
                      Key
                    </label>
                    <textarea
                      id="key-trigger"
                      name="key-trigger"
                      className="sftp-textarea"
                      rows={2}
                      defaultValue={workflow?.trigger?.key}
                      onChange={onChangeKey}
                    />
                  </div>

                  <div className="sftp-textarea-wrapper">
                    <label htmlFor="cert-trigger" className="sftp-textarea-label">
                      Certificate
                    </label>
                    <textarea
                      id="cert-trigger"
                      name="cert-trigger"
                      className="sftp-textarea"
                      rows={2}
                      defaultValue={workflow?.trigger?.cert}
                      onChange={onChangeCert}
                    />
                  </div>
                </div>
              </div>

              <div className="sftp-modal-footer">
                <button type="button" className="sftp-save-button" onClick={closeModal}>
                  Save
                </button>
                <button type="button" className="sftp-cancel-button" onClick={closeModal}>
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
