import React, { Fragment, useState, useEffect } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline';
import '../../../../css/Workflows.css';

interface Tunnel {
  PK: string;
  SK: string;
  name: string;
}

interface TunnelDropdownProps {
  tunnels: Tunnel[];
  selected: Tunnel | null;
  selectHandler: (tunnel: Tunnel) => void;
}

export default function TunnelDropdown({ tunnels, selected, selectHandler }: TunnelDropdownProps) {
  const [selectedTunnel, setSelectedTunnel] = useState<Tunnel | null>(selected);
  const [tunnelList, setTunnelList] = useState<Tunnel[]>(tunnels);

  useEffect(() => {
    setSelectedTunnel(selected);
    setTunnelList(tunnels);
  }, [tunnels, selected]);

  const handleSelection = (tunnel: Tunnel) => {
    setSelectedTunnel(tunnel);
    selectHandler(tunnel);
  };

  const getDisplayName = () => {
    if (selectedTunnel?.PK && selectedTunnel.name) {
      return selectedTunnel.name;
    }
    return tunnelList[0]?.name || 'Select a tunnel';
  };

  return (
    <Listbox value={selectedTunnel} onChange={handleSelection} className="tunnel-dropdown">
      {({ open }) => (
        <div className="tunnel-dropdown-wrapper">
          <Listbox.Button className="tunnel-dropdown-button">
            <span className="tunnel-dropdown-text">{getDisplayName()}</span>
            <span className="tunnel-dropdown-icon-wrapper">
              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </Listbox.Button>

          <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options className="tunnel-dropdown-options">
              {tunnelList.map((tunnel) => (
                <Listbox.Option
                  key={tunnel.SK}
                  value={tunnel}
                  className={({ active }) => `tunnel-dropdown-option ${active ? 'tunnel-dropdown-option-active' : 'tunnel-dropdown-option-inactive'}`}
                >
                  {({ selected, active }) => (
                    <div>
                      <span className={selected ? 'font-semibold' : 'font-normal'}>{tunnel.name}</span>
                      {selected && (
                        <span className={`tunnel-dropdown-check-wrapper ${active ? 'tunnel-dropdown-check-active' : 'tunnel-dropdown-check-inactive'}`}>
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}
