/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { TrashIcon, RocketLaunchIcon, CheckIcon } from '@heroicons/react/24/outline';
import Terminal, { TypingAnimation } from '../Tunnels/Elements/Terminal';
import { useEnvironment } from '../../context/EnvironmentContext';
import InputBox from '../Base/Elements/Input/InputBox';
import ConfirmModal from '../Base/Elements/Confirmations/ConfirmModal';

const STACKS = ['storage', 'network', 'compute'];
const STACK_STATUS_MAP = {
  CREATE_IN_PROGRESS: <span className="text-yellow-400">Creating...</span>,
  CREATE_FAILED: <span className="text-red-400">Failed</span>,
  CREATE_COMPLETE: <span className="text-green-400">Available</span>,
  ROLLBACK_IN_PROGRESS: <span className="text-yellow-400">Rolling back...</span>,
  ROLLBACK_FAILED: <span className="text-red-400">Rollback failed</span>,
  ROLLBACK_COMPLETE: <span className="text-green-400">Rolled back</span>,
  DELETE_IN_PROGRESS: <span className="text-yellow-400">Deleting...</span>,
  DELETE_FAILED: <span className="text-red-400">Delete failed</span>,
  DELETE_COMPLETE: <span className="text-gray-400">Not Found</span>,
  UPDATE_IN_PROGRESS: <span className="text-yellow-400">Updating...</span>,
  UPDATE_COMPLETE_CLEANUP_IN_PROGRESS: <span className="text-yellow-400">Updating...</span>,
  UPDATE_COMPLETE: <span className="text-green-400">Updated</span>,
  UPDATE_ROLLBACK_IN_PROGRESS: <span className="text-yellow-400">Rolling back...</span>,
  UPDATE_ROLLBACK_FAILED: <span className="text-red-400">Rollback failed</span>,
  UPDATE_ROLLBACK_COMPLETE_CLEANUP_IN_PROGRESS: <span className="text-yellow-400">Rolling back...</span>,
  UPDATE_ROLLBACK_COMPLETE: <span className="text-green-400">Rolled back</span>,
  REVIEW_IN_PROGRESS: <span className="text-yellow-400">Reviewing...</span>,
};

export default function EnvironmentList() {
  const { environments, loading, deleteEnvironment, deployStack, deleteStack, handleEnvironmentNameChange, saveEnvironmentName } = useEnvironment();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentEnvironment, setCurrentEnvironment] = useState(null);
  const [currentStack, setCurrentStack] = useState('');
  const [isEnvModalOpen, setIsEnvModalOpen] = useState(false);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.3 },
    },
    hover: {
      scale: 1.02,
      transition: { duration: 0.2 },
    },
  };

  // Modal handlers
  const openModal = (environment, stack) => {
    setCurrentEnvironment(environment);
    setCurrentStack(stack);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const confirmDeleteStack = async () => {
    await deleteStack(currentEnvironment, currentStack);
    closeModal();
  };

  const openEnvModal = (environment) => {
    setCurrentEnvironment(environment);
    setIsEnvModalOpen(true);
  };

  const closeEnvModal = () => {
    setIsEnvModalOpen(false);
  };

  const confirmDeleteEnvironment = async () => {
    await deleteEnvironment(currentEnvironment);
    closeEnvModal();
  };

  const getEnvironmentStatus = (environment) => {
    const getStackStatus = (status: string) => {
      if (!status) return 'off';

      if (status.includes('FAILED') || status.includes('ROLLBACK') || status.includes('DELETE_FAILED')) {
        return 'red';
      }

      if (status.includes('IN_PROGRESS') || status.includes('REVIEWING') || status.includes('UPDATE') || status.includes('CREATE_IN_PROGRESS')) {
        return 'yellow';
      }

      if (status === 'CREATE_COMPLETE' || status === 'UPDATE_COMPLETE') {
        return 'green';
      }

      return 'off';
    };

    return {
      storage: getStackStatus(environment.storage?.status),
      network: getStackStatus(environment.network?.status),
      compute: getStackStatus(environment.compute?.status),
    };
  };

  const renderEnvironmentCard = (environment) => {
    const titleElement = (
      <div className="flex items-center gap-x-2">
        <InputBox onChange={(event) => handleEnvironmentNameChange(event, environment)} value={environment?.name} placeholder={environment?.name} />
        <motion.button
          type="button"
          className="text-gray-400 hover:text-green-500 focus:outline-none"
          onClick={() => saveEnvironmentName(environment)}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <CheckIcon className="h-4 w-4" />
        </motion.button>
      </div>
    );

    const actionButtons = (
      <>
        {!environment?.storage?.status?.includes('DELETE') || !environment?.storage ? null : (
          <motion.button
            type="button"
            className="text-gray-400 hover:text-red-500 focus:outline-none"
            onClick={() => openEnvModal(environment)}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <TrashIcon className="h-4 w-4" />
          </motion.button>
        )}
      </>
    );

    return (
      <motion.div className="w-full" variants={itemVariants} whileHover="hover">
        <Terminal title={titleElement} actions={actionButtons} status={getEnvironmentStatus(environment)}>
          <div className="space-y-2 text-center px-0.5">
            <TypingAnimation duration={30} delay={100} className="text-xs text-gray-400">
              {`${environment.accountId} | ${environment.region}`}
            </TypingAnimation>

            <div className="grid gap-2">
              {STACKS.map((stack, index) => {
                const status = environment[stack]?.status || '';
                const isStorage = stack === 'storage';
                const storageStatus = environment.storage?.status;
                const canDelete = status && !status?.includes('IN_PROGRESS') && !status?.includes('DELETE_COMPLETE');
                const canDeploy = !environment[stack] || (environment[stack] && !status?.includes('PROGRESS') && !status?.includes('CREATE_COMPLETE'));
                const storageCreateComplete = storageStatus?.includes('CREATE_COMPLETE');
                const showRocket = (isStorage && canDeploy) || (!isStorage && canDeploy && storageCreateComplete);

                return (
                  <div key={stack} className="flex items-center justify-between text-xs">
                    <div className="flex">
                      <TypingAnimation duration={30} delay={(index + 2) * 100} className="text-gray-400">
                        {`${stack}`}
                      </TypingAnimation>
                      <TypingAnimation duration={30} delay={(index + 2) * 100} className="text-gray-400">
                        {`: `}
                      </TypingAnimation>
                    </div>
                    <div className="flex items-center gap-x-2">
                      {status ? STACK_STATUS_MAP[status] || <span className="text-gray-400">{status}</span> : <span className="text-gray-500">Not Found</span>}
                      <div className="flex gap-x-2">
                        {canDelete && (
                          <motion.button
                            type="button"
                            className="text-gray-400 hover:text-red-500"
                            onClick={() => openModal(environment, stack)}
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                          >
                            <TrashIcon className="h-5 w-5" />
                          </motion.button>
                        )}
                        {showRocket && (
                          <motion.button
                            type="button"
                            className="text-gray-400 hover:text-purple-500"
                            onClick={() => deployStack(environment, stack)}
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                          >
                            <RocketLaunchIcon className="h-5 w-5" />
                          </motion.button>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Terminal>
      </motion.div>
    );
  };

  return (
    <>
      <ConfirmModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={confirmDeleteStack}
        title="Confirm Delete Stack"
        message={`Are you sure you want to delete ${currentStack} for ${currentEnvironment?.name}?`}
      />
      <ConfirmModal
        isOpen={isEnvModalOpen}
        onClose={() => setIsEnvModalOpen(false)}
        onConfirm={confirmDeleteEnvironment}
        title="Confirm Delete Environment"
        message={`Are you sure you want to delete ${currentEnvironment?.name}?`}
      />

      <div className="col-span-5">
        <motion.dl
          className="grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <AnimatePresence>
            {environments?.map((environment) => (
              <motion.div key={environment?.SK}>
                {loading ? (
                  <Terminal>
                    <div className="flex items-center justify-center py-8">
                      <TypingAnimation duration={30} delay={0} className="text-gray-400">
                        Loading environment...
                      </TypingAnimation>
                    </div>
                  </Terminal>
                ) : (
                  renderEnvironmentCard(environment)
                )}
              </motion.div>
            ))}
          </AnimatePresence>
        </motion.dl>

        {environments && environments.length === 0 && (
          <motion.div className="w-full mt-8" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.3 }}>
            <Terminal>
              <div className="flex flex-col items-center justify-center py-8 space-y-2">
                <TypingAnimation duration={30} delay={0} className="text-gray-400">
                  No environments found
                </TypingAnimation>
                <TypingAnimation duration={30} delay={200} className="text-gray-400">
                  Create an environment to get started
                </TypingAnimation>
              </div>
            </Terminal>
          </motion.div>
        )}
      </div>
    </>
  );
}
