import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CogIcon } from '@heroicons/react/24/outline';
// eslint-disable-next-line import/extensions, import/no-unresolved
import CsvReader from '../../../Base/Elements/Input/CsvReader';
import '../../../../css/Workflows.css';

interface FieldMap {
  key: string;
  value: string;
  field: string;
  id: string;
}

interface TargetValueModalProps {
  workflow: {
    lookups: Record<string, any>;
  };
  selectedField: [{ definition: string }, { field: string }] | null;
  selectedFieldMap: {
    map: FieldMap[];
  };
  open: boolean;
  selectedDefaultValue: string;
  csvFile: File | null;
  handleLookupDefaultValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  clearToggle: (field: string) => void;
  setCsvFile: (file: File) => void;
  toggleLookUpModal: () => void;
  updateFieldMapKey: (event: React.ChangeEvent<HTMLInputElement>) => void;
  updateFieldMapValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
  removeRow: (id: string) => void;
  addRowToLookupsHandler: () => void;
}
interface DividerProps {
  label: string;
}

function Divider({ label }: DividerProps) {
  return (
    <div className="target-divider-wrapper">
      <div className="target-divider-line" aria-hidden="true">
        <div className="target-divider-border" />
      </div>
      <div className="target-divider-text">
        <span className="target-divider-label">{label}</span>
      </div>
    </div>
  );
}

export default function TargetValueModal({
  workflow,
  selectedField,
  selectedFieldMap,
  open,
  handleLookupDefaultValueChange,
  selectedDefaultValue,
  clearToggle,
  setCsvFile,
  toggleLookUpModal,
  csvFile,
  updateFieldMapKey,
  updateFieldMapValue,
  removeRow,
  addRowToLookupsHandler,
}: TargetValueModalProps) {
  const [field, setField] = useState<Record<string, any>>({ lookups: {} });

  const lookUpCallbackHandler = useCallback(() => {
    if (JSON.stringify(workflow.lookups) !== JSON.stringify(field)) {
      setField(workflow.lookups);
    }
  }, [workflow.lookups, field]);

  useEffect(() => {
    lookUpCallbackHandler();
  }, [workflow.lookups, lookUpCallbackHandler]);

  if (!selectedField || typeof selectedFieldMap?.map !== 'object') {
    return null;
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="target-modal-container" onClose={toggleLookUpModal}>
        <div className="target-modal-wrapper">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="target-modal-overlay" />
          </Transition.Child>

          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="target-modal-content">
              <div className="target-modal-header">
                <div className="target-modal-icon-wrapper">
                  <CogIcon className="target-modal-icon" />
                  <Dialog.Title as="h3" className="target-modal-title">
                    &nbsp; {selectedField[1]?.field || ''}
                  </Dialog.Title>
                  <Dialog.Title as="h3" className="target-modal-subtitle">
                    {selectedField[0]?.definition || ''}
                  </Dialog.Title>
                </div>
              </div>

              <div className="target-form-container">
                <input
                  className="target-input"
                  type="text"
                  name="defaultValue"
                  onChange={handleLookupDefaultValueChange}
                  placeholder="Default Value"
                  id={`default-${selectedField[1]?.field}`}
                  defaultValue={selectedDefaultValue}
                />

                <Divider label="CSV Lookup" />
                <CsvReader setCsvFile={setCsvFile} csvFile={csvFile} />
                <Divider label="Quick Lookup" />

                <div className="target-lookup-grid">
                  {selectedFieldMap.map.map((lookup: FieldMap, idx: number) => (
                    <Fragment key={lookup.id}>
                      <input
                        className="target-input"
                        type="text"
                        id={`match-${lookup.field}-${idx}`}
                        name="matchValue"
                        onChange={updateFieldMapKey}
                        placeholder="Source Value"
                        defaultValue={lookup.key}
                      />
                      <input
                        className="target-input"
                        type="text"
                        id={`replace-${lookup.field}-${idx}`}
                        name="matchOverride"
                        onChange={updateFieldMapValue}
                        placeholder="Replacement"
                        defaultValue={lookup.value}
                      />
                      <button type="button" className="target-action-button sm:col-start-5" onClick={() => removeRow(lookup.id)}>
                        -
                      </button>
                      {idx === 0 && (
                        <button type="button" className="target-action-button sm:col-start-6" onClick={addRowToLookupsHandler}>
                          +
                        </button>
                      )}
                    </Fragment>
                  ))}
                </div>
              </div>

              <div className="target-button-grid">
                <button type="button" className="target-close-button" onClick={toggleLookUpModal}>
                  Close
                </button>
                <button type="button" className="target-clear-button" onClick={() => clearToggle(selectedField[1].field)}>
                  Clear
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
