import React from 'react';
import { motion } from 'framer-motion';
import cloudPatient from '../../../images/cloud-patient.svg';

interface RetrohookHomeProps {
  matrix: boolean;
  matrixCSS: string;
  signUp: () => void;
  setMatrix: () => void;
  setShowTermsOfService: () => void;
}

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
      when: 'beforeChildren',
      staggerChildren: 0.2,
    },
  },
};

const titleVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      ease: 'easeOut',
    },
  },
};

const HeroText: React.FC<{
  matrix: boolean;
  matrixCSS: string;
}> = function ({ matrix, matrixCSS }) {
  return (
    <div className={`hero-title-base ${matrix ? matrixCSS : ''}`}>
      <motion.div initial="hidden" animate="visible" variants={titleVariants}>
        <h1>
          <motion.span style={{ fontFamily: '"Gugi", sans-serif', display: 'block' }} className="hero-title-primary">
            Instant Integration
          </motion.span>
          <motion.span style={{ fontFamily: '"Gugi", sans-serif', display: 'block' }} className="hero-title-gradient">
            Infinite Innovation
          </motion.span>
        </h1>
      </motion.div>
    </div>
  );
};

const CTAButton: React.FC<{
  signUp: () => void;
  setMatrix: () => void;
  matrix: boolean;
}> = function ({ signUp, setMatrix, matrix }) {
  return (
    <motion.div className="hero-cta-container" initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: 0.6 }}>
      <div className="hero-cta-wrapper">
        <motion.button
          type="button"
          onClick={() => {
            signUp();
            setMatrix(!matrix);
          }}
          className="hero-cta-button"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          transition={{ duration: 0.2 }}
        >
          Free Developer Account
        </motion.button>
      </div>
    </motion.div>
  );
};

const TermsOfServiceLink: React.FC<{
  setShowTermsOfService: () => void;
  setMatrix: () => void;
  matrix: boolean;
}> = function ({ setShowTermsOfService, setMatrix, matrix }) {
  return (
    <motion.p className="hero-terms-container" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.8 }}>
      by signing up, you agree to our{' '}
      <motion.span
        onClick={() => {
          setShowTermsOfService(true);
          setMatrix(!matrix);
        }}
        className="hero-terms-link"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        style={{ display: 'inline-block' }}
      >
        terms of service
      </motion.span>
    </motion.p>
  );
};

export default function RetrohookHome(props: RetrohookHomeProps) {
  const { matrix, matrixCSS, signUp, setMatrix, setShowTermsOfService } = props;

  return (
    <motion.div className="hero-main-container" initial="hidden" animate="visible" variants={containerVariants}>
      <div className="hero-content-wrapper">
        <motion.div className="hero-text-section">
          <div className="hero-text-inner">
            <HeroText matrix={matrix} matrixCSS={matrixCSS} />

            <motion.p
              className={`hero-subtitle-base ${matrix ? matrixCSS : ''}`}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
            >
              Empower your product with seamless, secure hl7 exchange.
            </motion.p>

            <div>
              <CTAButton signUp={signUp} setMatrix={setMatrix} matrix={matrix} />
              <TermsOfServiceLink setShowTermsOfService={setShowTermsOfService} setMatrix={setMatrix} matrix={matrix} />
            </div>
          </div>
        </motion.div>

        <motion.div
          className="hero-image-section"
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <motion.img className="hero-background-image" src={cloudPatient} alt="data UP UP" />
        </motion.div>
      </div>
    </motion.div>
  );
}
