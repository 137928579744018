import React from 'react';
import { motion } from 'framer-motion';
import cool from '../../../images/cool.png';
import config from '../../../config';

const { mainFeatures } = config;

interface Feature {
  id: number;
  imageUrl: string;
  category: {
    name: string;
    href: string;
  };
  href: string;
  title: string;
  preview: string;
}

function QuoteMark({ rotate = false }: { rotate?: boolean }) {
  return (
    <svg className={`h-6 w-6 inline ${rotate ? 'rotate-180' : ''} m-3 text-white opacity-25`} fill="currentColor" viewBox="0 0 32 32" aria-hidden="true">
      <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
    </svg>
  );
}

export default function FeaturesGrid() {
  // Variants for section animations
  const sectionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: 'easeOut',
      },
    },
  };

  // Variants for feature card animations
  const cardVariants = {
    hidden: { opacity: 0, scale: 0.95 },
    visible: (index: number) => ({
      opacity: 1,
      scale: 1,
      transition: {
        delay: index * 0.2,
        duration: 0.5,
        ease: 'easeOut',
      },
    }),
    hover: {
      scale: 1.05,
      transition: { duration: 0.3 },
    },
  };

  return (
    <div className="relative items-center">
      <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} className="py-6 my-16 border-t-2 border-t-cyan-400">
        <motion.div variants={sectionVariants} className="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
          <motion.h2 className="text-lg font-semibold text-cyan-500" style={{ fontFamily: '"Gugi", sans-serif' }}>
            Learn more about how Retrohook
          </motion.h2>
          <motion.p className="mt-2 text-3xl font-bold text-pink-400 dark:text-gray-200 tracking-tight sm:text-4xl sm:tracking-tight">
            <span className="mx-2" style={{ fontFamily: '"Gugi", sans-serif' }}>
              Accelerates HL7v2 Integration
            </span>
          </motion.p>
          <motion.p className="mb-10 mx-auto max-w-prose text-xl text-gray-500">
            <span className="mx-2">
              Work as a team or individual to deploy workflow automation in a fraction of the time with a DIY approach, from the comfort of your own
              infrastructure!
            </span>
          </motion.p>
        </motion.div>

        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          className="mx-auto px-4 grid gap-8 sm:px-6 lg:px-8 sm:grid-cols-1 grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 max-w-7xl"
        >
          {mainFeatures.map((post: Feature, index: number) => (
            <motion.div
              key={post.id}
              custom={index}
              variants={cardVariants}
              whileHover="hover"
              className="flex flex-col rounded-lg ring-2 ring-gray-200 dark:ring-cyan-400 overflow-hidden cursor-pointer"
            >
              <motion.div className="flex-shrink-0">
                <motion.img className="h-48 w-full object-cover" src={post.imageUrl} alt={post.title} whileHover={{ scale: 1.05 }} />
              </motion.div>
              <div className="flex-1 p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <motion.p className="text-sm font-medium text-pink-500 dark:text-cyan-400" whileHover={{ scale: 1.1 }}>
                    <a href={post.category.href} className="hover:underline">
                      {post.category.name}
                    </a>
                  </motion.p>
                  <a href={post.href} className="block mt-2">
                    <motion.p className="text-xl font-semibold text-gray-700 dark:text-gray-200" whileHover={{ scale: 1.05 }}>
                      {post.title}
                    </motion.p>
                    <motion.p className="text-base text-gray-500 dark:text-gray-400" whileHover={{ scale: 1.03 }}>
                      {post.preview}
                    </motion.p>
                  </a>
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6 }}
        className="bg-cyan-500 bg-gradient-to-r from-cyan-300 lg:z-10"
      >
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 grid grid-cols-3 lg:grid lg:grid-cols-3 lg:gap-8">
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="lg:-mt-28 md:-mt-14 mt-32"
          >
            <div className="mx-auto px-4 p-0 lg:h-full">
              <div className="aspect-w-10 aspect-h-10 overflow-hidden sm:aspect-w-7 sm:aspect-h-7 lg:aspect-none lg:h-full">
                <motion.img className="max-h-96 sm:max-h-84 md:max-h-84" src={cool} alt="Retrohook doc" whileHover={{ scale: 1.05 }} />
              </div>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="lg:m-0 col-span-2 lg:pl-8"
          >
            <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-0 mt-8 lg:max-w-none">
              <blockquote>
                <div>
                  <motion.p
                    className="mt-4 sm:text-sm md:text-lg lg:text-2xl xl:text-2xl font-medium text-white"
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.6 }}
                  >
                    <QuoteMark />
                    Is integration slowing you down? Supercharge your sales pipeline by seamlessly connecting EHRs to your platform—without the headaches.
                    <br />
                    <br />
                    Retrohook enables rapid onboarding of legacy healthcare systems, minimizing provider friction, letting you scale effortlessly.
                    <QuoteMark rotate />
                  </motion.p>
                </div>
              </blockquote>
            </div>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
}
