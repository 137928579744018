import React, { Fragment, useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Dialog, Transition } from '@headlessui/react';
import { HomeIcon } from '@heroicons/react/24/outline';
import WorkspaceDropdown from './WorkspaceDropdown';
import '../../../../css/Base.css';

interface Workspace {
  SK?: string;
  name: string;
}

interface WorkspaceSelectModalProps {
  workspaces?: Workspace[];
  accountPermissions?: Workspace[];
  open: boolean;
  closeSelectModal: () => void;
  selectedWorkspace?: Workspace;
  setSelectedWorkspaceHandler: (workspace: Workspace) => void;
}

const modalVariants = {
  hidden: { opacity: 0, y: 50, scale: 0.95 },
  visible: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      type: 'spring',
      stiffness: 300,
      damping: 30,
    },
  },
  exit: {
    opacity: 0,
    y: 30,
    scale: 0.95,
    transition: { duration: 0.2 },
  },
};

const headerVariants = {
  hidden: { opacity: 0, x: -20 },
  visible: {
    opacity: 1,
    x: 0,
    transition: { delay: 0.2, duration: 0.3 },
  },
};

export default function WorkspaceSelectModal({
  workspaces,
  accountPermissions,
  open,
  closeSelectModal,
  selectedWorkspace,
  setSelectedWorkspaceHandler,
}: WorkspaceSelectModalProps) {
  const [selected, setSelected] = useState<Workspace | undefined>(selectedWorkspace);

  useEffect(() => {
    if (selectedWorkspace) {
      setSelected(selectedWorkspace);
    }
  }, [accountPermissions, selectedWorkspace]);

  return (
    <AnimatePresence>
      {open && (
        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="workspace-modal" onClose={closeSelectModal}>
            <div className="workspace-modal-container">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="workspace-modal-overlay" />
              </Transition.Child>

              <span className="workspace-modal-spacer" aria-hidden="true">
                &#8203;
              </span>

              <motion.div className="workspace-modal-content" variants={modalVariants} initial="hidden" animate="visible" exit="exit">
                <motion.div className="workspace-modal-header" variants={headerVariants} initial="hidden" animate="visible">
                  <motion.div whileHover={{ rotate: 10 }} transition={{ type: 'spring', stiffness: 300 }}>
                    <HomeIcon className="workspace-modal-icon" />
                  </motion.div>
                  <Dialog.Title className="workspace-modal-title">Change Workspace</Dialog.Title>
                </motion.div>

                <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: 0.3 }} className="workspace-modal-dropdown">
                  <WorkspaceDropdown
                    workspaces={workspaces}
                    accountPermissions={accountPermissions}
                    selectedWorkspace={selected}
                    setSelectedWorkspaceHandler={setSelectedWorkspaceHandler}
                  />
                </motion.div>

                <motion.div className="workspace-modal-footer" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.4 }}>
                  <motion.button
                    type="button"
                    className="workspace-modal-close-button"
                    onClick={closeSelectModal}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    transition={{ type: 'spring', stiffness: 400, damping: 17 }}
                  >
                    Close
                  </motion.button>
                </motion.div>
              </motion.div>
            </div>
          </Dialog>
        </Transition.Root>
      )}
    </AnimatePresence>
  );
}
