/* eslint-disable consistent-return */
/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import useFetch from 'use-http';
import config from '../../config';

const awsProductURL = 'https://aws.amazon.com/marketplace/pp/prodview-boo4ccgoad3ae';

interface Props {
  user: any;
  selectedWorkspace: any;
  selectedEnvironment: any;
  selectedAccount: any;
  notify: ({ message: any }) => void;
  setSelectedEnvironment: () => void;
  updateBillingCustomer: () => void;
  logout: () => void;
  account: any;
  environments: any[];
  setSelectedWorkspace: (workspace: any) => void;
  setEnvironments: () => void;
  token: string;
  admin: boolean;
}

// eslint-disable-next-line import/prefer-default-export
export const useWorkspace = (props: Props) => {
  const { user, token, admin } = props;
  const { post, get, response, loading } = useFetch(config.url, { cachePolicy: 'no-cache' });

  const basePermissions = {
    dashboard: { read: true },
    workflows: { create: false, read: false, update: false, delete: false },
    tunnels: { create: false, read: false, delete: false },
    workspace: { admin: false },
  };

  const newInvite = {
    firstName: '',
    lastName: '',
    email: '',
    tempPass: '',
    permissions: basePermissions,
  };

  // State declarations
  const [subUx, setSubUx] = useState('Settings');
  const [error, setError] = useState<string | null>(null);
  const [products, setProducts] = useState<any[]>([]);
  const [contactModal, setContactModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<any | null>(null);
  const [togglePermissions, setTogglePermissions] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>({ permissions: basePermissions, email: '', name: '', id: '' });
  const [invite, setInvite] = useState<any>(newInvite);
  const [customer, setCustomer] = useState({});
  const [billingUX, setBillingUX] = useState('');
  const [loadingType, setLoadingType] = useState('');
  const [quantityPurchased, setQuantityPurchased] = useState(0);
  const [workspace, setWorkspace] = useState<any>();
  const [account, setAccount] = useState<any>(user?.account);
  const [selectedAccount, setSelectedAccount] = useState<any>([]);
  const [environments, setEnvironments] = useState<any>([]);
  const [selectedWorkspace, setSelectedWorkspace] = useState<any>();
  const [selectedEnvironment, setSelectedEnvironment] = useState<any>();
  const [accountPermissions, setAccountPermissions] = useState<any>();
  const [environmentChanged, setEnvironmentChanged] = useState<any>(false);

  const notify = (data: { message: string }) => toast(data.message);

  const getFullEnvironment = async (environment: any) => {
    if (!user || !token) return null;

    try {
      const url = `${config.url}/environment/get/${user.sub}`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ environment }),
      });
      const data = await response.json();

      if (response && data) {
        if (!selectedWorkspace) setSelectedWorkspace(user.account.workspaces[0]);
        if (selectedWorkspace) {
          selectedWorkspace.environments = selectedWorkspace.environments.filter((env: any) => env.SK !== data.environment.SK);
          selectedWorkspace.environments.push(data.environment);
        }
        return data.environment;
      }
      return null;
    } catch (error: any) {
      notify({ message: error.message });
      return null;
    }
  };

  const fetchWorkspace = async (workspaceId: string) => {
    if (!user || !token || !workspaceId) return null;

    try {
      const url = `${config.url}/account/get-workspace/${user.sub}`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ workspaceSK: workspaceId }),
      });
      const data = await response.json();

      if (response && response.ok) {
        const envs = data?.workspace?.environments;
        setEnvironments(selectedWorkspace?.environments.length > 0 ? selectedWorkspace.environments : data.workspace.environments.sort().reverse());
        const selected = selectedEnvironment || envs[0] || null;
        setSelectedEnvironmentHandler(selected);
        return data.workspace;
      }
      return null;
    } catch (error: any) {
      notify({ message: error.message });
      return null;
    }
  };

  const setSelectedEnvironmentHandler = async (requested: any) => {
    const fullEnv = await getFullEnvironment(requested);
    setSelectedEnvironment(fullEnv);
  };

  const setSelectedWorkspaceHandler = async (requested: any) => {
    const workspace = await fetchWorkspace(requested.SK);
    setSelectedWorkspace(workspace);
  };

  const createAccount = async (accountParams: any) => {
    if (!user || !token) {
      notify({ message: 'Error creating account' });
      return null;
    }

    try {
      const res = await fetch(`${config.url}/account/register-account/${accountParams.id}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ accountParams }),
      });
      return res.json();
    } catch (err: any) {
      throw new Error(err.message);
    }
  };

  const fetchAccount = async () => {
    if (!user || !token) return;

    try {
      let location = {
        ip: '',
        city: '',
        state: '',
        country: '',
      };

      const locationResponse = await fetch('https://api.geoapify.com/v1/ipinfo?apiKey=43fce59071bf4235804363c5f18e5f32');

      if (locationResponse) {
        const locationData = await locationResponse.json();
        location = {
          ip: locationData.ip,
          city: locationData.city.name,
          state: locationData.state.name,
          country: locationData.country.name,
        };
      }

      const url = `${config.url}/account/get-account/${user.sub}?ip=${location.ip}&city=${location.city}&state=${location.state}&country=${location.country}`;

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();
      if (response && response.ok && data.account) {
        setAccount(data.account);
        setSelectedAccount(data.account);
        const workspace = await fetchWorkspace(data.account.workspaces[0].SK);
        setSelectedWorkspace(workspace);
        setAccountPermissions(workspace.permissions);
      } else {
        await createAccount({
          id: user.sub,
          email: user.email,
          name: user.name,
          organization: user.nickname,
          location,
        });
        await fetchAccount();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getProducts = async () => {
    if (!user?.sub || !token) return;

    const productItems: any = await get(`account/get-stripe-products/${user.sub}`);
    if (productItems?.data) {
      const items = productItems.data;
      const promises = items.map((product: any) => {
        if (product.product[0].name === 'Production Plan' && product.product[0].active) {
          product.features = [
            'Everything in Developer Plan +',
            'Create unlimited workflows for streamlined legacy healthcare events.',
            'Invite unlimited team members to collaborate in a shared, efficient workspace.',
            'Simple AWS Deployment: Deploy Retrohook into your AWS account with ease.',
            'Production Environment: Create a single production environment for your organization. (more can be purchased)',
            'Secure Connectivity: Generate and establish up to 5 IPSEC VPN Tunnels for robust and secure remote connections.',
          ];
        } else if (product.product[0].name === 'Developer Plan' && product.product[0].active) {
          product.features = [
            'Effortless Serverless Healthcare Messaging: Experience seamless hl7v2 communication without the complexities of server management',
            'Test Workflows: Design and test up to 5 non-PHI workflows, perfect for experimentation and learning.',
            'Developer Workspace: Collaborate effectively with a 3-seat workspace designed for dynamic development teams.',
            'Message Allocation: Benefit from 100 messages per month, allowing for ample testing and development.',
            'Community Support: Gain insights and assistance from a community that understands your development needs.',
          ];
        }
        return product;
      });
      const products: any = await Promise.all(promises).then((res) => res);
      const filtered = products.filter((product: any) => product.product[0].active !== false && product.active !== false);
      setProducts(filtered.sort((a: any, b: any) => (a.unit_amount > b.unit_amount ? 1 : -1)));
    }
  };
  const selectProduct = async (product: any, qp: number) => {
    if (product.product[0].name !== 'Enterprise Plan' && product.product[0].name !== 'Developer Plan') {
      if (props.account.type !== 'aws') {
        await checkoutStripe(product.id, qp);
        setSelectedProduct(product);
        setPaymentModal(!paymentModal);
      } else {
        window.location.replace(awsProductURL);
      }
    }
    if (product.product[0].name === 'Enterprise Plan') {
      setSelectedProduct(product);
      setContactModal(!contactModal);
    }
  };

  const getStripeCustomer = async () => {
    const cst: any = await get(`account/get-stripe-customer/${props.account.customerId}`);
    if (response.ok) {
      return cst.customer;
    }
  };

  const getCustomerPortal = async () => {
    if (props.account.customerIdentifier) {
      if (props.account.type === 'stripe') {
        const customerPortal: any = await get(`account/generate-stripe-customer-portal-session/${props.account.customerIdentifier}`);
        if (response.ok) window.location.replace(customerPortal.portalSession.url);
      }
      if (props.account.type === 'aws') {
        window.location.replace(awsProductURL);
      }
    }
  };

  const createStripeCustomer = async () => {
    const body = { email: props.selectedWorkspace.email };
    const newCustomer: any = await post(`account/create-stripe-customer/${props.user.sub}`, body);
    if (response.ok) {
      return newCustomer.customer;
    }
  };

  const checkoutStripe = async (priceId: string, qp: number) => {
    let { customerId } = props.account;
    if (props.account.customerId) {
      const cst = await getStripeCustomer();
      customerId = cst.id;
      setCustomer(cst);
    } else {
      const cs = await createStripeCustomer();
      customerId = cs.id;
      setCustomer(cs);
    }
    const body = { priceId, qp, customerId, account: props.account };
    const checkout: any = await post(`account/generate-stripe-checkout-session/${props.user.sub}`, body);
    if (response.ok) {
      window.location.replace(checkout.session.url);
    }
  };

  const handleNewUserFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updated = { ...invite, firstName: event.target.value };
    setInvite(updated);
  };

  const handleNewUserLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updated = { ...invite, lastName: event.target.value };
    setInvite(updated);
  };

  const handleNewUserEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updated = { ...invite, email: event.target.value };
    setInvite(updated);
  };

  const handleMessageIDValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updated = { ...workspace, hash: { location: workspace.hash.location, value: event.target.value } };
    setWorkspace(updated);
  };

  const handleMessageIDChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updated = { ...workspace, hash: { value: workspace.hash.value, location: event.target.value } };
    setWorkspace(updated);
  };

  const handleOrganizationValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedWorkspace = {
      ...workspace,
      name: event.target.value,
    };
    setWorkspace(updatedWorkspace);
  };

  const openPermissions = (user: any) => {
    setSelectedUser(user);
    setTogglePermissions(true);
  };

  const closePermissions = () => {
    setTogglePermissions(false);
    setSelectedUser({ permissions: basePermissions, email: '', name: '', id: '' });
  };

  const clearInvite = () => {
    setInvite({ firstName: '', lastName: '', email: '', tempPass: '', permissions: basePermissions });
  };

  const addUserToWorkspace = async (slug: string) => {
    if (!invite.firstName || !invite.lastName || !invite.email) {
      setError('MissingInviteFields');
      return;
    }
    invite.tempPass = `${(Math.random() + 1).toString(36).substring(6)}-HL7!`;
    const body = { workspace, invite, account: props.account };
    const usersMax = props.account.quantityPurchased < 1 ? 5 : props.account.quantityPurchased * 10;

    if (workspace.accounts.length >= usersMax) {
      setError('AccountUserLimitAlert');
    } else {
      const inviteRequest = await post(`account/workspace-invite/${slug}`, body);
      if (response.ok && inviteRequest) {
        await fetchFreshWorkspace();
        props.notify({ message: '📤 Invite Sent' });
      }
    }
    setLoadingType('');
    clearInvite();
  };

  const removeInviteFromWorkspace = async (iv: any) => {
    const params = { invite: iv, workspace, account: props.account };
    const rsp = await post(`account/workspace-remove-invite/${props.user.sub}`, params);
    if (response && rsp) {
      await fetchFreshWorkspace();
      props.notify({ message: 'Invite Removed From Workspace' });
    }
  };

  const removeUserFromWorkspace = async (se: any) => {
    const params = { selectedUser: se, workspaceParams: workspace, account: props.account };
    const rsp = await post(`account/workspace-remove/${props.user.sub}`, params);
    if (response && rsp) {
      await fetchFreshWorkspace();
      props.notify({ message: 'User Removed From Workspace' });
    }
  };

  const updatePermissions = (resource: string, permission: string) => {
    selectedUser.permissions[resource][permission] = !JSON.parse(selectedUser.permissions[resource][permission]);
    const accounts = workspace.accounts.map((account: any) => {
      if (account.email === selectedUser.email) {
        if (account.permissions[resource][permission] === true || account.permissions[resource][permission] === 'true') {
          account.permissions[resource][permission] = true;
        } else {
          account.permissions[resource][permission] = false;
        }
      }
      return account;
    });
    setWorkspace({ ...workspace, accounts });
  };

  const savePermissions = async () => {
    const rsp = await post(`account/update-workspace/${props.selectedWorkspace.PK}`, { account: props.account, workspace: props.selectedWorkspace });
    if (response.ok && rsp) {
      props.notify({ message: 'Permissions Updated' });
    }
    closePermissions();
  };

  const saveWorkspace = async (ws: any) => {
    const rsp = await post(`account/update-workspace/${props.user.sub}`, { account: props.account, workspace: ws });
    if (response.ok && rsp) {
      setWorkspace(rsp.workspace);
      props.setSelectedWorkspace(rsp.workspace);
      props.notify({ message: 'Workspace Updated' });
    }
  };

  const fetchFreshWorkspace = async () => {
    if (props?.selectedWorkspace?.SK) {
      const freshWorkspace = await post(`account/get-workspace/${props.user.sub}`, {
        workspaceSK: props.selectedWorkspace.SK,
      });
      if (response.ok && freshWorkspace) {
        setWorkspace(freshWorkspace.workspace);
        props.setSelectedWorkspace(freshWorkspace.workspace);
      }
    }
  };

  useEffect(() => {
    if (user?.sub && token && !account) {
      fetchAccount();
    }
  }, [user?.sub, token, account]);

  useEffect(() => {
    if (props.selectedWorkspace?.SK) {
      fetchFreshWorkspace();
    }
  }, [props.selectedWorkspace?.SK]);

  useEffect(() => {
    if (user?.sub && token) {
      getProducts();
    }
  }, [user?.sub, token]);

  return {
    subUx,
    setSubUx,
    error,
    setError,
    products,
    contactModal,
    setContactModal,
    paymentModal,
    setPaymentModal,
    selectedProduct,
    setSelectedProduct,
    togglePermissions,
    setTogglePermissions,
    selectedUser,
    setSelectedUser,
    invite,
    setInvite,
    customer,
    setCustomer,
    billingUX,
    setBillingUX,
    loadingType,
    setLoadingType,
    quantityPurchased,
    setQuantityPurchased,
    workspace,
    setWorkspace,
    getProducts,
    selectProduct,
    getStripeCustomer,
    getCustomerPortal,
    createStripeCustomer,
    checkoutStripe,
    handleNewUserFirstNameChange,
    handleNewUserLastNameChange,
    handleNewUserEmailChange,
    handleMessageIDValueChange,
    handleMessageIDChange,
    handleOrganizationValueChange,
    openPermissions,
    closePermissions,
    clearInvite,
    addUserToWorkspace,
    removeUserFromWorkspace,
    updatePermissions,
    savePermissions,
    saveWorkspace,
    loading,
    awsProductURL,
    removeInviteFromWorkspace,
    account,
    selectedAccount,
    environments,
    selectedWorkspace,
    selectedEnvironment,
    accountPermissions,
    environmentChanged,
    setAccount,
    setSelectedAccount,
    setEnvironments,
    setSelectedWorkspace,
    setSelectedEnvironment,
    setAccountPermissions,
    setEnvironmentChanged,
    setSelectedEnvironmentHandler,
    setSelectedWorkspaceHandler,
    fetchAccount,
    fetchWorkspace,
    fetchFreshWorkspace,
  };
};
