/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import EnvironmentForm from './EnvironmentForm';
import EnvironmentList from './EnvironmentList';
import { useEnvironment } from '../../context/EnvironmentContext';
import aws from '../../../images/aws2.png';
import '../../css/Environments.css';

export default function EnvironmentContent({
  setShowCreateForm,
  showCreateForm,
}: {
  setShowCreateForm: React.Dispatch<React.SetStateAction<boolean>>;
  showCreateForm: boolean;
}) {
  const { hydrateAllEnvironments } = useEnvironment();

  React.useEffect(() => {
    hydrateAllEnvironments();
  }, [showCreateForm]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3,
      },
    },
  };

  return (
    <motion.div className="min-h-5/6 pb-6 m-2 border-2 shadow border-gray-500 rounded-md" initial="hidden" animate="visible" variants={containerVariants}>
      <motion.div className="environment-header" variants={itemVariants}>
        <motion.img className="environment-header-logo" src={aws} alt="AWS" whileHover={{ scale: 1.1 }} />
        <div className="environment-header-actions">
          <AnimatePresence>
            {!showCreateForm && (
              <motion.button
                key="create-button"
                type="button"
                id="create-environment"
                onClick={() => setShowCreateForm(!showCreateForm)}
                className="environment-header-button"
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 20 }}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Connect
              </motion.button>
            )}
            {showCreateForm && (
              <motion.button
                key="cancel-button"
                type="button"
                id="cancel-environment"
                onClick={() => setShowCreateForm(!showCreateForm)}
                className="environment-header-button"
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 20 }}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Cancel
              </motion.button>
            )}
          </AnimatePresence>
          <motion.button
            type="button"
            id="refresh-environments"
            onClick={() => hydrateAllEnvironments()}
            className="environment-refresh-button"
            variants={itemVariants}
            whileHover={{ rotate: 180 }}
            whileTap={{ scale: 0.9 }}
          >
            <ArrowPathIcon className="h-5 w-5" />
          </motion.button>
        </div>
      </motion.div>

      <AnimatePresence mode="wait">
        {showCreateForm ? (
          <motion.div
            key="create-form"
            className="environment-create-form-container"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            transition={{ duration: 0.3 }}
          >
            <EnvironmentForm setShowCreateForm={setShowCreateForm} />
          </motion.div>
        ) : (
          <motion.div
            key="environment-list"
            className="environment-list-container"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            transition={{ duration: 0.3 }}
          >
            <EnvironmentList />
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
}
