/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/function-component-definition */
// EnvironmentContext.tsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import useFetch from 'use-http';
import config from '../../config';

interface Environment {
  SK: string;
  name: string;
  accountId: string;
  region: string;
  resources?: any[];
  storage?: any;
  network?: any;
  compute?: any;
  [key: string]: any;
}

interface EnvironmentContextProps {
  environments: Environment[];
  availableRegions: any[];
  userCF: any;
  loading: boolean;
  account: any;
  selectedWorkspace: any;
  setAccount: () => void;
  setEnvironments: () => void;
  setSelectedWorkspace: () => void;
  createEnvironment: (environmentData: any) => Promise<void>;
  deleteEnvironment: (environment: Environment) => Promise<void>;
  getUserCF: () => Promise<void>;
  getRegions: () => Promise<void>;
  deployStack: (environment: Environment, stack: string) => Promise<void>;
  deleteStack: (environment: Environment, stack: string) => Promise<void>;
  getStackResources: (environment: Environment) => Promise<void>;
  handleEnvironmentNameChange: (event: any, environment: Environment) => void;
  saveEnvironmentName: (environment: Environment) => Promise<void>;
  notify: (message: string) => void;
  environmentChanged: string;
  setEnvironmentChanged: React.Dispatch<React.SetStateAction<boolean>>;
  hydrateAllEnvironments: (newEnvironments?: Environment[]) => Promise<void>;
  hydrateEnvironment: (environment: Environment) => Promise<void>;
  getAccount: () => Promise<void>;
}

const EnvironmentContext = createContext<EnvironmentContextProps | undefined>(undefined);

export const EnvironmentProvider: React.FC<any> = ({
  user,
  account,
  selectedWorkspace,
  notify,
  children,
  environmentChanged,
  setEnvironmentChanged,
  getAccount,
  setAccount,
  environments,
  setSelectedWorkspace,
  setEnvironments,
}) => {
  const { post, get, response, loading } = useFetch(config.url, { cachePolicy: 'no-cache' });
  const [availableRegions, setAvailableRegions] = useState<any[]>([]);
  const [userCF, setUserCF] = useState<any>('');
  const [envs, setEnvs] = useState<Environment[]>(environments);

  const filterOutReservedEnvironments = (envS: Environment[]) => {
    return envS.filter((environment: Environment) => environment.name !== '🚀 Production' && environment.name !== '🧪 Development');
  };
  const getUserCF = async () => {
    const userCFRequest = await get(`environment/setup-user/`);
    if (response.ok && userCFRequest) {
      setUserCF(userCFRequest.json);
    }
  };
  const getRegions = async () => {
    const regions = await get(`environment/get-regions/`);
    if (response.ok) {
      setAvailableRegions(regions.regions.Regions);
    }
  };
  const getStackResources = async (environment: Environment) => {
    const environmentRequest = await post(`environment/get/${user.sub}`, { environment });
    if (response.ok && environmentRequest) {
      return environmentRequest.environment;
    }
    return environment;
  };
  const hydrateAllEnvironments = async () => {
    const updatedEnvironments = await Promise.all(
      environments.map(async (environment) => {
        const env = await getStackResources(environment);
        return env || environment;
      }),
    );
    setEnvs(filterOutReservedEnvironments(updatedEnvironments));
  };
  const hydrateEnvironment = async (environment: Environment) => {
    const env = await getStackResources(environment);
    if (env && response.ok) {
      const updatedEnvironments = environments.filter((e: any) => (e.SK != environment.SK ? e : environment));
      setEnvironments(updatedEnvironments);
    }
  };
  const createEnvironment = async (environmentData: any) => {
    setAccount(user.account);
    if (
      !environmentData.name ||
      !environmentData.awsAccountID ||
      !environmentData.awsRegion ||
      !environmentData.awsUserClientId ||
      !environmentData.awsUserSecret
    ) {
      notify({ message: 'Please fill in all required fields.' });
      return;
    }

    if (!account.quantityPurchased || account.quantityPurchased <= selectedWorkspace.environments.length + 1) {
      notify({ message: 'You have reached your environment limit.' });
      return;
    }

    const params = { environment: environmentData, workspace: selectedWorkspace, account };
    const request = await post(`environment/create/${user.sub}`, params);

    if (response.ok && request) {
      const newEnvironments = filterOutReservedEnvironments(request.environments);
      setEnvironments(newEnvironments);
      setSelectedWorkspace(request.workspace);
      setAccount(request.account);
      notify({ message: '🎉 Environment Created' });
    } else {
      notify({ message: 'Failed to create environment.' });
    }
  };
  const deleteEnvironment = async (environment: Environment) => {
    const params = { environment, workspace: selectedWorkspace, account: user.account };
    const deleteRequest = await post(`environment/delete-environment/${user.sub}`, params);

    if (response.ok && deleteRequest) {
      const updatedEnvironments = environments.filter((env) => env.SK !== environment.SK);
      setEnvironments(updatedEnvironments);
      setSelectedWorkspace(deleteRequest.workspace);
      setAccount(deleteRequest.account);
      notify({ message: `␡ Deleted ${environment.name}` });
    } else {
      notify({ message: 'Failed to delete environment.' });
    }
  };
  const deployStack = async (environment: Environment, stack: string) => {
    const fullEnvironment = await getStackResources(environment);
    const params = { environment: fullEnvironment, workspace: selectedWorkspace, account: user.account };
    const deployRequest = await post(`environment/deploy-${stack}/${user.sub}`, params);
    if (deployRequest && response.ok) {
      await getStackResources(environment);
      setAccount(deployRequest.account);
    } else {
      notify({ message: `Failed to deploy ${stack} stack.` });
    }
  };
  const deleteStack = async (environment: Environment, stack: string) => {
    const params = { environment, workspace: selectedWorkspace, account: user.account };
    const deleteRequest = await post(`environment/delete-${stack}/${user.sub}`, params);
    if (deleteRequest && response.ok) {
      setAccount(deleteRequest.account);
    } else {
      notify({ message: `Failed to delete ${stack} stack.` });
    }
  };
  const handleEnvironmentNameChange = (event: any, environment: Environment) => {
    const updatedEnv = { ...environment, name: event.target.value };
    setEnvironments((prevEnvs) => prevEnvs.map((env) => (env.SK === environment.SK ? updatedEnv : env)));
  };
  const saveEnvironmentName = async (environment: Environment) => {
    const params = {
      environment,
      workspace: selectedWorkspace,
      account,
    };
    const envUpdateRequest = await post(`account/update-account-environment/${user.sub}`, params);
    if (response.ok && envUpdateRequest) {
      await getStackResources(environment);
      setAccount(envUpdateRequest.account);
      setSelectedWorkspace(envUpdateRequest.workspace);
      notify({ message: 'Environment name saved.' });
    } else {
      notify({ message: 'Failed to save environment name.' });
    }
  };

  useEffect(() => {
    if (account && selectedWorkspace) {
      getUserCF();
      getRegions();
      hydrateAllEnvironments();
      setEnvs(filterOutReservedEnvironments(selectedWorkspace.environments));
    }
  }, [account, selectedWorkspace]);

  useEffect(() => {
    if (environmentChanged) {
      hydrateAllEnvironments();
      setEnvironmentChanged(false);
    }
  }, [environmentChanged]);

  return (
    <EnvironmentContext.Provider
      value={{
        environments: envs,
        availableRegions,
        userCF,
        loading,
        account,
        selectedWorkspace,
        createEnvironment,
        deleteEnvironment,
        getUserCF,
        getRegions,
        deployStack,
        deleteStack,
        getStackResources,
        handleEnvironmentNameChange,
        saveEnvironmentName,
        hydrateAllEnvironments,
        hydrateEnvironment,
        notify,
        environmentChanged,
        setEnvironmentChanged,
        getAccount,
      }}
    >
      {children}
    </EnvironmentContext.Provider>
  );
};

export const useEnvironment = () => {
  const context = useContext(EnvironmentContext);
  if (context === undefined) {
    throw new Error('useEnvironment must be used within an EnvironmentProvider');
  }
  return context;
};
