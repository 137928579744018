import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Listbox } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid';
import '../../../../css/Base.css';

// Type definitions
interface Environment {
  SK: string;
  name: string;
}

interface EnvironmentDropdownProps {
  selectedEnvironment?: Environment;
  environments?: Environment[];
  selectedWorkspace?: any;
  setSelectedEnvironmentHandler: (environment: Environment) => void;
}

// Utility function for combining classes
const classNames = (...classes: any): string => {
  return classes.filter(Boolean).join(' ');
};

export default function EnvironmentDropdown({ selectedEnvironment, environments, selectedWorkspace, setSelectedEnvironmentHandler }: EnvironmentDropdownProps) {
  const [selected, setSelected] = useState<Environment | undefined>(selectedEnvironment);
  const [availableEnvironments, setAvailableEnvironments] = useState<Environment[] | undefined>(environments);

  // Update selected environment when workspace changes
  useEffect(() => {
    setSelected(selectedEnvironment);
    setAvailableEnvironments(environments);
  }, [selectedWorkspace, selectedEnvironment, environments]);

  // Handle environment selection
  const handleEnvironmentSelect = (environment: Environment) => {
    setSelected(environment);
    setSelectedEnvironmentHandler(environment);
  };

  // Animation variants
  const dropdownVariants = {
    hidden: { opacity: 0, y: -10 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.2,
        delayChildren: 0.1,
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, x: -10 },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.2,
      },
    },
    hover: {
      scale: 1.02,
      transition: { duration: 0.2 },
    },
  };

  return (
    <Listbox value={selected} onChange={handleEnvironmentSelect}>
      {({ open }) => (
        <motion.div className="env-dropdown-container" initial="hidden" animate="visible" variants={dropdownVariants}>
          <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
            <Listbox.Button className="env-dropdown-button">
              <span className="env-dropdown-button-text">{selected?.name || 'Select environment'}</span>
              <span className="env-dropdown-button-icon-wrapper">
                <ChevronUpDownIcon className="env-dropdown-button-icon" aria-hidden="true" />
              </span>
            </Listbox.Button>
          </motion.div>

          <AnimatePresence>
            {open && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.2 }}
                className="env-dropdown-options-wrapper"
              >
                <Listbox.Options className="env-dropdown-options">
                  {availableEnvironments?.map((environment) => (
                    <motion.div key={environment.SK} variants={itemVariants} whileHover="hover">
                      <Listbox.Option
                        value={environment}
                        className={({ active }) => classNames(active ? 'env-dropdown-option-active' : 'env-dropdown-option-inactive', 'env-dropdown-option')}
                      >
                        {({ selected: isSelected, active }) => (
                          <>
                            <span
                              className={classNames(
                                isSelected ? 'env-dropdown-option-text-selected' : 'env-dropdown-option-text-unselected',
                                'env-dropdown-option-text',
                              )}
                            >
                              {environment.name}
                            </span>

                            {isSelected ? (
                              <motion.span
                                initial={{ opacity: 0, scale: 0.5 }}
                                animate={{ opacity: 1, scale: 1 }}
                                className={classNames(
                                  active ? 'env-dropdown-option-check-icon-active' : 'env-dropdown-option-check-icon-inactive',
                                  'env-dropdown-option-check-wrapper',
                                )}
                              >
                                <CheckIcon className="env-dropdown-option-check-icon" aria-hidden="true" />
                              </motion.span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    </motion.div>
                  ))}
                </Listbox.Options>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      )}
    </Listbox>
  );
}
