/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import { useState, useEffect, useCallback, createContext, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Provider } from 'use-http';

import ReconnectingWebSocket from 'reconnecting-websocket';
import qs from 'qs';
import toast, { Toaster } from 'react-hot-toast';
import { ThemeProvider } from './app/context/ThemeContext';
import Navigation from './app/components/Base/Layout/Navigation';
import Footer from './app/components/Base/Layout/Footer';
import { Product } from './app/pages';
import PrivateRoutes from './app/components/Base/Layout/PrivateRoutes';
import WorkspaceSelectModal from './app/components/Base/Layout/NavigationElements/WorkspaceSelectModal';
import EnvironmentSelectModal from './app/components/Base/Layout/NavigationElements/EnvironmentSelectModal';
import ChatwootWidget from './app/components/Base/Layout/ChatWootWidget';
import config from './config';

const WebSocketContext = createContext(null);
export const useWebSocketContext = () => useContext(WebSocketContext);
const notify = (data: { message: string }) => toast(data.message);

export default function App() {
  const history = useHistory();
  const location = useLocation();
  const { logout, user, loginWithRedirect, isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();
  const [version, setVersion] = useState('loading...');
  const [wsClient, setWsClient] = useState(null);
  const [nav, setNav] = useState<any>(config.nav);
  const [account, setAccount] = useState<any>(user?.account);
  const [token, setToken] = useState<any>('');
  const [environments, setEnvironments] = useState<any>([]);
  const [selectedAccount, setSelectedAccount] = useState<any>([]);
  const [selectedWorkspaceModal, setSelectedWorkspaceModal] = useState<any>(false);
  const [selectedEnvironmentModal, setSelectedEnvironmentModal] = useState<any>(false);
  const [updateBillingCustomer, setUpdateBillingCustomer] = useState<any>(false);
  const [selectedEnvironment, setSelectedEnvironment] = useState<any>();
  const [selectedWorkspace, setSelectedWorkspace] = useState<any>();
  const [accountPermissions, setAccountPermissions] = useState<any>();
  const [environmentChanged, setEnvironmentChanged] = useState<any>(false);

  const updateNav = (href: string) => {
    const newNav = nav.map((item: any) => {
      if (item.href === href) return { ...item, current: true };
      return { ...item, current: false };
    });
    setNav(newNav);
  };
  const redirectToURL = (page: string) => {
    history.push(`/${page}`);
    updateNav(page);
  };
  const handleLogout = () => {
    if (wsClient) wsClient.send(JSON.stringify({ event: 'brb' }));
    // eslint-disable-next-line no-undef
    logout({ logoutParams: { returnTo: window.location.origin } });
  };
  const getRetrohookVersion = async () => {
    try {
      const packageJson = await import('@1putthealth/retrohook-utilities/package.json');
      return packageJson.version;
    } catch (error) {
      return 'unknown';
    }
  };
  const setNavHighlight = (pageName: string) => {
    const newNav = nav.map((item: any) => {
      if (item.name === pageName) return { ...item, current: true };
      return { ...item, current: false };
    });
    setNav(newNav);
  };
  const createAccount = async (accountParams: any) => {
    if (user && token) {
      try {
        const res = await fetch(`${config.url}/account/register-account/${accountParams.id}`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ accountParams }),
        });
        return res.json() || null;
      } catch (err: any) {
        throw new Error(err.message);
      }
    } else {
      notify({ message: 'Error creating account' });
      return null;
    }
  };
  const getFullEnvironment = async (environment: any) => {
    if (user && token) {
      try {
        const url = `${config.url}/environment/get/${user.sub}`;
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ environment }),
        });
        const data = await response.json();
        if (response && data) {
          if (!selectedWorkspace) setSelectedWorkspace(user.account.workspaces[0]);
          if (selectedWorkspace) {
            selectedWorkspace.environments = selectedWorkspace.environments.filter((env: any) => env.SK !== data.environment.SK);
            selectedWorkspace.environments.push(data.environment);
          }
          return data.environment;
        }
        return null;
      } catch (error) {
        notify({ message: error.message });
      }
    }
    return null;
  };
  const setSelectedEnvironmentHandler = async (requested: any) => {
    const fullEnv = await getFullEnvironment(requested);
    setSelectedEnvironment(fullEnv);
    setSelectedEnvironmentModal(false);
  };
  const fetchWorkspace = async (workspaceId: string) => {
    if (user && token.length > 0 && workspaceId) {
      try {
        const url = `${config.url}/account/get-workspace/${user.sub}`;
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ workspaceSK: workspaceId }),
        });
        const data = await response.json();
        if (response && response.ok) {
          const envs = data?.workspace?.environments;
          setEnvironments(selectedWorkspace?.environments.length > 0 ? selectedWorkspace.environments : data.workspace.environments.sort().reverse());
          const selected = selectedEnvironment || envs[0] || null;
          setSelectedEnvironmentHandler(selected);
          return data.workspace;
        }
        return null;
      } catch (error) {
        notify({ message: error.message });
      }
    }
    return null;
  };
  const setSelectedWorkspaceHandler = async (requested: any) => {
    const workspace = await fetchWorkspace(requested.SK);
    setSelectedWorkspace(workspace);
    setSelectedWorkspaceModal(false);
  };
  const fetchAccount = async () => {
    if (user && token.length > 0) {
      try {
        let location = {
          ip: '',
          city: '',
          state: '',
          country: '',
        };
        const locationResponse = await fetch('https://api.geoapify.com/v1/ipinfo?apiKey=43fce59071bf4235804363c5f18e5f32', {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });

        if (locationResponse) {
          const locationData = await locationResponse.json();
          location = {
            ip: locationData.ip,
            city: locationData.city.name,
            state: locationData.state.name,
            country: locationData.country.name,
          };
        }

        const url = `${config.url}/account/get-account/${user.sub}?ip=${location.ip}&city=${location.city}&state=${location.state}&country=${location.country}`;

        const response = await fetch(url, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        if (response && response.ok && data.account) {
          setAccount(data.account);
          setSelectedAccount(data.account);
          const workspace = await fetchWorkspace(data.account.workspaces[0].SK);
          setSelectedWorkspace(workspace);
          setAccountPermissions(workspace.permissions);
        } else {
          await createAccount({
            id: user.sub,
            email: user.email,
            name: user.name,
            organization: user.nickname,
            location,
          });
          await fetchAccount();
        }
      } catch (error) {
        console.error(error);
      }
    }
  };
  const postToken = async (validationToken: string) => {
    const newAccountWithWorkspaceRequest = await fetch(`${config.url}/hooks/accept-invitation/${validationToken}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (newAccountWithWorkspaceRequest.ok) {
      const newAccount = newAccountWithWorkspaceRequest.data.account;
      const newWorkspace = newAccountWithWorkspaceRequest.data.workspace;
      setAccount(newAccount);
      setSelectedAccount(newAccount);
      setSelectedWorkspace(newWorkspace);
      setAccountPermissions(newWorkspace.permissions);
      redirectToURL('dashboard');
      setNav(
        nav.map((item: any) => {
          if (item.href === `/dashboard`) return { ...item, current: true };
          return { ...item, current: false };
        }),
      );
      notify({ message: 'Invitation accepted' });
    }
  };
  const createWSSClient = (userId: string) => {
    const newClient = new ReconnectingWebSocket(config.wssUrl as string);
    newClient.addEventListener('open', () => {
      newClient.send(JSON.stringify({ event: 'hai', userId, token }));
    });

    newClient.addEventListener('message', (messageEvent: any) => {
      const messageData = JSON.parse(messageEvent.data);
      if (messageData.message && !messageData.suppress) notify({ message: messageData.message });
      if (messageData?.environmentChanged) {
        setEnvironmentChanged(true);
      }
    });
    setWsClient(newClient);
    return newClient;
  };

  const setAccountCallback = useCallback(
    async (selected: any) => {
      setAccount(selected);
    },
    [setAccount],
  );

  const setUpdateBilling = useCallback(
    async (selected: any) => {
      setUpdateBillingCustomer(selected);
    },
    [setUpdateBillingCustomer],
  );

  useEffect(() => {
    (async () => {
      try {
        const tk = await getAccessTokenSilently();
        setToken(tk);
        return tk;
      } catch (error) {
        return null;
      }
    })();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    // Clear cancelled stripe subscription mid process
    const canceled = !!qs.parse(history.location.search, { ignoreQueryPrefix: true }).canceled;
    if (canceled) {
      setUpdateBilling(true);
      history.replace({ search: '' });
    }
    // Accept workspace invitation
    const acceptInvitation = async (validationToken: string) => {
      await postToken(validationToken);
    };
    const fetchAcc = async () => {
      await fetchAccount();
    };
    const fetchWrk = async () => {
      if (user?.account) {
        if (selectedWorkspace?.SK) {
          const work = fetchWorkspace(selectedWorkspace.SK);
          setSelectedWorkspace(work);
          setAccountPermissions(selectedWorkspace.permissions);
        } else {
          const work = fetchWorkspace(user.account.myWorkspace.SK);
          setSelectedWorkspace(work);
          if (work?.environments && work?.environments.length > 0) {
            setSelectedEnvironmentHandler(work.environments[0]);
          }
          setAccountPermissions(user.account.myWorkspace.permissions);
        }
        if (selectedEnvironment) setSelectedEnvironmentHandler(selectedEnvironment);
      }
    };
    // Validate new invites
    const { validationToken } = qs.parse(history.location.search, { ignoreQueryPrefix: true }) as any;
    if (validationToken && validationToken.length > 0) {
      acceptInvitation(validationToken as string);
    } else {
      if (!account && user?.sub && token) fetchAcc();
      if (user?.account && !selectedWorkspace) fetchWrk();
      if (user) {
        user.token = token;
        user.account = account;
      }
      if (user?.account && user.sub && token && !wsClient) {
        const newClient = createWSSClient(user.sub);
        setWsClient(newClient as any);
      }
    }
  }, [user?.account, history.location.search, token, account, selectedWorkspace]);

  // Redirect user to desired page after login
  useEffect(() => {
    const currentPath = location.pathname.substring(1);
    if (currentPath) redirectToURL(currentPath);
    setNav(
      nav.map((item: any) => {
        if (item.href === `/${currentPath}`) return { ...item, current: true };
        return { ...item, current: false };
      }),
    );
    const getVersion = async () => {
      const retro = await getRetrohookVersion();
      setVersion(retro);
      // eslint-disable-next-line no-console
      console.info(`Retrohook version: ${retro}`);
    };
    getVersion();
  }, []);

  const contextValue = () => ({ wsClient, notify, createWSSClient });
  let options = {};
  if (token) options = { headers: { Authorization: `Bearer ${token}` } };
  if (isAuthenticated && !isLoading) {
    return (
      <ThemeProvider>
        <div className="flex flex-col h-screen">
          <ChatwootWidget />
          <WebSocketContext.Provider value={contextValue}>
            <Toaster />
            <Provider url={config.url} options={options}>
              <Navigation
                user={user}
                account={account}
                selectedWorkspace={selectedWorkspace}
                setSelectedWorkspaceModal={setSelectedWorkspaceModal}
                setSelectedEnvironmentModal={setSelectedEnvironmentModal}
                selectedEnvironment={selectedEnvironment}
                logout={() => handleLogout()}
                nav={nav}
                history={history}
                updateNav={updateNav}
                redirectToURL={(url: string) => redirectToURL(url)}
                version={version}
              />
              <WorkspaceSelectModal
                user={user}
                selectedWorkspace={selectedWorkspace}
                workspaces={account?.workspaces}
                setSelectedWorkspaceHandler={setSelectedWorkspaceHandler}
                accountPermissions={accountPermissions}
                closeSelectModal={() => setSelectedWorkspaceModal(false)}
                open={selectedWorkspaceModal}
                getAccount={fetchAccount}
              />
              <EnvironmentSelectModal
                user={user}
                selectedEnvironment={selectedEnvironment}
                environments={selectedWorkspace?.environments}
                setSelectedEnvironment={setSelectedEnvironmentHandler}
                closeSelectModal={() => setSelectedEnvironmentModal(false)}
                open={selectedEnvironmentModal}
                selectedWorkspace={selectedWorkspace}
                accountPermissions={accountPermissions}
                setSelectedEnvironmentHandler={setSelectedEnvironmentHandler}
                getAccount={fetchAccount}
              />
              <main className="animate-color-change-2x dark:animate-color-change-2x-dark h-screen overflow-hidden">
                <PrivateRoutes
                  account={account}
                  setAccount={setAccountCallback}
                  getAccount={fetchAccount}
                  environments={selectedWorkspace?.environments}
                  history={history}
                  updateBillingCustomer={updateBillingCustomer}
                  selectedEnvironment={selectedEnvironment}
                  setupEnvironmentsHandler={setEnvironments}
                  setSelectedEnvironment={setSelectedEnvironmentHandler}
                  selectedAccount={selectedAccount}
                  selectedWorkspace={selectedWorkspace}
                  setSelectedWorkspace={setSelectedWorkspace}
                  setSelectedAccount={setSelectedAccount}
                  redirectToURL={(url: string) => redirectToURL(url)}
                  notify={notify}
                  environmentChanged={environmentChanged}
                  setEnvironmentChanged={setEnvironmentChanged}
                  user={user}
                  setEnvironments={setEnvironments}
                  fetchAccount={fetchAccount}
                />
              </main>
              <Footer />
            </Provider>
          </WebSocketContext.Provider>
        </div>
      </ThemeProvider>
    );
  }
  if (!isAuthenticated) {
    return (
      <Product
        signUp={() =>
          loginWithRedirect({
            appState: { returnTo: '/' },
            authorizationParams: { screen_hint: 'signup', prompt: 'login' },
          })
        }
        login={() => loginWithRedirect()}
        notify={notify}
        setNavHighlight={setNavHighlight}
      />
    );
  }
}
