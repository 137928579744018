import React from 'react';
import '../../../../css/Workflows.css';

export default function FlowHeading({ title }: { title: string }) {
  return (
    <div className="w-full mt-2 inline px-2 mx-auto">
      <button type="button" className="heading">
        {title}
      </button>
    </div>
  );
}
