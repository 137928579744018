import React from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { XMarkIcon, Bars3Icon, BookOpenIcon } from '@heroicons/react/24/outline';
import { NavLink } from 'react-router-dom';
import logo from '../../../../images/retrohook.png';
import ThemeToggler from './ThemeToggler';
import '../../../css/Base.css';

// External URLs
const EXTERNAL_URLS = {
  DOCS: 'https://docs.retrohook.com/docs/intro',
  DISCORD: 'https://discord.com/channels/947629811646480414/1112590923893833780',
  WORKSPACE: '/workspace',
};

// Type definitions
interface NavigationProps {
  user: {
    picture: string;
    name: string;
    email: string;
  };
  history: { push: (path: string) => void };
  nav: Array<{
    name: string;
    href: string;
    current: boolean;
    icon: React.ComponentType<{ className?: string }>;
  }>;
  redirectToURL: (url: string) => void;
  version: string;
  updateNav: (href: string) => void;
  setSelectedWorkspaceModal: (show: boolean) => void;
  setSelectedEnvironmentModal: (show: boolean) => void;
  logout: () => void;
}

// Utility function for combining classes
function classNames(...classes: any): string {
  return classes.filter(Boolean).join(' ');
}

// Reusable button configurations
const DROPDOWN_MENU_ITEMS = [
  {
    key: 'Workspace-Select',
    label: 'Workspace',
    action: ({ setSelectedWorkspaceModal }: NavigationProps) => setSelectedWorkspaceModal(true),
  },
  {
    key: 'Environment-Select',
    label: 'Environment',
    action: ({ setSelectedEnvironmentModal }: NavigationProps) => setSelectedEnvironmentModal(true),
  },
  {
    key: 'Workspace',
    label: 'Settings',
    action: ({ updateNav, history }: NavigationProps) => {
      updateNav('');
      history.push(EXTERNAL_URLS.WORKSPACE);
    },
  },
  {
    key: 'Sign Out',
    label: 'Sign Out',
    action: ({ logout }: NavigationProps) => logout(),
  },
];

export default function Navigation({
  user,
  history,
  nav,
  redirectToURL,
  version,
  updateNav,
  setSelectedWorkspaceModal,
  setSelectedEnvironmentModal,
  logout,
}: NavigationProps) {
  // Render navigation items
  const renderNavItems = (items: typeof nav, isMobile = false) =>
    items.map((item) => (
      <NavLink
        key={item.name}
        to={item.href}
        onClick={() => updateNav(item.href)}
        className={classNames(
          // eslint-disable-next-line no-nested-ternary
          item.current ? (isMobile ? 'nav-mobile-link-current' : 'nav-link-current') : isMobile ? 'nav-mobile-link-inactive' : 'nav-link-inactive',
          isMobile ? 'nav-mobile-link' : 'nav-link',
        )}
        aria-current={item.current ? 'page' : undefined}
      >
        <item.icon className={isMobile ? 'h-6 w-6 inline' : 'nav-link-icon'} />
        {item.name}
      </NavLink>
    ));

  // Render dropdown menu items
  const renderDropdownItems = () =>
    DROPDOWN_MENU_ITEMS.map(({ key, label, action }) => (
      <Menu.Item key={key}>
        {({ active }) => (
          <button
            type="button"
            onClick={() =>
              action({
                user,
                history,
                nav,
                redirectToURL,
                version,
                updateNav,
                setSelectedWorkspaceModal,
                setSelectedEnvironmentModal,
                logout,
              })
            }
            className={classNames(active ? 'nav-dropdown-item-active' : '', 'nav-dropdown-item')}
          >
            {label}
          </button>
        )}
      </Menu.Item>
    ));

  // Render external links
  const renderExternalLinks = () => (
    <>
      <a href={EXTERNAL_URLS.DOCS} target="_blank" className="block px-3 py-2 rounded-md text-base font-medium text-white cursor-pointer" rel="noreferrer">
        <BookOpenIcon className="h-6 w-6 inline text-gray-600" />
        Docs
      </a>
      <a href={EXTERNAL_URLS.DISCORD} target="_blank" className="flex font-medium text-sm text-white cursor-pointer" rel="noreferrer">
        <span className="hover:bg-cyan-400 hover:bg-opacity-75 px-4 py-2 hover:border-transparent hover:rounded-md">Discord</span>
      </a>
    </>
  );

  return (
    <Disclosure as="nav" className="nav-container">
      {({ open }) => (
        <>
          <div className="nav-wrapper">
            <div className="nav-content">
              {/* Logo Section */}
              <div className="nav-logo-container">
                <div className="flex-shrink-0">
                  <button type="button" onClick={() => redirectToURL('')}>
                    <img className="nav-logo" src={logo} alt="logo" />
                    <span className="nav-logo-text" style={{ fontFamily: '"Gugi", sans-serif' }}>
                      Retrohook
                    </span>
                    <span className="nav-version-badge">
                      v{version.split('.')[0]}.{version.split('.')[1]}.{version.split('.')[2].substring(0, 1)}
                    </span>
                    <span className="inline text-xs align-middle text-cyan-300 pl-1" />
                  </button>
                </div>

                {/* Desktop Navigation */}
                <div className="nav-desktop-menu">
                  <div className="nav-desktop-links">{renderNavItems(nav)}</div>
                </div>
              </div>

              {/* Desktop Right Section */}
              <div className="nav-desktop-right">
                <div className="ml-4 flex items-center md:ml-6">
                  <a href={EXTERNAL_URLS.DOCS} target="_blank" className="nav-docs-container" rel="noreferrer">
                    <span className="nav-docs-text">
                      <BookOpenIcon className="h-6 w-6 inline" />
                      Docs
                    </span>
                  </a>

                  <ThemeToggler />

                  {/* Profile Dropdown */}
                  <Menu as="div" className="relative z-40">
                    <div>
                      <Menu.Button className="nav-profile-button">
                        <span className="sr-only">Open user menu</span>
                        <img className="nav-profile-image" src={user.picture} alt="" />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={React.Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="nav-dropdown-menu">{renderDropdownItems()}</Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>

              {/* Mobile Menu Button */}
              <div className="-mr-2 flex md:hidden">
                <Disclosure.Button className="nav-mobile-button">
                  <span className="sr-only">Open main menu</span>
                  {open ? <XMarkIcon className="block h-6 w-6" aria-hidden="true" /> : <Bars3Icon className="block h-6 w-6" aria-hidden="true" />}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          {/* Mobile Navigation Panel */}
          <Disclosure.Panel className="nav-mobile-panel">
            <div className="nav-mobile-links">
              {renderNavItems(nav, true)}
              {renderExternalLinks()}
            </div>

            <div className="nav-mobile-user-section">
              <div className="nav-mobile-user-info">
                <div className="ml-3">
                  <div className="nav-mobile-user-name">{user.name}</div>
                  <div className="nav-mobile-user-email">{user.email}</div>
                </div>
              </div>

              {/* Mobile Submenu Items */}
              {DROPDOWN_MENU_ITEMS.slice(0, -1).map(({ key, label, action }) => (
                <div key={key} className="nav-mobile-submenu">
                  <Disclosure.Button
                    onClick={() =>
                      action({
                        user,
                        history,
                        nav,
                        redirectToURL,
                        version,
                        updateNav,
                        setSelectedWorkspaceModal,
                        setSelectedEnvironmentModal,
                        logout,
                      })
                    }
                    as="a"
                    className="nav-mobile-submenu-item"
                  >
                    {label}
                  </Disclosure.Button>
                </div>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
