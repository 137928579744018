/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Cog8ToothIcon, TrashIcon, SparklesIcon } from '@heroicons/react/24/outline';
import SaveInAnotherAccountAlert from '../../Base/Elements/Alerts/SaveInAnotherAccountAlert';
import UserPermissionsAlert from '../../Base/Elements/Alerts/UserPermissionsAlert';
import FlowHeading from './Elements/FlowHeading';
import Toggle from './Elements/GridToggle';
import logo from '../../../../images/retrohook.png';

import { TLayerProps } from '../../../types';
import '../../../css/Workflows.css';

export default function TLayer({
  error,
  workflow,
  fieldMapGrid: initialFieldMapGrid,
  fhirLoading: initialFhirLoading,
  fhirProgress: initialFhirProgress,
  sampleFilter,
  loading,
  clearError,
  enableSampleFilter,
  fetchFHIRR4Map,
  updateFieldMap,
  toggleLookUpModal,
  removeFieldMapRow,
}: TLayerProps) {
  const [fieldMapGrid, setFieldMapGrid] = useState(initialFieldMapGrid);
  const [fhirLoading, setFhirLoading] = useState(initialFhirLoading);
  const [fhirProgress, setFhirProgress] = useState(initialFhirProgress);

  useEffect(() => {
    setFieldMapGrid(initialFieldMapGrid);
    setFhirLoading(initialFhirLoading);
    setFhirProgress(initialFhirProgress);
  }, [initialFieldMapGrid, initialFhirLoading, initialFhirProgress]);

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3,
      },
    },
    hover: {
      scale: 1.02,
      transition: { duration: 0.2 },
    },
  };

  const renderMainTable = () => (
    <motion.table className="data-map-table" initial="hidden" animate="visible" variants={containerVariants}>
      <thead className="data-map-header">
        <tr>
          <td colSpan={4}>
            <motion.div className="data-map-title-row" variants={itemVariants}>
              <h2 className="data-map-title">Data Map</h2>
              <div className="data-map-controls">
                {(workflow.next.type === 'S3 FHIR R4' || workflow.next.type === 'HTTPS FHIR R4') && (
                  <motion.div className="fhir-button" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                    {fhirLoading ? (
                      <div className="fhir-button-loading">
                        <div className="fhir-progress-bar" style={{ height: `${fhirProgress}%` }} />
                        <SparklesIcon className="fhir-icon" />
                      </div>
                    ) : (
                      <div className="fhir-button-idle" onClick={fetchFHIRR4Map}>
                        <SparklesIcon className="fhir-icon" />
                      </div>
                    )}
                  </motion.div>
                )}
                {workflow.trigger.type.split(' ')[1] === 'HL7v2' && (
                  <div className="toggle-container">
                    <Toggle enabled={sampleFilter} setEnabled={enableSampleFilter} />
                    <span>View All</span>
                  </div>
                )}
              </div>
            </motion.div>
            <div className="table-divider" />
          </td>
        </tr>
        <tr>
          <th className="table-header">Field</th>
          <th className="table-header">Specification</th>
          <th className="table-header-sample">SAMPLE</th>
          <th className="table-header">{workflow.next.type === 'S3 FHIR R4' ? 'TARGET PATH' : 'TARGET KEY'}</th>
        </tr>
      </thead>
      <AnimatePresence>
        <motion.tbody>
          {fieldMapGrid.map((map, idx) => (
            <motion.tr
              key={`${map[1].field}-${map[2].sample}${idx}`}
              className={idx % 2 === 0 ? 'table-row-even' : 'table-row-odd'}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              <td className="table-cell">{map[1].field}</td>
              <td className="table-cell-spec">{map[0].definition}</td>
              <td className="table-cell-sample">
                {map[2].sample.substring(0, 75)}
                {map[2].sample.length > 75 && '...'}
              </td>
              <td className="table-cell-actions">
                {(workflow.trigger.type.substring(0, 4) === 'MLLP' || workflow.trigger.type.substring(0, 4) === 'HTTP') && (
                  <input className="table-input" type="text" name="target" id={map[1].field} defaultValue={map[3].target || null} onChange={updateFieldMap} />
                )}
                <motion.div className="action-button" onClick={() => toggleLookUpModal(map)} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                  <Cog8ToothIcon className="cog-icon" />
                </motion.div>
                {!sampleFilter && (
                  <motion.div className="delete-button" onClick={() => removeFieldMapRow(map)} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                    <TrashIcon className="delete-icon" />
                  </motion.div>
                )}
              </td>
            </motion.tr>
          ))}
        </motion.tbody>
      </AnimatePresence>
    </motion.table>
  );

  const renderLoadingState = () => (
    <motion.div className="loading-container" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.3 }}>
      <div className="loading-content">
        <motion.div className="loading-header" initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }}>
          <FlowHeading title="Workflow Mappings & Transformations" />
          <div className="loading-toggle">
            <span className="text-sm">Selected&nbsp;&nbsp;</span>
            <Toggle enabled={sampleFilter} setEnabled={enableSampleFilter} />
            <span className="text-sm">&nbsp;&nbsp;All</span>
          </div>
        </motion.div>
        <div className="table-divider" />
        <motion.div
          className="loading-animation"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{
            opacity: 1,
            scale: 1,
            rotate: [0, -10, 0],
            transition: {
              type: 'spring',
              stiffness: 300,
              damping: 20,
            },
          }}
        >
          <motion.img
            className="loading-logo"
            src={logo}
            alt="Logo"
            initial={{ rotate: -10 }}
            animate={{ rotate: 0 }}
            transition={{ type: 'spring', stiffness: 300, damping: 20 }}
          />
          <motion.h2 className="loading-title" initial={{ opacity: 0, x: -20 }} animate={{ opacity: 1, x: 0 }} transition={{ delay: 0.3 }}>
            Retrohook
            <span className="loading-version" />
          </motion.h2>
        </motion.div>
      </div>
    </motion.div>
  );

  return (
    <motion.div className="tlayer-container" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.3 }}>
      <AnimatePresence>
        {error === 'SaveInAnotherAccountException' && (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <SaveInAnotherAccountAlert closeAlert={clearError} />
          </motion.div>
        )}
        {error === 'UserPermissionsException' && (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <UserPermissionsAlert closeAlert={clearError} />
          </motion.div>
        )}
      </AnimatePresence>

      {workflow.next !== 'FHIRR4' && (
        <div className="tlayer-content">
          <div className="tlayer-section">
            {workflow.trigger.type.substring(0, 4) === 'MLLP' && renderMainTable()}
            {workflow.next.type.substring(0, 4) === 'MLLP' && renderMainTable()}
          </div>
        </div>
      )}

      {loading && !workflow.fields.length && !fhirLoading && renderLoadingState()}
    </motion.div>
  );
}
