import React from 'react';
import { motion } from 'framer-motion';
import { KeyIcon, TrashIcon, ArrowLeftCircleIcon } from '@heroicons/react/24/outline';
import Terminal, { TypingAnimation } from './Elements/Terminal';

const TunnelCard = function ({ tunnel, onKeyClick, onDelete, TunnelConnect, connectProps }: any) {
  const name = tunnel.name ? tunnel.name.slice(0, 24) : tunnel.Tags[0].Value.slice(0, 24);

  const tunnelAuthProtocol = `${tunnel.ike_auth_protocol} | ${tunnel.ike_encryption_protocol} | ${tunnel.ike_diffie_helm_group}`;
  const isPSK = tunnel.tunnel1_psk ? 'psk' : '';
  const ipSecProtocol = `${isPSK} | ${tunnel.ipsec_auth_protocol}`;

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.3 } },
    hover: {
      scale: 1.02,
      transition: { duration: 0.2 },
    },
  };

  const titleElement = (
    <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="text-sm font-medium text-purple-500">
      {name}
    </motion.span>
  );

  const getTunnelStatus = () => {
    const isAvailable = tunnel.state.toUpperCase() === 'AVAILABLE';
    return {
      storage: isAvailable ? 'green' : 'off',
      network: isAvailable ? 'green' : 'off',
      compute: isAvailable ? 'green' : 'off',
    };
  };

  const normalActions = (
    <>
      <motion.button
        type="button"
        className="text-gray-400 hover:text-purple-500 focus:outline-none"
        onClick={() => onKeyClick(tunnel)}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        <KeyIcon className="h-4 w-4" />
      </motion.button>
      <motion.button
        type="button"
        className="text-gray-400 hover:text-pink-500 focus:outline-none"
        onClick={() => onDelete(tunnel)}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        <TrashIcon className="h-4 w-4" />
      </motion.button>
    </>
  );

  const connectActions = (
    <>
      <motion.button
        type="button"
        className="text-gray-400 hover:text-purple-500 focus:outline-none"
        onClick={() => connectProps.showDownloadTunnelConfigClickHandler(tunnel)}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        <ArrowLeftCircleIcon className="h-4 w-4" />
      </motion.button>
      <motion.button
        type="button"
        className="text-gray-400 hover:text-pink-500 focus:outline-none"
        onClick={() => onDelete(tunnel)}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        <TrashIcon className="h-4 w-4" />
      </motion.button>
    </>
  );

  return (
    <motion.div className="w-full max-w-lg" initial="hidden" animate="visible" whileHover="hover" variants={cardVariants}>
      <Terminal title={titleElement} actions={tunnel.showDownloadTunnelConfig ? connectActions : normalActions} status={getTunnelStatus()}>
        {tunnel.showDownloadTunnelConfig ? (
          <TunnelConnect tunnel={tunnel} {...connectProps} />
        ) : (
          <div className="space-y-1">
            <div className="flex">
              <TypingAnimation duration={100} delay={10} className="text-xs text-cyan-400">
                {`> STATUS: `}
              </TypingAnimation>
              {tunnel.state.toUpperCase() === 'AVAILABLE' ? (
                <TypingAnimation duration={30} delay={200 * 10} className="text-xs text-green-400">
                  {`${tunnel.state.toUpperCase()}`}
                </TypingAnimation>
              ) : (
                <TypingAnimation duration={30} delay={200 * 10} className="text-xs text-red-400">
                  {`${tunnel.state.toUpperCase()}`}
                </TypingAnimation>
              )}
            </div>

            <div className="flex">
              <TypingAnimation duration={30} delay={10 * 170} className="text-gray-400">
                Primary IPv4:&nbsp;
              </TypingAnimation>
              <TypingAnimation duration={30} className="text-cyan-400" delay={10 * 200}>
                {tunnel.tunnel1_ip || ''}
              </TypingAnimation>
            </div>

            <div className="flex">
              <TypingAnimation duration={30} delay={10 * 250} className="text-gray-400">
                Secondary IPv4:&nbsp;
              </TypingAnimation>
              <TypingAnimation duration={30} delay={300 * 10} className="text-cyan-400">
                {tunnel.tunnel2_ip || ''}
              </TypingAnimation>
            </div>

            <div className="flex">
              <TypingAnimation duration={30} delay={350 * 10} className="text-gray-400">
                {`${tunnel.Tags[0].Value} IPv4: `}
              </TypingAnimation>
              <TypingAnimation duration={30} delay={400 * 10} className="text-pink-500">
                {tunnel.customer_ip || ''}
              </TypingAnimation>
            </div>

            {tunnel.Routes && (
              <div className="flex">
                <TypingAnimation duration={30} delay={450 * 10} className="text-gray-400">
                  {`${tunnel.Tags[0].Value} Subnet: `}
                </TypingAnimation>
                <TypingAnimation duration={30} delay={500 * 10} className="text-pink-500">
                  {tunnel.Routes[0].DestinationCidrBlock || ''}
                </TypingAnimation>
              </div>
            )}

            {tunnel.Options && (
              <div className="flex">
                <TypingAnimation duration={30} delay={560 * 10} className="text-gray-400">
                  Retrohook Subnet:&nbsp;
                </TypingAnimation>
                <TypingAnimation duration={30} delay={600 * 10} className="text-gray-500">
                  {tunnel.Options.RemoteIpv4NetworkCidr || ''}
                </TypingAnimation>
              </div>
            )}

            <div className="flex">
              <TypingAnimation duration={30} delay={690 * 10} className="text-gray-400">
                IKE:&nbsp;
              </TypingAnimation>
              <TypingAnimation duration={30} delay={730 * 10} className="text-gray-500">
                {tunnelAuthProtocol || ''}
              </TypingAnimation>
            </div>

            <div className="flex">
              <TypingAnimation duration={30} delay={850 * 10} className="text-gray-400">
                IPSec:&nbsp;
              </TypingAnimation>
              <TypingAnimation duration={30} delay={900 * 10} className="text-gray-500">
                {ipSecProtocol || ''}
              </TypingAnimation>
            </div>

            {tunnel.showPsk && (
              <div className="flex">
                <TypingAnimation duration={50} delay={960 * 10} className="bg-red-900/50 px-2 py-1 rounded text-gray-400">
                  PSK:&nbsp;
                </TypingAnimation>
                <TypingAnimation duration={50} delay={1080 * 10} className="bg-red-900/50 px-2 py-1 rounded text-gray-300">
                  {tunnel.tunnel1_psk || ''}
                </TypingAnimation>
              </div>
            )}
          </div>
        )}
      </Terminal>
    </motion.div>
  );
};

export default TunnelCard;
