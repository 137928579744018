import { CloudArrowUpIcon } from '@heroicons/react/24/outline';
import React from 'react';

function DropdownButton({ label, options }: any) {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div className="dropdown-container">
      <button type="button" onClick={() => setIsOpen(!isOpen)} className="dropdown-button">
        <CloudArrowUpIcon className="dropdown-icon" />
        {label}
        <svg className="dropdown-caret" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      {isOpen && (
        <div className="dropdown-menu">
          <div className="dropdown-menu-content" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            {options.map((option: any, index: number) => (
              <button
                type="button"
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                onClick={() => {
                  option.action();
                  setIsOpen(false);
                }}
                className="dropdown-item"
              >
                {option.label}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default DropdownButton;
