import React from 'react';
import '../../../css/Base.css';

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer-container">
      <div className="footer-content">
        <p className="footer-text">&copy; {currentYear} Retrohook. All rights reserved</p>
      </div>
    </div>
  );
}
