import React, { Fragment, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Dialog, Transition } from '@headlessui/react';
import { ServerStackIcon } from '@heroicons/react/24/outline';
import EnvironmentDropdown from './EnvironmentDropdown';
import '../../../../css/Base.css';

// Type definitions
interface Environment {
  name: string;
  SK?: string;
}

interface EnvironmentSelectModalProps {
  accountPermissions?: any;
  open: boolean;
  closeSelectModal: () => void;
  selectedEnvironment?: Environment;
  setSelectedEnvironmentHandler: (environment: Environment) => void;
  environments?: Environment[];
  selectedWorkspace?: any;
}

export default function EnvironmentSelectModal({
  accountPermissions,
  open,
  closeSelectModal,
  selectedEnvironment,
  setSelectedEnvironmentHandler,
  environments,
  selectedWorkspace,
}: EnvironmentSelectModalProps) {
  // Initialize selected environment
  const [selected, setSelected] = useState<Environment | undefined>(selectedEnvironment || undefined);

  // Update selected environment when props change
  useEffect(() => {
    if (selectedEnvironment) {
      setSelected(selectedEnvironment);
    }
  }, [accountPermissions, selectedEnvironment]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="env-select-modal-container" onClose={closeSelectModal}>
        <div className="env-select-modal-wrapper">
          {/* Overlay */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="env-select-modal-overlay" />
          </Transition.Child>

          {/* Invisible spacer */}
          <span className="env-select-modal-spacer" aria-hidden="true">
            &#8203;
          </span>

          {/* Modal Content */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="env-select-modal-content">
              {/* Modal Header */}
              <motion.div className="env-select-modal-header" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.3 }}>
                <ServerStackIcon className="env-select-modal-icon" />
                <Dialog.Title className="env-select-modal-title">Change Environment</Dialog.Title>
              </motion.div>

              {/* Environment Dropdown */}
              <EnvironmentDropdown
                environments={environments}
                selectedEnvironment={selected}
                setSelectedEnvironmentHandler={setSelectedEnvironmentHandler}
                selectedWorkspace={selectedWorkspace}
              />

              {/* Close Button */}
              <div className="mt-4">
                <motion.button
                  type="button"
                  className="env-select-modal-close-button"
                  onClick={closeSelectModal}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Close
                </motion.button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
