/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { IconPickerItem } from 'react-heroicons-picker';
import { ArrowUpCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';
import '../../css/Workflows.css';

// Workflow Card Component
const WorkflowCard: React.FC<{
  flow: any;
  onEdit: () => void;
  onDelete: () => void;
  onViewEvents: () => void;
}> = function ({ flow, onEdit, onDelete, onViewEvents }) {
  return (
    <div className="workflow-card">
      <div className="mb-2">
        <dt>
          <div className="workflow-card-icon-wrapper">
            <div className="workflow-card-icon">
              <IconPickerItem icon={flow.icon || 'BoltIcon'} library="outline" />
            </div>
          </div>
          <div className="workflow-card-title-container">
            <p onClick={() => onEdit(flow)} className="workflow-card-title">
              {flow.flowName}
            </p>
          </div>
          <button className="workflow-card-delete-btn" onClick={() => onDelete(flow)}>
            X
          </button>
        </dt>

        <p className="workflow-card-trigger">
          {flow.trigger.type.toUpperCase()} →&nbsp;
          {flow.next.type}
        </p>
      </div>
      <div className="w-full bottom-0 inset-x-0 p-2 text-center">
        <div className="text-sm dark:text-gray-200 text-center w-full cursor-pointer" onClick={() => onViewEvents(flow)}>
          <div className="workflow-card-stats-container">
            <p className="workflow-card-success-count">{flow.successes}</p>
            <p className="ml-0 flex text-lg pr-2 border-r-gray-700 my-2 border-r-2">
              <ArrowUpCircleIcon className="workflow-card-success-icon" aria-hidden="true" />
            </p>
            <p className="workflow-card-failure-count">
              <span className="workflow-card-failure-count-number">{flow.failures}</span>
            </p>
            <p className="ml-0 flex text-sm align-base">
              <ExclamationCircleIcon className="workflow-card-failure-icon" aria-hidden="true" />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkflowCard;
