import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ArrowLeftCircleIcon, ArrowPathIcon, ServerIcon } from '@heroicons/react/24/outline';
import InputBox from '../components/Base/Elements/Input/InputBox';
import TunnelConnect from '../components/Tunnels/TunnelConnect';
import ProductionRequiredAlert from '../components/Base/Elements/Alerts/ProductionRequiredAlert';
import ConfirmModal from '../components/Base/Elements/Confirmations/ConfirmModal';
import MissingFieldsAlert from '../components/Base/Elements/Alerts/MissingFieldsAlert';
import ActionButton from '../components/Base/Layout/NavigationElements/ActionButton';
import TunnelCard from '../components/Tunnels/TunnelCard';
import logo from '../../images/retrohook.png';
import useTunnels from '../hooks/useTunnels';
import '../css/Tunnels.css';

import { TunnelsProps } from '../types';

export default function Tunnels(props: TunnelsProps) {
  const {
    tunnels,
    showAccessAlert,
    tunnel,
    missingFieldsAlert,
    missingFields,
    currentTunnel,
    isModalOpen,
    loading,
    setShowAccessAlert,
    setMissingFieldsAlert,
    getTunnels,
    canCreate,
    saveTunnel,
    handleIPChange,
    handleTunnelNameChange,
    handleInternalIPChange,
    showDownloadTunnelConfigClickHandler,
    openModal,
    closeModal,
    confirmDelete,
    createNewModal,
    setCreateNewModal,
  } = useTunnels(props);

  const { selectedEnvironment } = props;
  const { selectedWorkspace } = props;

  const isDev = props?.selectedEnvironment?.name?.substring(1, 3) === 'Dev';

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
    hover: {
      scale: 1.05,
      transition: { duration: 0.2 },
    },
  };

  const renderNewTunnelForm = () => (
    <motion.div className="new-tunnel-container w-full" initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
      <div className="new-tunnel-form">
        <div className="form-section">
          <InputBox name="Name" onChange={handleTunnelNameChange} defaultValue={tunnel.deviceName} placeholder={tunnel.deviceNamePlaceholder} />
        </div>
        <div>
          <div className="form-section">
            <InputBox
              name="Public IPv4 Address"
              onChange={handleIPChange}
              defaultValue={tunnel.PublicIpv4Address}
              placeholder={tunnel.PublicIpv4AddressPlaceholder}
            />
          </div>
          <div className="form-section">
            <InputBox
              name="Private IPV4 Address"
              onChange={handleInternalIPChange}
              defaultValue={tunnel.LocalIpv4Address}
              placeholder={tunnel.LocalIpv4AddressPlaceholder}
            />
          </div>
        </div>
      </div>
      <div className="form-button-container">
        <motion.button
          className={canCreate() ? 'create-button' : 'disabled-button'}
          type="button"
          onClick={canCreate() ? saveTunnel : () => setShowAccessAlert(true)}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          {loading ? 'Generating...' : 'Generate'}
        </motion.button>
      </div>
    </motion.div>
  );

  const { user, account, environments, selectedAccount, logout } = props;

  const connectProps = {
    user,
    account,
    environments,
    selectedEnvironment,
    selectedAccount,
    showDownloadTunnelConfigClickHandler,
    logout,
  };

  return (
    <div className="w-full h-full min-h-screen">
      <AnimatePresence>
        {showAccessAlert && (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <ProductionRequiredAlert closeProdRequiredAlert={() => setShowAccessAlert(false)} />
          </motion.div>
        )}

        {missingFieldsAlert && missingFields.length > 0 && (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <MissingFieldsAlert closeMissingFieldsAlert={() => setMissingFieldsAlert(false)} fields={missingFields} />
          </motion.div>
        )}
      </AnimatePresence>

      <ConfirmModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onConfirm={confirmDelete}
        title="Confirm Delete Tunnel"
        message={`Are you sure you want to delete ${currentTunnel.name}? This cannot be undone.`}
      />

      <div className="tunnels-container w-full h-full">
        <aside className="sidebar">
          <motion.div className="sidebar-content" initial={{ opacity: 0, x: -50 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 0.5 }}>
            {!loading && !isDev && (
              <div className="sidebar-buttons">
                {!createNewModal ? (
                  <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
                    <ActionButton title="New" icon={ServerIcon} clickHandler={() => setCreateNewModal(true)} />
                  </motion.div>
                ) : (
                  <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
                    <ActionButton title="Close" icon={ArrowLeftCircleIcon} clickHandler={() => setCreateNewModal(false)} />
                  </motion.div>
                )}
                {!createNewModal && (
                  <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
                    <ActionButton title="Refresh" icon={ArrowPathIcon} clickHandler={getTunnels} />
                  </motion.div>
                )}
              </div>
            )}
          </motion.div>
        </aside>

        <div className="w-full h-full">
          <AnimatePresence>
            {loading && (
              <motion.div
                className="loading-container"
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ type: 'spring', stiffness: 300, damping: 20 }}
              >
                <motion.img
                  className="loading-logo"
                  src={logo}
                  alt="Logo"
                  initial={{ rotate: -10 }}
                  animate={{ rotate: 0 }}
                  transition={{ type: 'spring', stiffness: 300, damping: 20 }}
                />
                <motion.h2
                  className="loading-title"
                  style={{ fontFamily: '"Gugi", sans-serif' }}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.2 }}
                >
                  Retrohook
                  <span className="loading-version" />
                </motion.h2>
              </motion.div>
            )}
          </AnimatePresence>

          {!selectedEnvironment && (
            <motion.div className="w-full px-4 h-full" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
              <div className="p-12 text-center">
                <h1 className="text-3xl font-bold text-gray-700 dark:text-gray-100">No Environment Selected</h1>
                <p className="mt-2 text-lg text-gray-600 dark:text-gray-300">Please select a production enabled environment.</p>
              </div>
            </motion.div>
          )}

          {!loading && createNewModal && selectedWorkspace.PK && renderNewTunnelForm()}

          {!loading && !createNewModal && (
            <motion.dl className="tunnels-grid w-full h-full" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
              {tunnels.length > 0 &&
                tunnels.map((tunnel) => (
                  <motion.div key={tunnel.vpnConnectionId} whileHover={{ scale: 1.01 }} transition={{ duration: 0.2 }}>
                    <TunnelCard
                      tunnel={tunnel}
                      onKeyClick={showDownloadTunnelConfigClickHandler}
                      onDelete={openModal}
                      TunnelConnect={TunnelConnect}
                      connectProps={connectProps}
                    />
                  </motion.div>
                ))}
            </motion.dl>
          )}
        </div>
      </div>
    </div>
  );
}
