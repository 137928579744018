import React, { Switch } from '@headlessui/react';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

export default function Toggle(props: any) {
  let enabled = null;
  enabled = props.enabled;
  return (
    <Switch
      checked={enabled}
      onChange={() => {
        props.onChange();
      }}
      className={classNames(
        enabled ? 'bg-cyan-500' : 'bg-gray-200',
        'relative inline-flex flex-shrink-0 h-3 w-7 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500',
      )}
    >
      <span className="sr-only">{props.name}</span>
      <span
        aria-hidden="true"
        className={classNames(
          enabled ? 'translate-x-4' : 'translate-x-0',
          'pointer-events-none inline-block h-2 w-2 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
        )}
      />
    </Switch>
  );
}
