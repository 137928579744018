/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { UserCircleIcon, EnvelopeIcon, XCircleIcon } from '@heroicons/react/24/outline';
import InputBox from '../Base/Elements/Input/InputBox';
import { User, Invite, Workspace } from '../../types';
import '../../css/Workspace.css';

// Invitations Section
interface InvitationsSectionProps {
  workspace: Workspace;
  invite: Invite;
  loading: boolean;
  loadingType: string;
  handleNewUserFirstNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleNewUserLastNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleNewUserEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  removeInviteFromWorkspace: (invite: Invite) => void;
  addUserToWorkspace: (userSub: string) => void;
  setLoadingType: (type: string) => void;
  user: User;
}

// eslint-disable-next-line import/prefer-default-export
export const InvitationsSection: React.FC<InvitationsSectionProps> = function ({
  workspace,
  invite,
  loading,
  loadingType,
  handleNewUserFirstNameChange,
  handleNewUserLastNameChange,
  handleNewUserEmailChange,
  removeInviteFromWorkspace,
  addUserToWorkspace,
  setLoadingType,
  user,
}) {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3,
      },
    },
    hover: {
      scale: 1.05,
      transition: { duration: 0.2 },
    },
  };

  return (
    <div className="flex flex-col h-full">
      <div className="bg-gray-300 dark:bg-gray-700 h-full mb-2 border-b-2 border-b-purple-400">
        <motion.div className="grid grid-cols-4 gap-2 mx-2 py-4 h-full" variants={containerVariants} initial="hidden" animate="visible">
          {(!workspace?.invites || workspace.invites.length < 1) && (
            <motion.div className="text-gray-400 col-span-4 h-full" variants={itemVariants}>
              <div className="text-2xl w-full items-center text-center align-middle h-full placer-items-center">
                <EnvelopeIcon className="h-9 w-9 rounded mr-1 inline align-middle text-gray-400" />
                No Pending Invitations
              </div>
            </motion.div>
          )}
          <AnimatePresence>
            {workspace?.invites?.map((invite: Invite, index: number) => (
              <motion.div
                key={invite.email + index}
                variants={itemVariants}
                whileHover="hover"
                initial="hidden"
                animate="visible"
                exit="hidden"
                className="flex flex-col text-md rounded items-center p-2 border-2 h-24 border-pink-400 text-gray-600 dark:text-gray-200 relative"
              >
                <motion.button
                  type="button"
                  className="absolute top-1 right-1 text-2xs dark:hover:text-cyan-400 hover:text-pink-500 text-gray-600 focus:outline-none focus:shadow-outline"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeInviteFromWorkspace(invite);
                  }}
                  whileHover={{ scale: 1.2 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <XCircleIcon className="h-6 w-6" />
                </motion.button>
                <div className="flex flex-col items-center gap-x-2">
                  <UserCircleIcon className="h-14 w-14 rounded text-gray-600 dark:text-gray-200" />
                  <div className="text-md text-center">
                    {invite.email.substring(0, 36) || ''}
                    {invite.email.length > 36 && '...'}
                  </div>
                </div>
              </motion.div>
            ))}
          </AnimatePresence>
        </motion.div>
      </div>
      <table className="w-full py-2 px-4">
        <tbody>
          <tr>
            <td className="px-2 py-4 whitespace-nowrap text-md text-gray-200">
              <InputBox name="First Name" onChange={handleNewUserFirstNameChange} defaultValue={invite.firstName} />
            </td>
            <td className="px-2 py-4 whitespace-nowrap text-md text-gray-200">
              <InputBox name="Last Name" onChange={handleNewUserLastNameChange} defaultValue={invite.lastName} />
            </td>
            <td className="px-2 py-4 whitespace-nowrap text-md text-gray-200">
              <InputBox name="Email" onChange={handleNewUserEmailChange} defaultValue={invite.email} />
            </td>
            {!loading && (
              <td className="px-2 py-3 whitespace-nowrap text-md text-gray-200">
                <motion.button
                  type="button"
                  className="w-full border-2 border-purple-400 bg-purple-500 hover:bg-purple-400 py-2 px-1 text-white rounded focus:outline-none focus:shadow-outline"
                  onClick={() => {
                    addUserToWorkspace(user.sub);
                    setLoadingType('invite');
                  }}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Send Invite
                </motion.button>
              </td>
            )}
            {loading && loadingType === 'invite' && (
              <td className="px-2 py-3 whitespace-nowrap text-md text-gray-200">
                <motion.button
                  type="button"
                  className="w-full bg-gray-500 hover:bg-gray-400 py-2 px-1 text-white rounded focus:outline-none focus:shadow-outline"
                  animate={{
                    y: [0, -10, 0],
                    transition: {
                      repeat: Infinity,
                      duration: 0.5,
                    },
                  }}
                >
                  Sending...
                </motion.button>
              </td>
            )}
          </tr>
        </tbody>
      </table>
    </div>
  );
};
