/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React from 'react';
import { UserGroupIcon, AdjustmentsVerticalIcon, ServerStackIcon, UserPlusIcon } from '@heroicons/react/24/outline';
import { SettingsSection, PermissionsSection, InvitationsSection } from '../components/Workspace';

import InnerNavButton from '../components/Base/Layout/NavigationElements/InnerNavButton';
import InnerNavButtonSelected from '../components/Base/Layout/NavigationElements/InnerNavButtonSelected';
import DuplicateUserAlert from '../components/Base/Elements/Alerts/DuplicateUserAlert';
import AccountUserLimitAlert from '../components/Base/Elements/Alerts/AccountUserLimitAlert';
import PasswordNotComplexAlert from '../components/Base/Elements/Alerts/PasswordNotComplexAlert';
import EmailFormatAlert from '../components/Base/Elements/Alerts/EmailFormatAlert';
import PricingBox from '../components/Product/PricingBox';
import Environments from '../components/Environments/Environments';
import logo from '../../images/retrohook.png';
import { useWorkspace } from '../hooks/useWorkspace';

const Workspace = function (props: any) {
  const {
    subUx,
    setSubUx,
    error,
    setError,
    selectedProduct,
    togglePermissions,
    selectedUser,
    invite,
    billingUX,
    loadingType,
    setLoadingType,
    workspace,
    quantityPurchased,
    setQuantityPurchased,
    selectProduct,
    getCustomerPortal,
    handleNewUserFirstNameChange,
    handleNewUserLastNameChange,
    handleNewUserEmailChange,
    handleMessageIDChange,
    handleOrganizationValueChange,
    openPermissions,
    addUserToWorkspace,
    removeUserFromWorkspace,
    updatePermissions,
    savePermissions,
    saveWorkspace,
    loading,
    awsProductURL,
    removeInviteFromWorkspace,
  } = useWorkspace(props);

  const {
    selectedWorkspace,
    environments,
    getAccount,
    account,
    user,
    setAccount,
    setSelectedWorkspace,
    setupEnvironmentsHandler,
    notify,
    environmentChanged,
    setEnvironmentChanged,
    setEnvironments,
  } = props;

  const members = workspace?.accounts?.filter((acc: any) => acc.PK != workspace.PK);

  if (loading || !workspace) {
    return (
      <div className="items-center animate-pulse py-48 sm:py-24 md:py-32 lg:py-48">
        <img className="h-24 mx-auto w-auto" src={logo} alt="Logo" />
        <h2 className="mt-0 text-center text-2xl font-bold text-gray-200" style={{ fontFamily: '"Gugi", sans-serif' }}>
          Retrohook
          <span className="inline align-middle text-xs tracking-tight text-cyan-300 pl-1" />
        </h2>
      </div>
    );
  }
  const renderAlert = () => {
    const alerts = {
      UsernameExistsException: <DuplicateUserAlert closeAlert={() => setError(null)} />,
      PasswordNotComplexException: <PasswordNotComplexAlert closeAlert={() => setError(null)} />,
      AccountUserLimitAlert: <AccountUserLimitAlert closeAlert={() => setError(null)} />,
      EmailFormatException: <EmailFormatAlert closeAlert={() => setError(null)} />,
    };
    return error ? alerts[error as keyof typeof alerts] : null;
  };

  const renderSection = () => {
    switch (subUx) {
      case 'Settings':
        return (
          <SettingsSection
            workspace={workspace}
            handleOrganizationValueChange={handleOrganizationValueChange}
            handleMessageIDChange={handleMessageIDChange}
            saveWorkspace={saveWorkspace}
          />
        );
      case 'Permissions':
        return (
          <PermissionsSection
            members={members}
            togglePermissions={togglePermissions}
            selectedUser={selectedUser}
            openPermissions={openPermissions}
            removeUserFromWorkspace={removeUserFromWorkspace}
            setLoadingType={setLoadingType}
            updatePermissions={updatePermissions}
            savePermissions={savePermissions}
          />
        );
      case 'Invitations':
        return (
          <InvitationsSection
            workspace={workspace}
            invite={invite}
            loading={loading}
            loadingType={loadingType}
            handleNewUserFirstNameChange={handleNewUserFirstNameChange}
            handleNewUserLastNameChange={handleNewUserLastNameChange}
            handleNewUserEmailChange={handleNewUserEmailChange}
            removeInviteFromWorkspace={removeInviteFromWorkspace}
            addUserToWorkspace={addUserToWorkspace}
            setLoadingType={setLoadingType}
            user={user}
          />
        );
      case 'Environments':
        return (
          <Environments
            user={user}
            account={account}
            setAccount={setAccount}
            environments={environments}
            selectedWorkspace={selectedWorkspace}
            setSelectedWorkspace={setSelectedWorkspace}
            setupEnvironmentsHandler={setupEnvironmentsHandler}
            notify={notify}
            setEnvironments={setEnvironments}
            environmentChanged={environmentChanged}
            setEnvironmentChanged={setEnvironmentChanged}
            getAccount={getAccount}
          />
        );
      case 'Subscription':
        return (
          <PricingBox
            account={account}
            selectProduct={selectProduct}
            getAccount={getAccount}
            awsProductURL={awsProductURL}
            quantityPurchased={quantityPurchased}
            setQuantityPurchased={setQuantityPurchased}
            selectedProduct={selectedProduct}
            getCustomerPortal={getCustomerPortal}
            selectedWorkspace={selectedWorkspace}
            ux={billingUX}
          />
        );
      default:
        return null;
    }
  };

  const navItems = [
    { key: 'Settings', icon: AdjustmentsVerticalIcon, title: 'General' },
    { key: 'Permissions', icon: UserGroupIcon, title: 'Permissions' },
    { key: 'Invitations', icon: UserPlusIcon, title: 'Invitations' },
    { key: 'Environments', icon: ServerStackIcon, title: 'Environments' },
  ];

  return (
    <div className="flex h-full">
      {renderAlert()}

      <aside className="flex-shrink-0 h-full bg-gray-700/70">
        <div className="bg-gray-700/70 border-t-purple-500 border-b-cyan-500 dark:bg-cyan-400 min-w-32 max-w-36 border-r-2 border-r-purple-600 h-full">
          <div className="w-full justify-center h-full py-2">
            {navItems.map(({ key, icon, title }) =>
              subUx === key ? (
                <InnerNavButtonSelected key={key} icon={icon} title={title} clickHandler={() => setSubUx(key)} />
              ) : (
                <InnerNavButton key={key} icon={icon} title={title} clickHandler={() => setSubUx(key)} />
              ),
            )}
          </div>
        </div>
      </aside>

      <dl className="w-full h-full bg-gray-200 dark:bg-gray-700">
        <div className="dark:bg-gray-800 inline overflow-y-scroll">{renderSection()}</div>
      </dl>
    </div>
  );
};

export default Workspace;
