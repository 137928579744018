/* eslint-disable no-param-reassign */
import { useState, useEffect } from 'react';
import moment from 'moment';
import useFetch from 'use-http';

interface WorkflowAnalyticsProps {
  selectedWorkspace: any;
  selectedEnvironment: any;
  userToken: string;
}

interface WorkflowEvent {
  timestamp: string;
  type: string;
}

interface GridData {
  x: string;
  y: number;
}

interface Workflow {
  events: WorkflowEvent[];
  eventsByDay: Record<string, WorkflowEvent[]>;
  gridSuccesses: {
    data: GridData[];
  };
  gridErrors: {
    data: GridData[];
  };
}

// eslint-disable-next-line import/prefer-default-export
export const useDashboard = ({ selectedWorkspace, selectedEnvironment, userToken }: WorkflowAnalyticsProps) => {
  const [workflows, setWorkflows] = useState<Workflow[]>([]);
  const [shouldRender, setShouldRender] = useState(false);
  const { post, response, loading } = useFetch();

  const removeDuplicates = (array: GridData[], key: keyof GridData) => {
    return array.reduce((arr: GridData[], item: GridData) => {
      const removed = arr.filter((i) => i[key] !== item[key]);
      return [...removed, item];
    }, []);
  };

  const processWorkflowEvents = (workflow: Workflow) => {
    // Format timestamps and group events by day
    workflow.events = workflow.events
      ? workflow.events.map((event) => ({
          ...event,
          timestamp: moment(event.timestamp).format('MM/DD/YY'),
        }))
      : [];

    workflow.eventsByDay =
      workflow.events.length > 0
        ? workflow.events.reduce((r: Record<string, WorkflowEvent[]>, a: WorkflowEvent) => {
            r[a.timestamp] = r[a.timestamp] || [];
            r[a.timestamp].push(a);
            return r;
          }, Object.create(null))
        : {};

    // Initialize grid data arrays
    workflow.gridSuccesses = { data: [] };
    workflow.gridErrors = { data: [] };

    // Process events by day
    Object.entries(workflow.eventsByDay).forEach(([date, events]) => {
      const successes = events.filter((event) => event.type === 'L-202');
      const tErrors = events.filter((event) => event.type === 'T-111');
      const errors = events.filter((event) => event.type === 'L-222');
      const dailyErrors = errors.length + tErrors.length;

      if (successes.length) {
        workflow.gridSuccesses.data.push({ x: date, y: successes.length });
      }
      if (dailyErrors) {
        workflow.gridErrors.data.push({ x: date, y: dailyErrors });
      }
    });

    // Remove duplicates and sort data
    workflow.gridSuccesses.data = removeDuplicates(workflow.gridSuccesses.data, 'x');
    workflow.gridErrors.data = removeDuplicates(workflow.gridErrors.data, 'x');

    const sortByDate = (a: GridData, b: GridData) => (moment(a.x).format('YYYYMMDD') > moment(b.x).format('YYYYMMDD') ? -1 : 1);

    workflow.gridSuccesses.data.sort(sortByDate);
    workflow.gridErrors.data.sort(sortByDate);

    return workflow;
  };

  const getEnvironmentAnalytics = async () => {
    const body = { selectedWorkspace, selectedEnvironment };
    const workflowRequest = await post(`workflow/list-workflows/${userToken}`, body);

    if (response.ok && workflowRequest) {
      const fetchedWorkflows = workflowRequest?.workflows || [];

      if (fetchedWorkflows.length) {
        const processedWorkflows = fetchedWorkflows.map(processWorkflowEvents);
        setShouldRender(!!processedWorkflows[0]?.eventsByDay);
        setWorkflows(processedWorkflows);
      } else {
        setWorkflows([]);
        setShouldRender(false);
      }
    } else {
      setWorkflows([]);
      setShouldRender(false);
    }
  };

  useEffect(() => {
    if (selectedWorkspace?.SK && selectedEnvironment?.SK) {
      getEnvironmentAnalytics();
    }
  }, [selectedEnvironment, selectedWorkspace]);

  return {
    workflows,
    shouldRender,
    loading,
    getEnvironmentAnalytics,
  };
};
