import React from 'react';
import { motion } from 'framer-motion';
import screenshot from '../../../images/screenshot.png';
import screenshotLight from '../../../images/screenshot-light.png';

// Type definition for component props
interface RetrohookOverviewProps {
  effectiveTheme: 'light' | 'dark';
  matrix: boolean;
  matrixCSS: string;
}

export default function RetrohookOverview(props: RetrohookOverviewProps) {
  const { effectiveTheme, matrix, matrixCSS } = props;

  // Animation variants for text and image
  const textVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.6,
        ease: 'easeOut',
      },
    },
  };

  const imageVariants = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.6,
        ease: 'easeOut',
        delay: 0.3,
      },
    },
  };

  return (
    <motion.div initial="hidden" animate="visible" className="overview-container">
      <motion.div variants={textVariants} className={`${matrix ? matrixCSS : ''} overview-text-wrapper`}>
        <motion.h2 className="overview-subtitle" style={{ fontFamily: '"Gugi", sans-serif' }}>
          ⚡️ Harness Real Time EHR Event Automation ⚡️
        </motion.h2>

        <motion.p style={{ fontFamily: '"Gugi", sans-serif' }} className="overview-main-title">
          🔥 Reignite HL7v2.x With Clicks, Not Code.
        </motion.p>

        <motion.p className="overview-description" variants={textVariants}>
          Quickly and securely connect EHR data to modern technologies you ❤️.
        </motion.p>
      </motion.div>

      <motion.div className="overview-screenshot-container" variants={imageVariants}>
        <motion.img
          className="overview-screenshot"
          src={effectiveTheme === 'dark' ? screenshot : screenshotLight}
          alt="A view of Retrohook"
          whileHover={{
            scale: 1.05,
            transition: { duration: 0.3 },
          }}
        />
      </motion.div>
    </motion.div>
  );
}
