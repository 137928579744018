import React from 'react';
import { motion } from 'framer-motion';
import logo from '../../../images/retrohook.png';

interface AuthenticatedLoaderProps {
  handleNavClick: () => void;
}

export default function AuthenticatedLoader({ handleNavClick }: AuthenticatedLoaderProps) {
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    handleNavClick(e, '#top', 'Home');
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const logoVariants = {
    hidden: {
      opacity: 0,
      scale: 0.8,
      rotate: -10,
    },
    visible: {
      opacity: 1,
      scale: 1,
      rotate: 0,
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 20,
      },
    },
    hover: {
      scale: 1.1,
      rotate: 5,
      transition: { duration: 0.2 },
    },
  };

  const titleVariants = {
    hidden: {
      opacity: 0,
      x: -50,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 20,
      },
    },
    hover: {
      scale: 1.05,
      transition: { duration: 0.2 },
    },
  };

  return (
    <motion.div className="auth-loader-container" initial="hidden" animate="visible" variants={containerVariants}>
      <motion.div
        className="auth-loader-content"
        onClick={handleClick}
        role="button"
        tabIndex={0}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            handleClick(e);
          }
        }}
        whileHover={{
          scale: 1.05,
          transition: { duration: 0.2 },
        }}
        whileTap={{ scale: 0.95 }}
      >
        <motion.img className="auth-loader-logo" src={logo} alt="Retrohook Logo" variants={logoVariants} whileHover="hover" />
        <motion.h2 className="auth-loader-title" variants={titleVariants} whileHover="hover">
          Retrohook
          <span className="auth-loader-version" />
        </motion.h2>
      </motion.div>
    </motion.div>
  );
}
