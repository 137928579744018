import { ChartBarIcon, LockClosedIcon, CodeBracketSquareIcon } from '@heroicons/react/24/outline';

import hospital from './images/hospital.png';
import happy from './images/happy.png';
import teams from './images/teams.png';
import serverRoom from './images/secure-tunnel.png';

export default {
  url: process.env.API_URL,
  wssUrl: process.env.WSS_URL,
  client_url: process.env.CLIENT_URL,
  stripe_api_key: process.env.STRIPE_API_KEY,
  ENV: process.env.ENV ? process.env.ENV : 'development',
  domain: process.env.AUTH0_DOMAIN ? process.env.AUTH0_DOMAIN : 'retrohook.us.auth0.com',
  clientId: process.env.AUTH0_CLIENT_ID ? process.env.AUTH0_CLIENT_ID : 'DIpZEFp4TLTQjfxdCLHOgnZlstTKFogd',
  scope: process.env.AUTH0_SCOPE
    ? process.env.AUTH0_SCOPE
    : 'read:current_user update:current_user_metadata read:current_user_metadata openid profile email w_member_social read:user user:follow read:org',
  nav: [
    { name: 'Dashboard', href: '/', current: false, icon: ChartBarIcon },
    { name: 'Workflows', href: '/workflows', current: false, icon: CodeBracketSquareIcon },
    { name: 'Tunnels', href: '/tunnels', current: false, icon: LockClosedIcon },
  ],
  productNav: [
    { name: 'Home', href: '#top', current: true },
    { name: 'Features', href: '#features' },
    { name: 'Pricing', href: '#pricing' },
    { name: 'Docs', href: 'https://docs.retrohook.com/' },
    { name: 'Contact', href: '#contact' },
  ],
  mainFeatures: [
    {
      id: 1,
      title: 'No-Code Integrations',
      href: 'https://docs.retrohook.com/docs/workflows',
      category: { name: 'Built for Everyone', href: 'https://docs.retrohook.com/docs/workflows' },
      imageUrl: hospital,
      preview: "Retrohook's workflow engine allow you to route and map legacy hl7v2 data into useful HTTPS requests (webhooks), or AWS services.",
    },
    {
      id: 2,
      title: 'Simple Secure Tunnels',
      href: 'https://docs.retrohook.com/docs/tunnels',
      category: { name: 'IPSec IKEv2 VPN', href: 'https://docs.retrohook.com/docs/tunnels' },
      imageUrl: serverRoom,
      preview:
        'Retrohook ensures secure interfaces by supporting both old and new security tech. Easily create safe MLLP tunnels with its user-friendly UI and connection agent.',
    },
    {
      id: 3,
      title: 'Private AWS Deployments',
      href: 'https://docs.retrohook.com/docs/workspaces',
      category: { name: 'Leverage Your AWS', href: 'https://docs.retrohook.com/docs/workspaces' },
      imageUrl: happy,
      preview: 'Deploy a Retrohook instance into your own AWS environment for enhanced compliance and security controls.',
    },
    {
      id: 4,
      title: 'Team Mode',
      href: 'https://docs.retrohook.com/docs/intro#workspaces--environments',
      category: { name: 'Shared Workspaces', href: 'https://docs.retrohook.com/docs/intro#workspaces--environments' },
      imageUrl: teams,
      preview: 'Share your workspace and collaborate with others. Team mode also provides granular controls for managing access.',
    },
  ],
  installationPrice: 2000,
  recurringPrice: 2000,
  developer: ['No-code workflow builder', 'Native EHR Event Automation', 'Shared environment access', 'Team mode collaboration', 'Access to documentation'],
  core: ['Everything in Developer', 'Pro features (S3, FHIR, SFTP, Tunnels)', '1 No-code AWS private environment', 'Two 1 hour training sessions'],
  updatesFeatures: ['Feature updates and improvements', 'Maintenance and security patches', '48 Hour Response Support', 'Access to community forums'],
};
