import React from 'react';
import '../../../../css/Base.css';

// Type definition for props
interface InnerNavButtonProps {
  icon?: React.ComponentType<{
    className?: string;
    'aria-hidden'?: boolean;
  }>;
  title: string;
  clickHandler: () => void;
}

export default function InnerNavButton({ icon: Icon, title, clickHandler }: InnerNavButtonProps) {
  return (
    <div className="inner-nav-button-container">
      <button type="button" onClick={clickHandler} className="inner-nav-button">
        {Icon && <Icon className="inner-nav-button-icon" aria-hidden="true" />}
        {title}
      </button>
    </div>
  );
}
