/* eslint-disable react/react-in-jsx-scope */
export default function WorkflowNameInputBox({ name, disabled, onChange, value }: any) {
  return (
    <div className="relative border-2 flex border-cyan-300 rounded px-2 py-1.5 focus-within:ring-cyan-600 focus-within:border-pink-600">
      <input
        type="text"
        name={name}
        disabled={disabled}
        id={name}
        onChange={onChange}
        defaultValue={value}
        placeholder="Workflow Name"
        className="block w-full border-0 p-0 text-gray-600 dark:text-cyan-200 placeholder-gray-400 bg-transparent dark:bg-gray-800 focus:ring-0 sm:text-sm"
      />
    </div>
  );
}
