import React, { ReactNode } from 'react';

// Adapter Types
export interface AdapterType {
  id: number;
  type: string;
  label: string;
  description: string;
  disabled: boolean;
}

export interface Workflow {
  PK?: string;
  icon?: string;
  flowName?: string;
  trigger: {
    type: string;
    host?: string;
    port?: string;
    path?: string;
    username?: string;
    cadence?: string;
    key?: string;
    cert?: string;
  };
  next: any;
  sampleMessage?: string;
}

export interface AdaptersProps {
  error?: string;
  selectedEnvironment: {
    name: string;
  };
  workflow: Workflow;
  placeholder: {
    flowName: string;
  };
  clearError: () => void;
  handleFlowNameChange: () => void;
  toggleActive: () => void;
  updateEventTrigger: (event: AdapterType) => void;
  updateActionTrigger: (event: AdapterType) => void;
  handleSampleMessageChange: () => void;
}

// Time Types
export interface TimeOption {
  id: number;
  label: string;
  value: number;
}

export interface TimeDropdownProps {
  selected: TimeOption | null;
  selectHandler: (option: TimeOption) => void;
}

// Tunnel Types
export interface Tunnel {
  PK: string;
  SK: string;
  name: string;
}

export interface TunnelDropdownProps {
  tunnels: Tunnel[];
  selected: Tunnel | null;
  selectHandler: (tunnel: Tunnel) => void;
}

// SFTP Config Types
export interface SFTPConfigModalProps {
  isOpen: boolean;
  workflow: {
    trigger: {
      host?: string;
      port?: string;
      path?: string;
      username?: string;
      cadence?: string;
      key?: string;
      cert?: string;
    };
  };
  closeModal: () => void;
  onChangeUsername: () => void;
  onChangePort: () => void;
  onChangePath: () => void;
  onChangeHost: () => void;
  onChangeCadence: (value: string) => void;
  onChangeCert: () => void;
  onChangeKey: () => void;
}

// URL Input Types
export interface URLInputBoxProps {
  leftInput?: ReactNode;
  url: string;
  value: string;
  onChange: () => void;
}

// Workflow Name Input Types
export interface WorkflowNameInputBoxProps {
  name: string;
  disabled?: boolean;
  value?: string;
  placeholder?: string;
  onChange: () => void;
}

// Default Event Types
export const defaultEventTypes: AdapterType[] = [
  {
    id: 1,
    type: 'MLLP HL7v2',
    label: 'HL7v2 Message Event (TCP/MLLP)',
    description: 'HL7v2 Messages over MLLP',
    disabled: false,
  },
  {
    id: 2,
    type: 'HTTPS JSON',
    label: 'JSON Request (HTTPS)',
    description: 'Consume JSON over HTTP POST requests',
    disabled: false,
  },
  {
    id: 3,
    type: 'HTTPS FHIR R4',
    label: 'FHIR R4 Bundle Request (HTTPS)',
    description: 'Consume FHIR Over HTTP Post requests',
    disabled: true,
  },
  {
    id: 4,
    type: 'HTTPS HL7v2',
    label: 'HL7v2 Message Request (HTTPS)',
    description: 'Consume HL7v2 over HTTP POST requests',
    disabled: true,
  },
  {
    id: 5,
    type: 'HTTPS C-CDA',
    label: 'C-CDA XML Request (HTTPS)',
    description: 'Consume C-CDA From HTTP',
    disabled: false,
  },
  {
    id: 6,
    type: 'SFTP HL7v2',
    label: 'HL7v2 Message Polling (SFTP)',
    description: 'HL7v2 Messages over SFTP',
    disabled: true,
  },
  {
    id: 7,
    type: 'SOAP C-CDA',
    label: 'C-CDA XML Request (SOAP)',
    description: 'Consume C-CDA From SOAP',
    disabled: true,
  },
];

// Default Action Types
export const defaultActionTypes: AdapterType[] = [
  {
    id: 1,
    type: 'HTTPS JSON',
    label: 'JSON POST Request (HTTPS)',
    description: 'POST JSON Objects over HTTPS',
    disabled: false,
  },
  {
    id: 2,
    type: 'S3 JSON',
    label: 'JSON Write (S3)',
    description: 'Write JSON Objects to AWS S3',
    disabled: false,
  },
  {
    id: 3,
    type: 'S3 FHIR R4',
    label: 'FHIR R4 Bundle Write (S3)',
    description: 'Write FHIR Bundles to AWS S3',
    disabled: false,
  },
  {
    id: 4,
    type: 'MLLP HL7v2',
    label: 'HL7v2 Message Publish (TCP/MLLP)',
    description: 'Send MLLP Events To An IP & Port',
    disabled: false,
  },
  {
    id: 5,
    type: 'HTTPS FHIR R4',
    label: 'FHIR R4 Bundle POST (HTTPS)',
    description: 'HTTPS POST FHIR Bundle Transactions',
    disabled: true,
  },
  {
    id: 6,
    type: 'HTTPS C-CDA XML',
    label: 'C-CDA XML POST (HTTPS)',
    description: 'POST C-CDA XML Over HTTPS',
    disabled: false,
  },
  {
    id: 7,
    type: 'SOAP C-CDA XML',
    label: 'C-CDA XML POST (SOAP)',
    description: 'POST C-CDA XML Over SOAP',
    disabled: true,
  },
];

// Helper Functions
export function classNames(...classes: string[]): string {
  return classes.filter(Boolean).join(' ');
}

export interface Environment {
  accountId: string;
  network?: {
    nlb?: {
      DNSName: string;
    };
    webhook?: {
      ApiEndpoint: string;
    };
  };
}

export interface Workspace {
  hash: {
    location: string;
    value: string;
  };
}

export interface GridMap {
  field?: string;
  definition?: string;
  sample?: string;
  filter?: string;
}

export interface EventTriggerMap {
  field: string;
  definition: string;
  sample: string;
  filter: string;
}

export interface ELayerProps {
  error?: string;
  selectedEnvironment: Environment;
  workflow: {
    SK: string;
    trigger: {
      type: string;
    };
    next: {
      type: string;
      method?: string;
    };
  };
  eventTriggerGrid: EventTriggerMap[];
  sampleFilter: boolean;
  triggerSample: string;
  selectedWorkspace: Workspace;
  copied: boolean;
  hl7v2EventTofhirr4MethodMap: any[];
  clearError: () => void;
  setCopiedHandler: () => void;
  updateTriggerMethod: () => void;
  enableSampleFilter: () => void;
  updateTrigger: () => void;
  removeTriggerMapRow: () => void;
  onChangeHost: () => void;
  onChangeUsername: () => void;
  onChangePassword: () => void;
  onChangePort: () => void;
  onChangePath: () => void;
  onChangeCadence: () => void;
  onChangeCert: () => void;
  onChangeKey: () => void;
  onChangePrivateKey: () => void;
}

export interface TLayerProps {
  error?: string;
  workflow: {
    next: any;
    trigger: {
      type: string;
    };
    fields: any[];
  };
  fieldMapGrid: any[];
  fhirLoading: boolean;
  fhirProgress: number;
  sampleFilter: boolean;
  loading: boolean;
  clearError: () => void;
  enableSampleFilter: () => void;
  fetchFHIRR4Map: () => void;
  updateFieldMap: () => void;
  toggleLookUpModal: () => void;
  removeFieldMapRow: () => void;
}
export interface LLayerProps {
  error?: string;
  tunnels: any[];
  workflow: {
    SK?: string;
    trigger: {
      type: string;
    };
    next: {
      type: string;
      url?: string;
      tunnel?: any;
      ip?: string;
      port?: string;
      bucket?: string;
      key?: string;
      clientId?: string;
      clientSecret?: string;
      orgId?: string;
      projectId?: string;
    };
  };
  selectedWorkspace: {
    hash: {
      value: string;
    };
  };
  sampleMap: any;
  fhirSampleMap: any;
  clearError: () => void;
  handleNextUrl: () => void;
  handleNextTunnel: () => void;
  handleNextIP: () => void;
  handleNextPort: () => void;
  handleNextS3BucketName: () => void;
  handleNextS3Key: () => void;
  handleNextClientId: () => void;
  handleNextClientSecret: () => void;
  handleNextOrgId: () => void;
  handleNextProjectId: () => void;
}

export interface WorkflowEvent {
  id: string;
  type: string | number;
  message: string;
  timestamp: string;
  icon?: React.ReactNode;
}

export interface WorkflowsGridProps {
  selectedEnvironment: any;
  selectedWorkspace: any;
  user: any;
  admin: boolean;
  loadWorkflow: () => void;
  notify: () => void;
  newWorkflowClickHandler: () => void;
}

export interface User {
  sub: string;
  email?: string;
  picture?: string;
  account?: any;
}

export interface Workspace {
  PK: string;
  name?: string;
  hash?: {
    location: string;
    value: string;
  };
  accounts?: User[];
  invites?: Invite[];
}

export interface Invite {
  email: string;
  firstName?: string;
  lastName?: string;
}

export interface Environment {
  name: string;
  // Add other environment properties as needed
}

export interface WorkspaceProps {
  subUx: string;
  setSubUx: (ux: string) => void;
  error: string | null;
  setError: (error: string | null) => void;
  selectedProduct: any;
  togglePermissions: boolean;
  selectedUser: User | null;
  invite: Invite;
  billingUX: string;
  loadingType: string;
  setLoadingType: (type: string) => void;
  workspace: Workspace;
  quantityPurchased: number;
  setQuantityPurchased: (quantity: number) => void;
  selectProduct: (product: any) => void;
  getCustomerPortal: () => void;
  handleNewUserFirstNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleNewUserLastNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleNewUserEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleMessageIDChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleOrganizationValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  openPermissions: (user: User) => void;
  addUserToWorkspace: (userSub: string) => void;
  removeUserFromWorkspace: (user: User) => void;
  updatePermissions: (permission: any) => void;
  savePermissions: () => void;
  saveWorkspace: (workspace: Workspace) => void;
  loading: boolean;
  awsProductURL: string;
  removeInviteFromWorkspace: (invite: Invite) => void;
  selectedWorkspace: Workspace;
  environments: Environment[];
  getAccount: () => void;
  account: any;
  user: User;
  setAccount: (account: any) => void;
  setSelectedWorkspace: (workspace: Workspace) => void;
  setupEnvironmentsHandler: () => void;
  notify: (message: string) => void;
  environmentChanged: boolean;
  setEnvironmentChanged: (changed: boolean) => void;
  setEnvironments: (environments: Environment[]) => void;
}

export interface SectionProps {
  workspace: Workspace;
  handleOrganizationValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleMessageIDChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  saveWorkspace: (workspace: Workspace) => void;
}
export type Account = {
  id: string;
  name: string;
  email: string;
  password: string;
  createdAt: string;
  updatedAt: string;
};

export type Environment = {
  PK: string;
  SK: string;
  name: string;
  awsAccountID: string;
  awsUserClientId: string;
  awsUserSecret: string;
  awsRegion: string;
  resources?: any[];
  createdAt: string;
  updatedAt: string;
};

export type Workspace = {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  environments: Environment[];
  accounts: Account[];
};

export interface TunnelCardProps {
  tunnel: any;
  onKeyClick: () => void;
  onDelete: () => void;
  TunnelConnect: React.ComponentType<any>;
  connectProps: any;
}

export interface TunnelsProps {
  user: any;
  selectedWorkspace: any;
  selectedEnvironment: any;
  selectedAccount: any;
  admin: boolean;
  notify: () => void;
  setSelectedEnvironment: () => void;
  updateBillingCustomer: () => void;
  logout: () => void;
  account: any;
  environments: any[];
}

export interface TunnelProps {
  user: any;
  selectedEnvironment: any;
  tunnel: Tunnel;
  selectedWorkspace: any;
  selectedAccount: any;
  admin: any;
  notify: any;
  setSelectedEnvironment: any;
  updateBillingCustomer: any;
  logout: any;
  account: any;
  environments: any;
  showDownloadTunnelConfigClickHandler: any;
}
export interface Location {
  ip: string;
  city: string;
  state: string;
  country: string;
}

export interface NavItem {
  name: string;
  href: string;
  current: boolean;
}

export interface WebSocketContextValue {
  wsClient: WebSocket | null;
  notify: (data: { message: string }) => void;
  createWSSClient: (userId: string) => WebSocket;
}

export interface Environment {
  SK: string;
  // Add other environment properties
}

export interface Workspace {
  SK: string;
  environments: Environment[];
  permissions: any; // Define specific permissions type
}

export interface Account {
  workspaces: Workspace[];
  myWorkspace: Workspace;
}
