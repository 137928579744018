/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Switch } from 'react-router-dom';
import { Dashboard, Workflows, Tunnels, Workspace } from '../../../../app/pages';
import { PrivateRoute } from '../../../../app/components/Base/Layout/PrivateRoute';

interface BaseProps {
  account: any;
  environments: any;
  selectedAccount: any;
  setAccount: any;
  updateBillingCustomer: any;
  setSelectedWorkspace: any;
  selectedEnvironment: any;
  setSelectedEnvironment: any;
  setupEnvironmentsHandler: any;
  setSelectedAccount: any;
  redirectToURL: any;
  history: any;
  selectedWorkspace: any;
  getAccount: any;
  notify: any;
  environmentChanged: any;
  setEnvironmentChanged: any;
  user: any;
  fetchAccount: any;
  setEnvironments: any;
}

export default function PrivateRoutes(props: BaseProps) {
  const {
    account,
    environments,
    selectedAccount,
    setAccount,
    updateBillingCustomer,
    setSelectedWorkspace,
    selectedEnvironment,
    setSelectedEnvironment,
    setSelectedAccount,
    redirectToURL,
    selectedWorkspace,
    notify,
    environmentChanged,
    setEnvironmentChanged,
    user,
    getAccount,
    setEnvironments,
  } = props;

  return (
    <Switch>
      <PrivateRoute
        exact
        path="/"
        render={(routeProps: any) => (
          <Dashboard
            {...routeProps}
            user={user}
            account={account}
            setAccount={setAccount}
            environments={environments}
            selectedAccount={selectedAccount}
            selectedWorkspace={selectedWorkspace}
            updateBillingCustomer={updateBillingCustomer}
            selectedEnvironment={selectedEnvironment}
            setSelectedEnvironment={setSelectedEnvironment}
            notify={notify}
          />
        )}
      />
      <PrivateRoute
        path="/workflows"
        render={(routeProps: any) => (
          <Workflows
            {...routeProps}
            user={user}
            account={account}
            setAccount={setAccount}
            environments={environments}
            selectedAccount={selectedAccount}
            selectedWorkspace={selectedWorkspace}
            selectedEnvironment={selectedEnvironment}
            setSelectedEnvironment={setSelectedEnvironment}
            notify={notify}
          />
        )}
      />
      <PrivateRoute
        path="/tunnels"
        render={(routeProps: any) => (
          <Tunnels
            {...routeProps}
            user={user}
            account={account}
            setAccount={setAccount}
            environments={environments}
            selectedAccount={selectedAccount}
            selectedEnvironment={selectedEnvironment}
            selectedWorkspace={selectedWorkspace}
            setSelectedEnvironment={setSelectedEnvironment}
            notify={notify}
          />
        )}
      />
      <PrivateRoute
        path="/workspace"
        render={(routeProps: any) => (
          <Workspace
            {...routeProps}
            user={user}
            account={account}
            setAccount={setAccount}
            getAccount={getAccount}
            environments={environments}
            workspaces={account?.workspaces}
            selectedAccount={selectedAccount}
            setSelectedAccount={setSelectedAccount}
            selectedWorkspace={selectedWorkspace}
            selectedEnvironment={selectedEnvironment}
            setEnvironments={setEnvironments}
            setSelectedEnvironment={setSelectedEnvironment}
            setSelectedWorkspace={setSelectedWorkspace}
            setupEnvironmentsHandler={setEnvironments}
            redirectToURL={redirectToURL}
            notify={notify}
            environmentChanged={environmentChanged}
            setEnvironmentChanged={setEnvironmentChanged}
          />
        )}
      />
    </Switch>
  );
}
