/* eslint-disable no-unused-expressions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/function-component-definition */
import React, { Fragment } from 'react';
import { motion } from 'framer-motion';
import { Dialog, Transition } from '@headlessui/react';
import logo from '../../../images/retrohook.png';

// Type definitions
interface TermsOfServiceProps {
  open: boolean;
  closeModal: () => void;
}

const TermsOfService: React.FC = () => (
  <div className="tos-container">
    <h1 className="tos-title">Terms of Service</h1>
    <p className="tos-subtitle">Last Updated: February 10, 2025</p>
    <h2 className="tos-section-title">Welcome to Retrohook</h2>
    <p>
      Welcome to Retrohook! These Terms of Service ("Terms") govern your use of the Retrohook platform ("Service"), provided by 1PuttHealth LLC ("Company",
      "we", "us", "our").
    </p>
    <h2 className="tos-section-title">1. Acceptance of Terms</h2>
    <p>
      By accessing or using the Service, you agree to comply with and be bound by these Terms.
      <strong> If you do not agree to these Terms, please do not use the Service.</strong>
    </p>
    <h2 className="tos-section-title">2. Changes to Terms</h2>
    <p>We reserve the right to modify these Terms at any time. Your continued use of the Service constitutes your acceptance of any changes.</p>
    <h2 className="tos-section-title">3. Privacy Policy</h2>
    <p>Your use of the Service is also governed by our Privacy Policy, which can be requested upon contact.</p>
    <h2 className="tos-section-title">4. User Responsibilities</h2>
    <p>You are responsible for all activities that occur under your account. We expect you to:</p>
    <ul className="list-disc pl-5">
      <li>Maintain the confidentiality of your account</li>
      <li>Use the service in a legal and ethical manner</li>
      <li>Avoid any prohibited conduct</li>
    </ul>
    <h2 className="tos-section-title">5. Limitation of Liability</h2>
    <p>To the fullest extent permitted by law, the Company shall not be liable for any:</p>
    <ul className="list-disc pl-5">
      <li>Indirect damages</li>
      <li>Incidental damages</li>
      <li>Consequential damages</li>
    </ul>
    <h2 className="tos-section-title">6. Governing Law</h2>
    <p>These Terms are governed by the laws of the State of Delaware, without regard to its conflict of laws principles.</p>
    <h2 className="tos-section-title">7. Contact Information</h2>
    <p>
      For any questions about these Terms, please contact us at{' '}
      <a href="mailto:support@retrohook.com" className="text-blue-600 hover:underline">
        support@retrohook.com
      </a>
      .
    </p>
    <p className="mt-4 italic">By using Retrohook, you acknowledge that you have read, understood, and agree to these Terms of Service.</p>
  </div>
);

export default function TermsOfServiceModal(props: TermsOfServiceProps) {
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => props.closeModal()}>
        <div className="tos-modal-container">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="tos-modal-overlay" />
          </Transition.Child>

          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="tos-modal-content">
              <div className="tos-modal-inner">
                <div className="tos-modal-body">
                  <motion.div className="items-center" initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.3 }}>
                    <motion.img className="tos-logo" src={logo} alt="Logo" whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }} />
                    <h2 className="tos-logo-text" style={{ fontFamily: '"Gugi", sans-serif' }}>
                      Retrohook
                    </h2>
                  </motion.div>
                  <TermsOfService />
                </div>
              </div>

              <div className="px-4 pb-2">
                <motion.button
                  type="button"
                  className="tos-close-button"
                  onClick={() => props.closeModal()}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Close
                </motion.button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
