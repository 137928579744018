import React, { Fragment } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Dialog, Transition } from '@headlessui/react';
import { ArrowRightIcon, ClipboardDocumentListIcon } from '@heroicons/react/24/outline';
import { IconPickerItem } from 'react-heroicons-picker';
import '../../../../css/Workflows.css';

interface Props {
  isOpen: boolean;
  templates: any;
  saveButton: boolean;
  closeModal: () => void;
  applyTemplate: (event: any) => void;
  saveTemplate: (event: any) => void;
}

export default function TemplatesModal({ isOpen, templates, closeModal, applyTemplate, saveTemplate, saveButton }: Props) {
  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3,
      },
    },
    hover: {
      scale: 1.02,
      transition: { duration: 0.2 },
    },
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="modal-container" onClose={closeModal}>
        <div className="modal-wrapper">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="modal-overlay" />
          </Transition.Child>

          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <motion.div className="modal-content" initial="hidden" animate="visible" variants={containerVariants}>
              <div className="p-6">
                {/* Header */}
                <motion.div className="modal-header" variants={itemVariants}>
                  <div className="modal-header-icon">
                    <ClipboardDocumentListIcon className="h-7 w-7 text-gray-600 dark:text-gray-300" />
                  </div>
                  <Dialog.Title className="modal-title">Templates</Dialog.Title>
                </motion.div>

                {/* Templates Grid */}
                <motion.div className="templates-grid" variants={containerVariants}>
                  <AnimatePresence>
                    {templates.map((template: any) => (
                      <motion.div
                        key={template.id}
                        className="template-card"
                        variants={itemVariants}
                        whileHover="hover"
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                      >
                        <div className="template-header">
                          <div className="template-icon-wrapper">
                            <IconPickerItem size={4} icon={template?.icon || 'BoltIcon'} library="outline" />
                          </div>
                          <p className="template-name">{template.flowName}</p>
                        </div>

                        <div className="flow-container">
                          <div className="flow-steps">
                            <span className="flow-step">{template.trigger.type}</span>
                            <ArrowRightIcon className="flow-arrow" />
                            <span className="flow-step-end">{template.next.type}</span>
                          </div>
                          <motion.button
                            type="button"
                            onClick={() => {
                              applyTemplate(template);
                              closeModal();
                            }}
                            className="apply-button"
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                          >
                            Apply
                          </motion.button>
                        </div>
                      </motion.div>
                    ))}
                  </AnimatePresence>
                </motion.div>
              </div>

              {/* Footer */}
              <motion.div className="modal-footer" variants={itemVariants}>
                <motion.button type="button" onClick={closeModal} className="cancel-button" whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                  Cancel
                </motion.button>
                {saveButton && (
                  <motion.button type="button" onClick={saveTemplate} className="save-button" whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                    Generate Template from Current Workflow
                  </motion.button>
                )}
              </motion.div>
            </motion.div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
