/* eslint-disable react/require-default-props */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable eqeqeq */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/function-component-definition */

import { BoltIcon, ShareIcon, SparklesIcon, ArrowLeftCircleIcon, CodeBracketSquareIcon, Square3Stack3DIcon } from '@heroicons/react/24/outline';
import InnerNavButton from '../../../Base/Layout/NavigationElements/InnerNavButton';
import InnerNavButtonSelected from '../../../Base/Layout/NavigationElements/InnerNavButtonSelected';
import DropdownButton from './SaveDropdown';
import ActionButton from '../../../Base/Layout/NavigationElements/ActionButton';
import '../../../../css/Workflows.css';

interface WorkflowType {
  PK?: string;
  trigger: {
    type: string;
  };
  sampleMessage?: any;
}

interface SubNavProps {
  ux: 'adapters' | 'e-layer' | 't-layer' | 'l-layer';
  workflow: WorkflowType;
  template?: {
    PK?: string;
  };
  saveTemplate: () => void;
  saveWorkflow: () => void;
  adapterClickHandler: () => void;
  eventTriggerClickHandler: () => void;
  mapClickHandler: () => void;
  loadClickHandler: () => void;
  showTemplates: () => void;
  backToListClickHandler: () => void;
}

// Helper component to reduce duplication
interface NavButtonProps {
  title: string;
  icon: any;
  isSelected: boolean;
  onClick: () => void;
}

function NavButton({ title, icon, isSelected, onClick }: NavButtonProps) {
  const ButtonComponent = isSelected ? InnerNavButtonSelected : InnerNavButton;
  return <ButtonComponent title={title} icon={icon} clickHandler={onClick} />;
}

export default function SubNav({
  ux,
  workflow,
  template,
  saveTemplate,
  saveWorkflow,
  adapterClickHandler,
  eventTriggerClickHandler,
  mapClickHandler,
  loadClickHandler,
  showTemplates,
  backToListClickHandler,
}: SubNavProps) {
  const saveOptions = [
    {
      label: template?.PK ? 'Update Template' : 'Generate Template',
      action: saveTemplate,
    },
    {
      label: workflow?.PK ? 'Update Workflow' : 'Create Workflow',
      action: saveWorkflow,
    },
  ];

  const createOptions = [
    {
      label: 'Generate Template',
      action: saveTemplate,
    },
    {
      label: 'Create Workflow',
      action: saveWorkflow,
    },
  ];

  const isHttpTrigger = workflow.trigger.type.substring(0, 4) === 'HTTP';

  return (
    <aside className="subnav-container">
      <div className="subnav-wrapper">
        <div className="subnav-buttons-container">
          <NavButton title="Setup" icon={CodeBracketSquareIcon} isSelected={ux === 'adapters'} onClick={adapterClickHandler} />
          {!isHttpTrigger && <NavButton title="Trigger" icon={BoltIcon} isSelected={ux === 'e-layer'} onClick={eventTriggerClickHandler} />}
          <NavButton title="Data Map" icon={ShareIcon} isSelected={ux === 't-layer'} onClick={mapClickHandler} />
          <NavButton title="Action" icon={SparklesIcon} isSelected={ux === 'l-layer'} onClick={loadClickHandler} />
        </div>

        <ActionButton title="Templates" icon={Square3Stack3DIcon} clickHandler={showTemplates} />
        <div className="subnav-divider" />
        {workflow?.sampleMessage && !workflow?.PK && <DropdownButton label="Create" options={createOptions} />}
        {workflow?.PK && <DropdownButton label="Save" options={saveOptions} />}
        <div className="subnav-divider-small" />
        <ActionButton title="Close" icon={ArrowLeftCircleIcon} clickHandler={backToListClickHandler} />
      </div>
    </aside>
  );
}
