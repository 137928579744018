import React from 'react';
import { Switch } from '@headlessui/react';

import '../../../../css/Workflows.css';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export default function GridToggle({ enabled, setEnabled }: any) {
  return (
    <Switch checked={enabled} onChange={setEnabled} className={classNames('toggle-switch', enabled ? 'toggle-switch-enabled' : 'toggle-switch-disabled')}>
      <span className="sr-only">Toggle Empty Values</span>
      <span aria-hidden="true" className={classNames('toggle-handle', enabled ? 'toggle-handle-enabled' : 'toggle-handle-disabled')} />
    </Switch>
  );
}
