/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import useFetch from 'use-http';
import moment from 'moment';
import {
  CloudIcon,
  CloudArrowUpIcon,
  VariableIcon,
  SparklesIcon,
  CheckCircleIcon,
  CodeBracketIcon,
  SquaresPlusIcon,
  ExclamationTriangleIcon,
  ArrowLeftCircleIcon,
} from '@heroicons/react/24/outline';

import config from '../../../config';
import WorkflowCard from './WorkflowCard';
import MaximumNumberWorkflowsAlert from '../Base/Elements/Alerts/MaximumNumberWorkflowsAlert';
import ActionButton from '../Base/Layout/NavigationElements/ActionButton';
import ConfirmModal from '../Base/Elements/Confirmations/ConfirmModal';
import logo from '../../../images/retrohook.png';
import { Workflow, WorkflowEvent, WorkflowsGridProps } from '../../types';
import '../../css/Workflows.css';

// Utility function to map event types to icons
const getEventIcon = (eventType: string | number) => {
  const iconClasses = 'rounded-full h-8 w-8 p-1 bg-gray-300 dark:bg-cyan-300 text-white';
  const iconMapping: Record<string | number, React.ReactNode> = {
    'T-101': <VariableIcon className={`${iconClasses}`} aria-hidden="true" />,
    101: <VariableIcon className={`${iconClasses}`} aria-hidden="true" />,
    'T-111': <VariableIcon className="rounded-full h-8 w-8 bg-gray-300 dark:bg-orange-500 text-white" aria-hidden="true" />,
    'T-102': <SparklesIcon className={`${iconClasses}`} aria-hidden="true" />,
    102: <SparklesIcon className={`${iconClasses}`} aria-hidden="true" />,
    'L-201': <CloudArrowUpIcon className={`${iconClasses}`} aria-hidden="true" />,
    201: <CloudArrowUpIcon className={`${iconClasses}`} aria-hidden="true" />,
    'L-204': <CloudArrowUpIcon className={`${iconClasses}`} aria-hidden="true" />,
    204: <CloudArrowUpIcon className={`${iconClasses}`} aria-hidden="true" />,
    'L-202': <CloudIcon className={`${iconClasses}`} aria-hidden="true" />,
    202: <CloudIcon className={`${iconClasses}`} aria-hidden="true" />,
    'L-221': <ExclamationTriangleIcon className="rounded-full h-8 w-8 p-1 bg-gray-300 dark:bg-pink-300 text-white" aria-hidden="true" />,
    221: <ExclamationTriangleIcon className="rounded-full h-8 w-8 p-1 bg-gray-300 dark:bg-pink-300 text-white" aria-hidden="true" />,
    'L-222': <ExclamationTriangleIcon className="rounded-full h-8 w-8 p-1 bg-gray-300 dark:bg-pink-300 text-pink" aria-hidden="true" />,
    222: <ExclamationTriangleIcon className="rounded-full h-8 w-8 p-1 bg-gray-300 dark:bg-pink-300 text-pink" aria-hidden="true" />,
    'L-223': <ExclamationTriangleIcon className="rounded-full h-8 w-8 p-1 bg-gray-300 dark:bg-pink-300 text-pink" aria-hidden="true" />,
    223: <ExclamationTriangleIcon className="rounded-full h-8 w-8 p-1 bg-gray-300 dark:bg-pink-300 text-pink" aria-hidden="true" />,
    'C-001': <CheckCircleIcon className={`${iconClasses}`} aria-hidden="true" />,
    'U-001': <CodeBracketIcon className={`${iconClasses}`} aria-hidden="true" />,
  };

  return iconMapping[eventType] || null;
};

const WorkflowEventItem: React.FC<{ event: WorkflowEvent }> = function ({ event }) {
  return (
    <motion.li key={event.id} initial={{ opacity: 0, x: -20 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: 20 }} transition={{ duration: 0.3 }}>
      <div className="workflow-event-item">
        <span className="workflow-event-separator" aria-hidden="true" />
        <div className="relative flex space-x-2">
          <div>
            <span className="workflow-event-icon-container">{event.icon}</span>
          </div>
          <div className="workflow-event-details">
            <div>
              <p className="workflow-event-message">{event.message}</p>
            </div>
            <div className="workflow-event-timestamp">
              <time dateTime={event.timestamp}>{moment(event.timestamp).format('dddd, MMMM Do YYYY, h:mm:ss a')}</time>
            </div>
          </div>
        </div>
      </div>
    </motion.li>
  );
};

export default function WorkflowsGrid({
  selectedEnvironment,
  selectedWorkspace,
  user,
  admin,
  loadWorkflow,
  notify,
  newWorkflowClickHandler,
}: WorkflowsGridProps) {
  const { post, response, loading } = useFetch(config.url, { cachePolicy: 'no-cache' });
  const [workflows, setWorkflows] = useState<Workflow[]>([]);
  const [selectedFlow, setSelectedFlow] = useState<Workflow>({
    events: [],
    flowName: '',
    SK: '',
    trigger: { type: '' },
    next: { type: '' },
    successes: 0,
    failures: 0,
  });
  const [showAccessAlert, setShowAccessAlert] = useState(false);
  const [currentWorkflow, setCurrentWorkflow] = useState<Workflow>({
    flowName: '',
    SK: '',
    trigger: { type: '' },
    next: { type: '' },
    successes: 0,
    failures: 0,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Fetch workflows
  const getWorkflows = async () => {
    const body = { selectedWorkspace, selectedEnvironment };
    try {
      const workflowRequest: any = await post(`workflow/list-workflows/${user.sub}`, body);
      if (response.ok && workflowRequest) {
        const { workflows } = workflowRequest;
        setWorkflows(workflows || []);
        return workflows;
      }
      return [];
    } catch (error) {
      console.error('Error fetching workflows:', error);
      return [];
    }
  };

  // Fetch workflows when workspace or environment changes
  useEffect(() => {
    if (selectedWorkspace && selectedEnvironment) {
      getWorkflows();
    }
  }, [JSON.stringify(selectedWorkspace), JSON.stringify(selectedEnvironment)]);

  // Permissions check for delete
  const canDelete = () => {
    const hasUpdateWorkflowPermission = selectedWorkspace.accounts.some((account: any) => account.PK && account.permissions.workflows.delete);
    const isWorkspaceOwner = selectedWorkspace.owner.email === user.account.email;
    return admin || hasUpdateWorkflowPermission || isWorkspaceOwner;
  };

  // Edit workflow handler
  const editFlow = (flow: Workflow) => {
    loadWorkflow(flow);
  };

  // Delete workflow handler
  const deleteWorkflow = async (flow: Workflow) => {
    if (canDelete()) {
      const body = { selected: selectedEnvironment, flow };
      try {
        const deleteRequest = await post(`workflow/delete-workflow/${user.sub}`, body);
        if (response.ok && deleteRequest) {
          notify({ message: `Workflow Deleted` });
          await getWorkflows();
        }
      } catch (error) {
        console.error('Error deleting workflow:', error);
      }
    }
  };

  // Modal and confirmation handlers
  const openModal = (workflow: Workflow) => {
    setCurrentWorkflow(workflow);
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);

  const confirmDelete = () => {
    deleteWorkflow(currentWorkflow);
    closeModal();
  };

  // View events for a workflow
  const viewEvents = (flow: Workflow) => {
    if (flow.events) {
      const enrichedEvents = flow.events.map((event) => ({
        ...event,
        icon: getEventIcon(event.type),
      }));
      setSelectedFlow({ ...flow, events: enrichedEvents });
    }
  };

  // Loading state render
  const renderLoading = () => (
    <motion.div
      className="workflows-loading"
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ type: 'spring', stiffness: 300, damping: 20 }}
    >
      <motion.img
        className="workflows-loading-logo"
        src={logo}
        alt="Logo"
        initial={{ rotate: -10 }}
        animate={{ rotate: 0 }}
        transition={{ type: 'spring', stiffness: 300, damping: 20 }}
      />
      <motion.h2
        className="workflows-loading-title"
        style={{ fontFamily: '"Gugi", sans-serif' }}
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ delay: 0.2 }}
      >
        Retrohook
        <span className="workflows-loading-version" />
      </motion.h2>
    </motion.div>
  );

  // Render workflows grid
  const renderWorkflowsGrid = () => (
    <motion.div className="workflows-grid" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.3 }}>
      <AnimatePresence>
        {workflows.length < 1 && (
          <motion.div className="workflows-empty-state" initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: 20 }}>
            <p className="workflows-empty-state-text">You don&apos;t have any workflows in this workspace environment.</p>
          </motion.div>
        )}
        {workflows.map((flow) => (
          <motion.div key={flow.SK} initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: 20 }} transition={{ duration: 0.3 }}>
            <WorkflowCard flow={flow} onEdit={editFlow} onDelete={openModal} onViewEvents={viewEvents} />
          </motion.div>
        ))}
      </AnimatePresence>
    </motion.div>
  );

  // Render workflow events
  const renderWorkflowEvents = () => (
    <motion.div className="h-full flex w-full" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.3 }}>
      <aside className="workflow-events-sidebar">
        <div className="workflow-events-sidebar-content">
          <div className="items-start place-items-start px-2 space-y-1 py-1">
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <ActionButton
                title="Close"
                icon={ArrowLeftCircleIcon}
                clickHandler={() =>
                  setSelectedFlow({
                    events: [],
                    flowName: '',
                    SK: '',
                    trigger: { type: '' },
                    next: { type: '' },
                    successes: 0,
                    failures: 0,
                  })
                }
              />
            </motion.div>
          </div>
        </div>
      </aside>
      <motion.ul
        className="workflow-events-list"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3, delayChildren: 0.2, staggerChildren: 0.1 }}
      >
        {!selectedFlow.events && (
          <motion.h2 className="workflow-events-empty-state" initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }}>
            Events will start appearing when messages are processed by this workflow.
          </motion.h2>
        )}
        <AnimatePresence>
          {selectedFlow.events
            ?.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())
            .map((event) => <WorkflowEventItem key={event.id} event={event} />)}
        </AnimatePresence>
      </motion.ul>
    </motion.div>
  );

  // Main render
  if (!selectedWorkspace || !selectedEnvironment) return null;

  return (
    <motion.div className="workflows-main-container" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.3 }}>
      {!selectedFlow.SK && (
        <motion.div className="flex h-full bg-gray-700/70 w-full" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.3 }}>
          <header className="workflows-sidebar">
            <div className="workflows-sidebar-content">
              <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                <ActionButton title="Create" icon={SquaresPlusIcon} clickHandler={newWorkflowClickHandler} />
              </motion.div>
            </div>
          </header>

          {showAccessAlert && <MaximumNumberWorkflowsAlert closeProdRequiredAlert={() => setShowAccessAlert(false)} />}

          <ConfirmModal
            isOpen={isModalOpen}
            onClose={closeModal}
            onConfirm={confirmDelete}
            title="Confirm Delete Workflow"
            message={`Are you sure you want to delete ${currentWorkflow?.flowName}?`}
          />

          {loading ? renderLoading() : renderWorkflowsGrid()}
        </motion.div>
      )}

      {selectedFlow.SK && renderWorkflowEvents()}
    </motion.div>
  );
}
