import React from 'react';
import { motion } from 'framer-motion';
import { DocumentTextIcon, CalendarDaysIcon, PlayCircleIcon, ChatBubbleLeftRightIcon } from '@heroicons/react/24/outline';
import help from '../../../images/help.png';
import AWSMarket from '../../../images/aws-mkt.png';
import AWSMarketDark from '../../../images/aws-mkt-dark.png';
import '../../css/Product.css';

interface ContactUsProps {
  effectiveTheme: 'dark' | 'light';
  matrix?: boolean;
}

interface ContactLink {
  href: string;
  className: string;
  icon: React.ReactNode;
  text: string;
  external?: boolean;
  onClick?: () => void;
}

export default function ContactUs({ effectiveTheme, matrix }: ContactUsProps) {
  const contactLinks: ContactLink[] = [
    {
      href: 'https://docs.retrohook.com',
      className: 'contact-link-docs',
      icon: <DocumentTextIcon className="contact-link-docs-icon" aria-hidden="true" />,
      text: 'Docs',
      external: true,
    },
    {
      href: 'https://www.youtube.com/channel/UCsgby_S_K27scdBlvvOJK8A',
      className: 'contact-link-youtube',
      icon: <PlayCircleIcon className="contact-link-youtube-icon" aria-hidden="true" />,
      text: 'Youtube',
      external: true,
    },
    {
      href: 'https://calendly.com/clint_johnson/retrohook?hide_gdpr_banner=1',
      className: 'contact-link-demo',
      icon: <CalendarDaysIcon className="contact-link-demo-icon" aria-hidden="true" />,
      text: 'Schedule a Demo',
      external: true,
    },
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
    hover: {
      scale: 1.05,
      transition: { duration: 0.2 },
    },
  };

  const renderContactLink = ({ href, className, icon, text, external, onClick }: ContactLink) => (
    <motion.a
      key={text}
      href={href}
      className={className}
      rel={external ? 'noreferrer' : undefined}
      target={external ? '_blank' : undefined}
      onClick={onClick}
      variants={itemVariants}
      whileHover="hover"
    >
      {icon}
      {text}
    </motion.a>
  );

  return (
    <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} variants={containerVariants}>
      <motion.div className="contact-image-container" variants={itemVariants} whileHover={{ scale: 1.05 }}>
        <motion.img
          src={help}
          className="contact-image"
          alt="Retrohook takes you back in time"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        />
      </motion.div>

      <div className="contact-content-wrapper">
        <motion.div className="contact-content" variants={itemVariants}>
          <div className={matrix ? 'contact-matrix-wrapper' : 'cursor-pointer'}>
            <h2 className="contact-spacer">&nbsp;</h2>

            <motion.p className="contact-title" variants={itemVariants}>
              Get Started with Retrohook
            </motion.p>

            <motion.p className="contact-description" variants={itemVariants}>
              Check out our documentation to learn how to get started with Retrohook. When you are ready we are here to help.
            </motion.p>

            <motion.div className="contact-links" variants={containerVariants}>
              {contactLinks.map(renderContactLink)}

              <motion.div variants={itemVariants}>
                {renderContactLink({
                  href: '#/contact',
                  className: 'contact-link-chat',
                  icon: <ChatBubbleLeftRightIcon className="contact-link-chat-icon" aria-hidden="true" />,
                  text: 'Chat Now',
                  // eslint-disable-next-line no-undef
                  onClick: () => window.$chatwoot.toggle('open'),
                })}
                <motion.div initial={{ opacity: 0, x: -20 }} animate={{ opacity: 1, x: 0 }} transition={{ delay: 0.5, duration: 0.5 }}>
                </motion.div>
              </motion.div>

              <motion.a
                href="https://aws.amazon.com/marketplace/pp/prodview-efbfzy3xjgx5s"
                className="contact-aws-link"
                target="_blank"
                rel="noreferrer"
                variants={itemVariants}
                whileHover="hover"
              >
                Available on
                <motion.img
                  className="contact-aws-image"
                  src={effectiveTheme !== 'dark' ? AWSMarketDark : AWSMarket}
                  alt="AWS Marketplace"
                  whileHover={{ scale: 1.1 }}
                />
              </motion.a>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
}
