/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { ArrowTrendingUpIcon, ChartBarIcon } from '@heroicons/react/24/outline';
import StackedBarChart from '../components/Dashboard/StackedBarChart';
import AreaChart from '../components/Dashboard/AreaChart';
import logo from '../../images/retrohook.png';
import '../css/Dashboard.css';
import { useDashboard } from '../hooks/useDashboard';

export default function Dashboard({ selectedWorkspace, selectedEnvironment, user }: any) {
  const [chartType, setChartType] = useState('bar');

  const { workflows, shouldRender, loading } = useDashboard({
    selectedWorkspace,
    selectedEnvironment,
    userToken: user.token,
  });

  if (selectedEnvironment) {
    return (
      <div className="dashboard-container">
        <motion.div className="dashboard-sidebar" initial={{ opacity: 0, x: -50 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 0.5 }}>
          <h2 className="dashboard-sidebar-title" style={{ fontFamily: '"Gugi", sans-serif' }}>
            {selectedEnvironment?.name}
          </h2>
          <div className="dashboard-sidebar-content">
            {!loading && (
              <div className="dashboard-sidebar-buttons">
                <div className="text-md text-gray-200 p-2 flex flex-col space-y-2">
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className={`flex items-center px-4 py-2 rounded-md transition-colors duration-200 
                    ${chartType === 'area' ? 'dashboard-chart-button-active' : 'dashboard-chart-button'}`}
                    onClick={() => setChartType('area')}
                  >
                    <ArrowTrendingUpIcon className="h-4 w-4 mr-2" />
                    Area
                  </motion.button>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className={`flex items-center px-4 py-2 rounded-md transition-colors duration-200 
                    ${chartType === 'bar' ? 'dashboard-chart-button-active' : 'dashboard-chart-button'}`}
                    onClick={() => setChartType('bar')}
                  >
                    <ChartBarIcon className="h-4 w-4 mr-2" />
                    Bar
                  </motion.button>
                </div>
              </div>
            )}
          </div>
        </motion.div>
        <div className="dashboard-main-content">
          {loading && (
            <motion.div
              className="dashboard-loading-container"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ type: 'spring', stiffness: 300, damping: 20 }}
            >
              <div className="dashboard-loading-content">
                <motion.img
                  className="dashboard-loading-logo"
                  src={logo}
                  alt="Logo"
                  initial={{ rotate: -10 }}
                  animate={{ rotate: 0 }}
                  transition={{ type: 'spring', stiffness: 300, damping: 20 }}
                />
                <h2 className="dashboard-loading-text" style={{ fontFamily: '"Gugi", sans-serif' }}>
                  Retrohook
                </h2>
              </div>
            </motion.div>
          )}

          {shouldRender && chartType === 'bar' && <StackedBarChart workflows={workflows} />}
          {shouldRender && chartType === 'area' && <AreaChart workflows={workflows} />}

          {!shouldRender && !loading && (
            <motion.div className="dashboard-empty-state" initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
              <div className="dashboard-empty-state-title">Hi {user?.first}, Welcome to Retrohook!</div>
              <p className="dashboard-empty-state-description">
                This environment doesn&apos;t have any transactions... Ready to create your first workflow? Click the Workflows tab above. New here and unsure
                of how to build a Workflow? Check out our step-by-step guides at the top or chat with us (the little conversation in the bottom right corner).
              </p>
            </motion.div>
          )}
        </div>
      </div>
    );
  }
}
