import React from 'react';
import '../../../../css/Base.css';

// Type definition for props
interface ActionButtonProps {
  title: string;
  clickHandler: () => void;
  icon: React.ComponentType<{
    icon?: any;
    className?: string;
  }>;
}

export default function ActionButton({ title, clickHandler, icon: Icon }: ActionButtonProps) {
  return (
    <div className="action-button-container">
      <button type="button" onClick={clickHandler} className="action-button">
        <Icon className="action-button-icon" />
        {title}
      </button>
    </div>
  );
}
