import React from 'react';
import { motion } from 'framer-motion';
import { ArrowLeftCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import { TypingAnimation } from './Elements/Terminal';
import useTunnels from '../../hooks/useTunnels';
import Toggle from './Elements/TunnelTypeToggle';
import Dropdown from './Elements/TunnelConfigDropdown';
import { TunnelProps } from '../../types';

export interface TunnelConnectProps extends TunnelProps {
  tunnel: {
    name?: string;
    Tags: Array<{ Value: string }>;
    showDownloadTunnelConfig?: boolean;
  };
  showDownloadTunnelConfigClickHandler: () => void;
}

const TunnelConnect: React.FC<TunnelConnectProps> = function ({ tunnel, showDownloadTunnelConfigClickHandler, ...props }) {
  const { devices, showAdvancedTunnelConfig, setShowadvancedTunnelConfig, selectHandler, downloadML7Config, downloadTunnelConfig } = useTunnels(props);

  const renderAdvancedConfig = () => (
    <div className="space-y-3 text-center items-center">
      <TypingAnimation duration={30} delay={100} className="text-gray-400">
        Device specific connection support.
      </TypingAnimation>

      <div className="mt-2">
        <Dropdown className="text-center items-center w-full bg-background border border-border rounded-md text-sm" selectHandler={selectHandler} devices={devices} />
      </div>

      <motion.button
        type="button"
        onClick={() => downloadTunnelConfig(tunnel)}
        className="px-4 py-2 bg-purple-500 text-white rounded-md text-sm hover:bg-purple-600 transition-colors"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        Download
      </motion.button>
    </div>
  );

  const renderSimpleConfig = () => (
    <div className="space-y-3">

      <div className="text-center items-center gap-x-2">
        <motion.button
          type="button"
          onClick={() => downloadML7Config(tunnel)}
          className="text-purple-500 rounded-md text-sm hover:text-purple-600 transition-colors"
          whileHover={{ scale: 1.00 }}
          whileTap={{ scale: 0.95 }}
        >
          Download&nbsp;
        </motion.button>
        <TypingAnimation duration={30} delay={100} className="text-gray-400">
          the connection agent.
        </TypingAnimation>
      </div>
    </div>
  );

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-x-2 text-sm text-gray-400 justify-end">
        <Toggle
          enabled={showAdvancedTunnelConfig}
          name={showAdvancedTunnelConfig}
          setEnabled={() => setShowadvancedTunnelConfig(!showAdvancedTunnelConfig)}
          className="mt-1"
        />
        <span>Advanced</span>
      </div>

      {showAdvancedTunnelConfig ? renderAdvancedConfig() : renderSimpleConfig()}
    </div>
  );
};

export default TunnelConnect;
