import React, { Fragment } from 'react';
import { motion } from 'framer-motion';
import { Dialog, Transition } from '@headlessui/react';
import { CogIcon } from '@heroicons/react/24/outline';
import Toggle from '../Base/Elements/Input/Toggle';
import '../../css/Account.css';

interface UserPermissionsModalProps {
  selectedUser: {
    email: string;
    id: string;
    permissions: {
      workspace: { admin: string };
      dashboard: { read: string };
      workflows: {
        read: string;
        create: string;
        update: string;
        delete: string;
      };
      tunnels: {
        read: string;
        create: string;
        delete: string;
      };
    };
  };
  open: boolean;
  close: () => void;
  updatePermissions: (category: string, permission: string, user: string | object) => void;
}

export default function UserPermissionsModal({ selectedUser, open, close, updatePermissions }: UserPermissionsModalProps) {
  if (!selectedUser) return null;

  return (
    <Transition.Root show={open} as={React.Fragment}>
      <Dialog as="div" className="permissions-modal-container" onClose={close}>
        <div className="permissions-modal-wrapper">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="permissions-modal-overlay" />
          </Transition.Child>

          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="permissions-modal-content">
              <div className="permissions-modal-header">
                <div className="permissions-modal-header-icon-wrapper">
                  <CogIcon className="permissions-modal-header-icon" />
                  <Dialog.Title as="h3" className="permissions-modal-header-title">
                    {' '}
                    &nbsp; {selectedUser.email}{' '}
                  </Dialog.Title>
                </div>
              </div>

              <div className="permissions-modal-body">
                <div className="permissions-modal-grid">
                  {/* Workspace Permissions */}
                  <div className="permissions-modal-label">Workspace</div>
                  <div className="permissions-modal-toggle-wrapper">
                    <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                      <Toggle
                        name="admin-set"
                        enabled={JSON.parse(selectedUser.permissions.workspace.admin)}
                        onChange={() => updatePermissions('workspace', 'admin', selectedUser)}
                      />
                    </motion.div>
                    &nbsp;&nbsp;Admin{' '}
                  </div>

                  {/* Dashboard Permissions */}
                  <div className="permissions-modal-label">Dashboard</div>
                  <div className="permissions-modal-toggle-wrapper">
                    <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                      <Toggle
                        name="dashboard-view"
                        enabled={JSON.parse(selectedUser.permissions.dashboard.read)}
                        onChange={() => updatePermissions('dashboard', 'read', selectedUser)}
                      />
                    </motion.div>
                    &nbsp;View{' '}
                  </div>

                  {/* Rest of the toggles follow the same pattern */}
                  {/* Workflows Permissions */}
                  <div className="permissions-modal-label">Workflows</div>
                  <div className="permissions-modal-workflows-toggle">
                    <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                      <Toggle
                        name="workflows-view"
                        enabled={JSON.parse(selectedUser.permissions.workflows.read)}
                        onChange={() => updatePermissions('workflows', 'read', selectedUser)}
                      />
                    </motion.div>
                    &nbsp;View{' '}
                  </div>
                  <div className="permissions-modal-workflows-toggle">
                    <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                      <Toggle
                        name="workflows-create"
                        enabled={JSON.parse(selectedUser.permissions.workflows.create)}
                        onChange={() => updatePermissions('workflows', 'create', selectedUser.id)}
                      />
                    </motion.div>
                    &nbsp;Create{' '}
                  </div>
                  <div className="permissions-modal-workflows-toggle">
                    <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                      <Toggle
                        name="workflows-update"
                        enabled={JSON.parse(selectedUser.permissions.workflows.update)}
                        onChange={() => updatePermissions('workflows', 'update', selectedUser.id)}
                      />
                    </motion.div>
                    &nbsp;Modify{' '}
                  </div>
                  <div className="permissions-modal-workflows-toggle">
                    <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                      <Toggle
                        name="workflows-delete"
                        enabled={JSON.parse(selectedUser.permissions.workflows.delete)}
                        onChange={() => updatePermissions('workflows', 'delete', selectedUser.id)}
                      />
                    </motion.div>
                    &nbsp;Delete{' '}
                  </div>

                  {/* Tunnels Permissions */}
                  <div className="permissions-modal-label">Tunnels</div>
                  <div className="permissions-modal-workflows-toggle">
                    <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                      <Toggle
                        name="tunnels-view"
                        enabled={JSON.parse(selectedUser.permissions.tunnels.read)}
                        onChange={() => updatePermissions('tunnels', 'read', selectedUser.id)}
                      />
                    </motion.div>
                    &nbsp;View{' '}
                  </div>
                  <div className="permissions-modal-workflows-toggle">
                    <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                      <Toggle
                        name="tunnels-create"
                        enabled={JSON.parse(selectedUser.permissions.tunnels.create)}
                        onChange={() => updatePermissions('tunnels', 'create', selectedUser.id)}
                      />
                    </motion.div>
                    &nbsp;Create{' '}
                  </div>
                  <div className="permissions-modal-workflows-toggle">
                    <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                      <Toggle
                        name="tunnels-delete"
                        enabled={JSON.parse(selectedUser.permissions.tunnels.delete)}
                        onChange={() => updatePermissions('tunnels', 'delete', selectedUser.id)}
                      />
                    </motion.div>
                    &nbsp;Delete{' '}
                  </div>
                </div>
              </div>

              <div className="permissions-modal-footer">
                <motion.button type="button" className="permissions-modal-close-button" onClick={close} whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                  Done
                </motion.button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
